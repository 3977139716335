import React, { useEffect } from 'react';
import { Divider, Grid2 as Grid, Alert, Typography, Checkbox } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';

import TiffinForm from '../components/tiffin_form';
import TiffinGoodsForm from '../components/tiffin_goods_form';
import { tiffinsApi } from '../../../js/slices/api_slices';
import { STORAGE_KEYS } from '../../../js/lib/constants';
import { TuiAppBar, Hooks, TuiSpinner, Footer, CoreButton } from '../../../core/index';

const { useUpdateTiffinMutation, useGetTiffinQuery } = tiffinsApi;

export default function EditItem() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { errorSnackBar, successSnackBar } = Hooks.useSnackBarNotification();

  const { data: fetchedData, isLoading: itemLoading } = useGetTiffinQuery({
    id,
  }, { refetchOnMountOrArgChange: true, skip: !id });

  const [updateTiffin, {
    data: updateData,
    isSuccess: updateIsSuccess,
    error: updateError,
    isError: updateIsError,
    isLoading: updateIsLoading,
  }] = useUpdateTiffinMutation();

  const methods = useForm({
    defaultValues: {
      ...fetchedData?.data,
      showInCustomerPortal: fetchedData?.data?.showInCustomerPortal || false,
      pickUpPriceChange: (fetchedData?.data?.price?.delivery?.single !== fetchedData?.data?.price?.pickUp?.single ||
        fetchedData?.data?.price?.delivery?.weekly !== fetchedData?.data?.price?.pickUp?.weekly ||
        fetchedData?.data?.price?.delivery?.monthly !== fetchedData?.data?.price?.pickUp?.monthly ||
        fetchedData?.data?.price?.delivery?.biweekly !== fetchedData?.data?.price?.pickUp?.biweekly) || false,
    },
  });

  const { handleSubmit, reset, formState: { errors, isDirty }, control, watch, getValues, setValue } = methods;

  useEffect(() => {
    if (watch('name') != fetchedData?.data?.name) {
      setValue('itemsChanged', true);
    }
  }, [watch('name')]);

  useEffect(() => {
    if (updateIsSuccess && updateData) {
      successSnackBar({ message: 'Meal plan updated successfully' });
      navigate(-1);
    } if (updateError && updateIsError) {
      errorSnackBar({ message: updateError?.data?.errorMessage });
    }
  }, [updateIsSuccess, updateData, updateError, updateIsError]);

  useEffect(() => {
    reset({
      ...fetchedData?.data,
      showInCustomerPortal: fetchedData?.data?.showInCustomerPortal || false,
      pickUpPriceChange: (fetchedData?.data?.price?.delivery?.single !== fetchedData?.data?.price?.pickUp?.single ||
        fetchedData?.data?.price?.delivery?.weekly !== fetchedData?.data?.price?.pickUp?.weekly ||
        fetchedData?.data?.price?.delivery?.monthly !== fetchedData?.data?.price?.pickUp?.monthly) || false,
    });
  }, [fetchedData]);

  const onSubmit = (userInput) => {
    updateTiffin({ id, data: userInput });
  };

  return (
    <Grid component='form'
      onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid size={12}>
          <TuiAppBar title='Edit Meal Plan' />
        </Grid>
        {itemLoading && <TuiSpinner />}
        {!itemLoading && <Grid
          container
          size={12}
          height='80vh'
        >
          <Grid size={12} container spacing={2} sx={{ p: 1 }}>
            <Grid size={6}>
              <TiffinForm
                action='update'
                getValues={getValues}
                setValue={setValue}
                item={fetchedData?.data}
                control={control}
                register={methods.register}
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid size={6}>
              <TiffinGoodsForm
                action='update'
                watch={watch}
                setValue={setValue}
                item={fetchedData?.data}
                control={control}
                isLoading={updateIsLoading}
              />
            </Grid>
          </Grid>
        </Grid>}
        <Footer>
          <Grid container spacing={1} size={12} justifyContent='flex-end' sx={{ p: 1 }}>
            {watch('updateExistingSubscribers') && <Grid size='auto' sx={{ pr: 5 }}>
              <Alert
                severity="warning"
                sx={{ p: 0.25 }}
                action={
                  <Controller
                    control={control}
                    name="updateExistingSpecialMealPlanSubscribers"
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        size='small'
                        sx={{
                          color: 'secondary.contrastText',
                          '&.Mui-checked': {
                            color: 'secondary.contrastText',
                          },
                        }}
                      />
                    )}
                  />
                }
              >
                <Typography fontWeight="bold">
                  Apply changes to customers with customized meal plans
                </Typography>
              </Alert>
            </Grid>}
            {watch('itemsChanged') && <Grid size='auto' sx={{ pr: 5 }}>
              <Alert
                severity="warning"
                sx={{ p: 0.25 }}
                action={
                  <Controller
                    control={control}
                    name="updateExistingSubscribers"
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        size='small'
                        sx={{
                          color: 'secondary.contrastText',
                          '&.Mui-checked': {
                            color: 'secondary.contrastText',
                          },
                        }}
                      />
                    )}
                  />
                }
              >
                <Typography fontWeight="bold">
                  Apply changes to existing customers of this meal plan
                </Typography>
              </Alert>
            </Grid>}
            <Grid size='auto'>
              <CoreButton
                fullWidth={false}
                onClickHandler={() => {
                  reset({
                    name: '',
                    description: '',
                    items: [],
                    showInCustomerPortal: false,
                    price: {
                      delivery: {
                        single: '',
                        monthly: '',
                        weekly: '',
                      },
                      pickUp: {
                        single: '',
                        monthly: '',
                        weekly: '',
                      },
                    }
                  });
                  sessionStorage.removeItem(STORAGE_KEYS.TIFFIN)
                }}
                variant="outlined"
                startIcon={<ClearAllOutlinedIcon />}
              >
                Clear
              </CoreButton>
            </Grid>
            <Divider orientation='vertical' flexItem />
            <Grid size='auto'>
              <CoreButton
                fullWidth={false}
                onClickHandler={() => navigate(-1)}
                variant="outlined"
                startIcon={<ArrowBackIosNewOutlinedIcon />}
              >
                Back
              </CoreButton>
            </Grid>
            <Grid size='auto'>
              <CoreButton
                isLoading={updateIsLoading}
                type='submit'
                fullWidth={false}
                variant="contained"
                disabled={!isDirty}
                startIcon={<SaveOutlinedIcon />}
              >
                Save
              </CoreButton>
            </Grid>
          </Grid>
        </Footer>
      </Grid>
    </Grid>
  );
}