import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Grid2 as Grid,
  Divider,
  Typography,
  FormLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  Alert,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import { ToggleDays } from '../../../core/index';

export default function GeneralSettings() {
  const { control, formState: { errors, dirtyFields }, watch, getValues } = useFormContext();
  return (
    <Grid container sx={{
      p: 2,
      backgroundColor: 'white',
      borderRadius: '20px',
      border: '1px solid #E0E0E0',
    }}>
      <Grid size={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
        <Grid size={6}>
          <Grid size={12} container>
            <Typography id="days-in-month-change">
              Subscription Cycle
            </Typography>
          </Grid>
          <Grid size={12} container>
            <FormLabel id="days-in-month-change-label">Custom monthly subscription cycle</FormLabel>
          </Grid>
        </Grid>
        <Grid size={2}>
          <FormControl fullWidth size="small" sx={{ mt: 1 }}>
            <Controller
              rules={{ required: 'Invalid Selection' }}
              control={control}
              name="settings.daysInMonth"
              render={({ field }) => (
                <FormControl
                  required={true}
                  fullWidth={false}
                >
                  <InputLabel
                    sx={{
                      color: errors?.['daysInMonth']?.message ? 'secondary.contrastText.100' : 'default',
                    }}>
                    Select Days
                  </InputLabel>
                  <Select
                    id="days-in-month"
                    error={!!errors?.['daysInMonth']?.message}
                    label="Days in Month"
                    onChange={(event) => field.onChange(event.target.value)}
                    value={field?.value ?? 'Full Month'} // Default to 'Full Month' if value is null
                  >
                    {[{
                      label: 'Full Month',
                      value: 'Full Month'
                    }, {
                      label: '28 Days',
                      value: 28
                    }].map(({ label, value }) => <MenuItem key={label} value={value}>{label}</MenuItem>)}
                  </Select>
                </FormControl>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid size={12} container>
        <Grid size={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
          <Grid size={6}>
            <Grid size={12} container>
              <Typography id="restaurant-type-change">
                Default Delivery Days
              </Typography>
            </Grid>
            <Grid size={12} container>
              <FormLabel id="service-radio-buttons-group-label">Delivery days with counter can also be set for each customer</FormLabel>
            </Grid>
          </Grid>
          <Grid size={6}>
            <Controller
              rules={{ required: 'Invalid Selection' }}
              control={control}
              name="settings.deliveryDays"
              render={({ field }) => {
                return (
                  <ToggleDays field={field} existingDays={getValues('settings.deliveryDays')} />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid size={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
          <Grid size={6}>
            <Grid size={12} container>
              <Typography id="restaurant-type-change">
                Route Recommendation
              </Typography>
            </Grid>
            <Grid size={12} container>
              <FormLabel id="service-radio-buttons-group-label">Recommend Route and Driver based on route area</FormLabel>
            </Grid>
          </Grid>
          <Grid size={6}>
            <Grid size={12}>
              <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                <Controller
                  rules={{ required: 'Invalid Selection' }}
                  control={control}
                  name="settings.services.routeRecommendation.enable"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={watch('settings.services.routeRecommendation.enable')}
                          disabled={!watch('settings.services.routeRecommendation.eligible')}
                          sx={{
                            color: 'secondary.contrastText',
                            '&.Mui-checked': {
                              color: 'secondary.contrastText',
                            },
                          }}
                        />
                      }
                      sx={{
                        '.MuiFormControlLabel-label': { ml: 1 },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
          <Grid size={6}>
            <Grid size={12} container>
              <Typography id="tiffin-expiry-continue-change">
                Send Expired Tiffins
              </Typography>
            </Grid>
            <Grid size={12} container>
              <FormLabel id="tiffin-expiry-continue-change-label">Continue sending expired tiffins until manually cancelled</FormLabel>
            </Grid>
          </Grid>
          <Grid size={6}>
            <Grid container>
              <Grid size={1}>
                <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                  <Controller
                    rules={{ required: 'Invalid Selection' }}
                    control={control}
                    name="settings.services.sendExpiredTiffins.enable"
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            sx={{
                              color: 'secondary.contrastText',
                              '&.Mui-checked': {
                                color: 'secondary.contrastText',
                              },
                            }}
                          />
                        }
                        sx={{
                          '.MuiFormControlLabel-label': { ml: 1 },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid size={10}>
                {dirtyFields?.settings?.services?.sendExpiredTiffins?.enable && <Alert severity="info">
                  OPTIMIZE the routes to reflect changes in today&apos;s packing list, stickers, and routes.
                </Alert>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
          <Grid size={6}>
            <Grid size={12} container>
              <Typography id="days-for-expiry-reminders-change">
                Expiry Reminder Frequency
              </Typography>
            </Grid>
            <Grid size={12} container>
              <FormLabel id="days-for-expiry-reminders-change-label">Specify the number of days before the expiry date to send reminders</FormLabel>
            </Grid>
          </Grid>
          <Grid size={2}>
            <FormControl fullWidth size="small" sx={{ mt: 1 }}>
              <Controller
                rules={{ required: 'Invalid Selection' }}
                control={control}
                name="settings.expiryRemindersFrequency"
                render={({ field }) => (
                  <FormControl
                    required={true}
                    fullWidth={false}
                  >
                    <InputLabel
                      sx={{
                        color: errors?.['settings.expiryRemindersFrequency']?.message ? 'secondary.contrastText.100' : 'default',
                      }}>
                      Select Frequency
                    </InputLabel>
                    <Select
                      id="days-for-expiry-reminders"
                      error={!!errors?.['settings.expiryRemindersFrequency']?.message}
                      label="Select Frequency"
                      onChange={(event) => field.onChange(event.target.value)}
                      value={field?.value ?? 0}
                    >
                      {[{
                        label: 'On Expiry Day',
                        value: 0
                      },
                      {
                        label: '1 Day Before (until expiry)',
                        value: 1
                      },
                      {
                        label: '2 Days Before (until expiry)',
                        value: 2
                      }].map(({ label, value }) => <MenuItem key={label} value={value}>{label}</MenuItem>)}
                    </Select>
                  </FormControl>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  );
}
