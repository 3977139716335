import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { GoogleMap, useJsApiLoader, Polyline, Polygon, MarkerF, DrawingManager } from '@react-google-maps/api';

import TuiAutoComplete from './autocomplete';
import useDebounce from './hooks/use_debounce';

import { googleMapsApi } from '../js/slices/api_slices';
import { ROUTE, TIFFIN } from '../js/lib/constants';

const { useGetPlacesQuery, useLazyGetPlaceByIdQuery } = googleMapsApi;
const libraries = ['drawing', 'geometry'];

function GoogleMapComponent({
  startAddress,
  width,
  height,
  currentLocation = false,
  showSearch = false,
  drawingManager = null,
  markers = null,
  geometry = null,
  polygonCords = null,
}) {
  const [addressInputValue, setAddressInputValue] = useState('');
  const debouncedAddressInput = useDebounce(addressInputValue, 500);
  const [longitude, setLongitude] = useState(startAddress?.coordinates?.longitude);
  const [latitude, setLatitude] = useState(startAddress?.coordinates?.latitude);
  const [drawingMode, setDrawingMode] = useState(null);
  const [drawingModes, setDrawingModes] = useState([]);
  const [polylineCords, setPolylineCords] = useState([]);

  const { data: addressData,
    isLoading: addressLoading } = useGetPlacesQuery({
      input: debouncedAddressInput,
    }, { refetchOnMountOrArgChange: true, skip: addressInputValue ? false : true });

  const [trigger] = useLazyGetPlaceByIdQuery({});

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });

  const containerStyle = {
    width: width || 500,
    height: height || 500,
  };

  const onMapLoad = (e) => {
    if (currentLocation) {
      navigator?.geolocation.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lng } }) => {
          setLatitude(lat);
          setLongitude(lng);
        },
      );
    }
    if (geometry) {
      const decodedPath = window.google.maps.geometry.encoding.decodePath(geometry);
      setPolylineCords(decodedPath.map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() })));
    }
    setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
    setDrawingModes([window.google.maps.drawing.OverlayType.POLYGON]);
  };

  const handleChange = async (item) => {
    try {
      if (item?.place_id) {
        const res = await trigger({ id: item?.place_id });
        if (res.isSuccess && res?.data?.data) {
          const { coordinates, id } = res?.data?.data;
          const { latitude, longitude } = coordinates;
          setLatitude(latitude);
          setLongitude(longitude);
        }
      }
    } catch (error) { 
      console.error(error);
    }
  };

  const handleOverlayComplete = (e) => {
    drawingManager.setShape(e.overlay);
    drawingManager?.onSave(e.overlay, ROUTE.DELIVERY_AREA_TYPES.POLYGON);
    setDrawingMode(null);
    setDrawingModes([]);
  };

  useEffect(() => {
    if (isLoaded && !drawingManager?.shape) {
      setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
      setDrawingModes([window.google.maps.drawing.OverlayType.POLYGON]);
    }
  }, [isLoaded, drawingManager]);

  return isLoaded ? (
    <Grid container rowSpacing={2} sx={{ mt: 0.5 }}>
      {showSearch && <Grid item xs={12}>
        <TuiAutoComplete
          id="address"
          name="address"
          loading={addressLoading}
          freeSolo
          onChange={(event, item) => {
            handleChange(item);
          }}
          inputValue={addressInputValue}
          onInputChange={(event, newInputValue) => {
            setAddressInputValue(newInputValue);
          }}
          label="Search Address"
          labelKey='description'
          options={addressData?.data?.items}
        />
      </Grid>}
      <Grid item xs={12}>
        <GoogleMap
          center={{ lat: latitude, lng: longitude }}
          mapContainerStyle={containerStyle}
          zoom={10}
          onLoad={onMapLoad}
        >
          {polylineCords &&
            <Polyline
              path={polylineCords}
              options={{
                strokeColor: '#0000FF',
                strokeOpacity: 0.6,
                strokeWeight: 4
              }}
            />}
          {markers && markers?.map((cord, index) => {
            let url = "https://maps.google.com/mapfiles/kml/paddle/wht-blank.png";
            if (cord?.status === TIFFIN.DELIVERY_ROUTES_STOP_STATUS.DELIVERED) {
              url = "https://maps.google.com/mapfiles/kml/paddle/grn-blank.png";
            } else if (cord?.status === TIFFIN.DELIVERY_ROUTES_STOP_STATUS.FAILED) {
              url = "https://maps.google.com/mapfiles/kml/paddle/red-blank.png";
            } else if (cord?.status === TIFFIN.DELIVERY_ROUTES_STOP_STATUS.ON_THE_WAY) {
              url = "https://maps.google.com/mapfiles/kml/paddle/ylw-blank.png";
            } else if (cord?.status === TIFFIN.DELIVERY_ROUTES_STOP_STATUS.CANCELLED) {
              url = "https://maps.google.com/mapfiles/kml/paddle/red-blank.png";
            } else if (cord?.unassigned) {
              url = "https://maps.google.com/mapfiles/kml/paddle/pink-blank.png";
            } else if (cord?.status === TIFFIN.DELIVERY_ROUTES_STOP_STATUS.PENDING) {
              url = "https://maps.google.com/mapfiles/kml/paddle/blu-blank.png";
            }

            return (
              <MarkerF
                key={index}
                title={cord?.title || ''}
                label={{
                  text: cord?.position.toString(),
                  color: 'black',
                  fontSize: '12px'
                }}
                position={{ lat: cord?.lat, lng: cord?.lng }}
                options={{
                  icon: {
                    url,
                    scaledSize: new google.maps.Size(46, 46),
                    labelOrigin: new google.maps.Point(23, 15)
                  }
                }}
              />
            );
          })}
          {polygonCords?.length > 0 &&
            <Polygon
              path={polygonCords?.map((cord) => {
                return {
                  lat: cord?.[1], lng: cord?.[0]
                }
              })}
              options={{
                fillColor: `#008080`,
                strokeColor: `#008080`,
                fillOpacity: 0.5,
                strokeOpacity: 1,
                strokeWeight: 5,
                clickable: true,
                editable: true,
                draggable: true,
                zIndex: 1,
              }}
            />}
          {drawingManager && drawingManager?.onSave &&
            <DrawingManager
              options={{
                drawingControlOptions: {
                  drawingModes: drawingModes,
                },
                drawingMode: drawingMode,
                polygonOptions: {
                  fillColor: `#008080`,
                  strokeColor: `#008080`,
                  fillOpacity: 0.5,
                  strokeOpacity: 1,
                  strokeWeight: 5,
                  clickable: true,
                  editable: true,
                  draggable: true,
                  zIndex: 1,
                },
              }}
              onOverlayComplete={handleOverlayComplete}
            />}
        </GoogleMap>
      </Grid>
    </Grid>
  ) : <></>;
}

export default React.memo(GoogleMapComponent);
