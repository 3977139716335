// eslint-disable-next-line no-undef
const HOSTNAME = process.env.REACT_APP_HOSTNAME;
const API_PREFIX = 'api';
const API_VERSION = 'v1';
export const SIDE_DRAWER_WIDTH = 220;
export const SERVER_URL = `${HOSTNAME}/${API_PREFIX}/${API_VERSION}`;
export const START_YEAR = 2022;
export const TURMITECH_WEBSITE_URL = 'https://www.turmitech.com';

export const APIS = {
  CUSTOMER_OPERATIONS: {
    EDIT_DELIVERY: 'editDelivery',
    RESET_DELIVERY: 'resetDelivery',
  },
  CUSTOMER_BULK_OPERATIONS: {
    DELETE: 'delete',
    ADD_HOLD: 'addHold',
    CHANGE_MEAL_PLAN: 'changeMealPlan',
    CHANGE_ROUTE: 'changeRoute',
    CANCEL_SUBSCRIPTION: 'cancelSubscription',
    SEND_MESSAGE: 'sendMessage',
  },
  EXPENSE_BULK_OPERATIONS: {
    DELETE: 'delete',
    UPDATE: 'update',
  }
};

export const CURRENCY = {
  USD: 'USD',
  CAD: 'CAD',
  INR: 'INR',
};

export const SALARY_TYPES = {
  MONTHLY: 'Monthly',
  HOURLY: 'Hourly',
  DAILY: 'Daily',
  PER_HOUR: 'Per Hour',
  PER_PACKAGE: 'Per Package',
  PER_STOP: 'Per Stop',
  PER_KM: 'Per Kilometer',
  PER_ROUTE: 'Per Route',
};

export const RESTAURANT = {
  SERVICE: {
    DINE_IN: 'DINE_IN',
    SELF: 'SELF',
  },
};

export const DELIVERY_METHOD = {
  HOME_DELIVERY: 'Home Delivery',
  PICK_UP: 'Pick up',
};

export const ORDERS = {
  STATUS: {
    COMPLETED: 'Completed',
    PENDING: 'Pending',
    CANCELLED: 'Cancelled',
    FAILED: 'Failed',
  },
};

export const UNITS = {
  BOWL: 'BOWL',
  GRAMS: 'GRAMS',
  OUNCES: 'OUNCES',
  POUNDS: 'POUNDS',
  PIECES: 'PIECES',
  SLICES: 'SLICES',
  CUPS: 'CUPS',
  TABLESPOONS: 'TABLE-SPOONS',
  TEASPOONS: 'TEA-SPOONS',
  PORTION: 'PORTION',
};

export const USERS = {
  STATUS: {
    PENDING_APPROVAL: 'Pending_Approval',
    ARCHIVED: 'Archived',
    ACTIVE: 'Active',
    AWAY: 'Away',
    BLOCKED: 'Blocked',
    PROFILE_INCOMPLETE: 'Profile_Incomplete',
    WAITING: 'Waiting',
  },
  TYPES: {
    EMPLOYEE: {
      COOK: 'Cook',
      DRIVER: 'Driver',
      PACKER: 'Packer',
      MANAGER: 'Manager',
      ALL: ['Cook', 'Driver', 'Packer', 'Manager'],
    },
  },
};
export const TIFFIN = {
  STATUS: {
    IN_PROGRESS: 'In Progress',
    ACTIVE: 'Active',
    EXPIRING_SOON: 'Expiring Soon',
    EXPIRED: 'Expired',
    CANCEL: 'Cancel',
    HOLD: 'Hold',
    NOT_STARTED: 'Not Started',
  },
  SHIPPING_STATUS: {
    PENDING: 'Pending',
    DELIVERED: 'Delivered',
    PICKED_UP: 'Picked Up',
    NONE: 'None',
    HOLD: 'Hold',
    NON_DELIVERY_DAY: 'Non Delivery Day',
  },
  PLANS: {
    SINGLE: 'single',
    WEEKLY: 'weekly',
    BIWEEKLY: 'biweekly',
    MONTHLY: 'monthly',
    CUSTOM_DATE: 'custom_date',
    CUSTOM_DELIVERIES: 'custom_deliveries',
  },
  DELIVERY_ROUTES_STATUS: {
    NOT_READY: 'Not Ready',
    READY: 'Ready',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
  },
  DELIVERY_ROUTES_STOP_STATUS: {
    PENDING: 'Pending',
    ON_THE_WAY: 'On The Way',
    DELIVERED: 'Delivered',
    FAILED: 'Failed',
    CANCELLED: 'Cancelled',
  },
  PAYMENTS: {
    PAID: 'Paid',
    PENDING: 'Pending',
  }
};

export const ROUTE = {
  DELIVERY_AREA_TYPES: {
    POLYGON: 'Polygon',
  }
};

export const DAYS = [
  {
    name: 'Sunday',
    shortName: 'Sun',
    label: 'S',
    value: 0,
  },
  {
    name: 'Monday',
    shortName: 'Mon',
    label: 'M',
    value: 1,
  },
  {
    name: 'Tuesday',
    shortName: 'Tue',
    label: 'T',
    value: 2,
  },
  {
    name: 'Wednesday',
    shortName: 'Wed',
    label: 'W',
    value: 3,
  },
  {
    name: 'Thursday',
    shortName: 'Thu',
    label: 'T',
    value: 4,
  },
  {
    name: 'Friday',
    shortName: 'Fri',
    label: 'F',
    value: 5,
  },
  {
    name: 'Saturday',
    shortName: 'Sat',
    label: 'S',
    value: 6,
  },
];

export const TIMEZONES = {
  Edmonton: 'Mountain Standard Time',
  Toronto: 'Eastern Standard Time',
  Vancouver: 'Pacific Standard Time',
};

export const STORAGE_KEYS = {
  CUSTOMER: 'customer',
  CUSTOMER_FILTER: 'customer_filter',
  CUSTOMER_PAGINATION: 'customer_pagination',
  ROUTE: 'route',
  ROUTE_FILTER: 'route_filter',
  TIFFIN: 'tiffin',
  EMPLOYEE: 'employee',
  EMPLOYEE_PAGINATION: 'employee_pagination',
  EXPENSE: 'expense',
  EXPENSE_FILTER: 'expense_filter',
  LEADS_FILTER: 'leads_filter',
  STICKER_FIELDS: 'sticker_fields',
  STICKER_LABEL_STYLE: 'sticker_label_style',
  WHATS_NEW_MODAL_UPDATED_AT: 'whats_new_modal_updated_at',
};


export const LEADS = {
  STATUS: {
    NEW: 'New',
    ACCEPTED: 'Accepted',
    REJECTED: 'Rejected',
  }
};

export const SUBSCRIPTION_PLANS = {
  PREMIUM: 'premium',
  STARTER: 'starter',
};

export const WHATSAPP_CUSTOM_TEMPLATE = {
  VACATION: (business) => ({
    NAME: 'Vacation',
    TEMPLATE: 'dinespot_vacation',
    IMAGE: false,
    MESSAGE: `Hello,\nWe hope this message finds you well! 🌟\n\nWe wanted to inform you that meal delivery will not be available [1].\n\nWe apologize for any inconvenience this may cause and truly appreciate your understanding. Regular service will resume [2].\n\nThank you for being a valued customer. If you have any questions, feel free to reach out to us directly ${business?.phoneNumber?.number || business?.email}.\n\nBest regards,\n${business?.name}`
  }),
  VACATION_AND_REPLACEMENT: (business) => ({
    NAME: 'Vacation and Replacement',
    TEMPLATE: 'dinespot_vacation_and_replacement',
    IMAGE: false,
    MESSAGE: `Hello,\n\nWe hope this message finds you well! 🌟\n\nWe wanted to inform you that meal delivery will not be available [1].\n\nHowever, to ensure you don’t miss out, the meal will instead be delivered [2].\n\nWe apologize for any inconvenience this may cause and truly appreciate your understanding. If you have any questions, feel free to reach out to us directly ${business?.phoneNumber?.number || business?.email}.\n\nThank you for being a valued customer!\n\nBest regards,\n${business?.name}`
  }),
  FESTIVAL_WISHES: (business) => ({
    NAME: 'Festival Wishes',
    TEMPLATE: 'dinespot_festival_wish',
    IMAGE: false,
    MESSAGE: `[1]!\n\nDear Customer,\n\nWishing you and your loved ones a joyous and prosperous [1]! 🌟✨\n\nMay this special occasion bring happiness, health, and success into your life.\n\nThank you for being a part of the ${business?.name} family. We look forward to serving you with the best always.\n\nEnjoy the celebrations! 🥳\n\nWarm regards,\n${business?.name}`
  }),
  DELIVERY_ISSUE: (business) => ({
    NAME: 'Delivery Issue',
    TEMPLATE: 'dinespot_delivery_issue',
    IMAGE: false,
    MESSAGE: `Hello,\n\nWe hope this message finds you well! 🌟\n\nWe wanted to inform you that we are experiencing a delay in delivering your meal today. We apologize for any inconvenience this may cause and truly appreciate your understanding.\n\nIf you have any questions or concerns, please feel free to reach out to us directly ${business?.phoneNumber?.number || business?.email}.\n\nThank you for being a valued customer!\n\nBest regards,\n${business?.name}`
  }),
  WEEKLY_MENU: (business) => ({
    NAME: 'Weekly Menu',
    TEMPLATE: 'dinespot_weekly_menu',
    IMAGE: true,
    MESSAGE: `Hello,\n\nWe hope this message finds you well! 🌟\n\nPlease find the menu of the week from [1] till [2].\n\nIf you have any questions or concerns, please feel free to reach out to us directly ${business?.phoneNumber?.number || business?.email}.\n\nBest regards,\n${business?.name}`
  }),
}