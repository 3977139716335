import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
    Grid2 as Grid,
    Box,
    Typography,
    Modal,
    InputLabel,
    Alert,
    FormControl,
    Select,
    MenuItem,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BaseDropzone, CoreButton, BaseTextField, Hooks } from '../../../core/index';
import { WHATSAPP_CUSTOM_TEMPLATE, APIS } from '../../../js/lib/constants';
import { tiffinsApi } from '../../../js/slices/api_slices';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 420,
    color: 'black',
    backgroundColor: 'white',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    p: 2,
    borderRadius: '5px',
};

const { useBulkOperationMutation } = tiffinsApi;
const { useSnackBarNotification } = Hooks;

export default function CustomerSendMessageModal({ open, onClose, selectedItems }) {
    const { control, handleSubmit, formState: { errors }, register, setValue, watch } = useForm({
        defaultValues: {
            headerImage: null,
            placeholders: [],
            messageTemplate: '',
        },
    });

    const { successSnackBar, errorSnackBar } = useSnackBarNotification();
    const settings = useSelector((store) => store?.settings?.settings);
    const business = useSelector((store) => store?.business?.business);

    const whatsAppMessagesLeft = settings?.plan?.messages?.whatsApp?.total - settings?.plan?.messages?.whatsApp?.used;

    const [placeholderValues, setPlaceholderValues] = useState({});

    const [bulkOperation, {
        data: bulkData,
        isLoading: bulkLoading,
        isSuccess: bulkSuccess,
        isError: bulkIsError,
        error: bulkError,
    }] = useBulkOperationMutation();

    const handlePlaceholderChange = (placeholder, value) => {
        setPlaceholderValues((prevValues) => ({
            ...prevValues,
            [placeholder]: value,
        }));
    };


    useEffect(() => {
        if (!bulkLoading) {
            if (bulkSuccess && bulkData) {
                const { data } = bulkData;
                if (data?.failedMessagesCount === 0) {
                    successSnackBar({ message: `Message successfully sent to ${data?.successMessagesCount} customers` });
                    return;
                } else if (data?.successMessagesCount === 0) {
                    errorSnackBar({ message: `Message not sent to ${data?.failedMessagesCount} customers` });
                    return;
                }
                successSnackBar({ message: `Message successfully sent to ${data?.successMessagesCount} customers` });
                errorSnackBar({ message: `Message not sent to ${data?.failedMessagesCount} customers` });
                onClose();
            } if (bulkIsError && bulkError) {
                errorSnackBar({ message: bulkError?.data?.errorMessage });
                onClose();
            }
        }
    }, [bulkSuccess, bulkIsError, bulkError, bulkData]);


    const getMessageWithPlaceholders = () => {
        let message = WHATSAPP_CUSTOM_TEMPLATE[watch('messageTemplate')](business).MESSAGE;
        Object.keys(placeholderValues).forEach((placeholder) => {
            const regex = new RegExp(`\\${placeholder}`, 'g');
            message = message.replace(regex, placeholderValues[placeholder]);
        });
        return message;
    };

    const onSubmit = (data) => {
        const { messageTemplate, placeholders, headerImage } = data;
        let headerImageBase64 = null;

        if (messageTemplate === 'WEEKLY_MENU' && headerImage) {
            const reader = new FileReader();
            reader.readAsDataURL(headerImage);
            reader.onloadend = () => {
                headerImageBase64 = reader.result;
                bulkOperation({
                    operation: APIS.CUSTOMER_BULK_OPERATIONS.SEND_MESSAGE, ids: [...selectedItems], update: {
                        whatsApp: {
                            template: WHATSAPP_CUSTOM_TEMPLATE[messageTemplate](business).TEMPLATE,
                            message: getMessageWithPlaceholders(),
                            headerParams: [{ type: 'image', image: headerImageBase64 }],
                            bodyParams: [...placeholders, business?.phoneNumber?.number || business?.email, business?.name]?.map((param) => ({ type: 'text', text: param })),
                        }
                    }
                });
            };
        } else {
            bulkOperation({
                operation: APIS.CUSTOMER_BULK_OPERATIONS.SEND_MESSAGE, ids: [...selectedItems], update: {
                    whatsApp: {
                        template: WHATSAPP_CUSTOM_TEMPLATE[messageTemplate](business).TEMPLATE,
                        message: getMessageWithPlaceholders(),
                        headerParams: messageTemplate === 'FESTIVAL_WISHES' ? placeholders?.map((param) => ({ type: 'text', text: param })) : [],
                        bodyParams: messageTemplate === 'FESTIVAL_WISHES' ? [...placeholders, business?.name]?.map((param) => ({ type: 'text', text: param })) :
                            [...placeholders, business?.phoneNumber?.number || business?.email, business?.name]?.map((param) => ({ type: 'text', text: param })),
                    }
                }
            });
        }
    };

    const formComponent = (
        <Grid container spacing={1}>
            <Grid size={12}>
                {selectedItems.length > whatsAppMessagesLeft ? <Alert severity="warning">{`Total selected customers ${selectedItems?.length} and messages left ${whatsAppMessagesLeft}. Contact support team to update limit in plan.`}</Alert> :
                        <Alert severity="info">
                            {`Sending an exciting message to ${selectedItems?.length} customers! 🎉`}
                        </Alert>}
            </Grid>
            <Grid size={12}>
                <Controller
                    name="messageTemplate"
                    control={control}
                    render={({ field }) => (
                        <FormControl fullWidth>
                            <InputLabel id="messageTemplate">Select Message</InputLabel>
                            <Select
                                labelId="messageTemplate"
                                id="messageTemplate"
                                error={!!errors?.['messageTemplate']?.message}
                                label="Select Message"
                                onChange={(event) => {
                                    field.onChange(event);
                                }}
                                value={field.value}
                            >
                                {Object.keys(WHATSAPP_CUSTOM_TEMPLATE).map((key) => (
                                    <MenuItem key={key} value={key}>
                                        {WHATSAPP_CUSTOM_TEMPLATE[key](business).NAME}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                />
            </Grid>
            {watch('messageTemplate') && (
                <>
                    <Grid size={12} sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '16px', backgroundColor: '#f9f9f9' }}>
                        <Typography variant="body2" color="textSecondary">
                            {getMessageWithPlaceholders().split('\n').map((part, index) => (
                                <span key={index}>
                                    {part}
                                    {index < getMessageWithPlaceholders().split('\n').length - 1 && <br />}
                                </span>
                            ))}
                        </Typography>
                    </Grid>
                    {[...new Set(WHATSAPP_CUSTOM_TEMPLATE[watch('messageTemplate')](business).MESSAGE.match(/\[\d+\]/g))]?.map((placeholder, index) => (
                        <Grid size={12} key={index}>
                            <BaseTextField
                                fullWidth
                                label={`Placeholder ${placeholder}`}
                                variant="outlined"
                                slotProps={{
                                    htmlInput: { maxLength: 50 },
                                }}
                                validate={register(`placeholders.${index}`, {
                                    required: 'Name is required',
                                    maxLength: {
                                        value: 1000,
                                        message: 'Length must be less than 500',
                                    },
                                },
                                )}
                                required
                                helperText={`${placeholderValues[placeholder]?.length || 0}/50`}
                                onChange={(event) => handlePlaceholderChange(placeholder, event.target.value)}
                                error={(placeholderValues[placeholder]?.length || 0) > 50}
                            />
                        </Grid>
                    ))}
                    {WHATSAPP_CUSTOM_TEMPLATE[watch('messageTemplate')](business).IMAGE && <Grid container size={12}>
                        <Grid size={6}>
                            <Controller
                                name="headerImage"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <BaseDropzone
                                        name="headerImage"
                                        onChange={onChange}
                                    />
                                )}
                            />
                        </Grid>
                        {watch('headerImage') && <Grid
                            size={6}
                            container
                            justifyContent="center"
                            alignItems="center">
                            <img
                                style={{
                                    maxHeight: '8vh',
                                    maxWidth: '8vw',
                                    objectFit: 'fill',
                                }}
                                src={watch('headerImage') ? URL.createObjectURL(watch('headerImage')) : WHATSAPP_CUSTOM_TEMPLATE[watch('messageTemplate')](business).IMAGE}
                            />
                            <IconButton
                                onClick={() => {
                                    setValue('headerImage', null);
                                }}
                                sx={{
                                    position: 'relative',
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>}
                    </Grid>}
                </>
            )}
        </Grid>
    );

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <Grid container spacing={2}>
                    <Grid size={12}>
                        Send Message
                    </Grid>
                    <Grid size={12} container component="form" onSubmit={handleSubmit(onSubmit)}>
                        <Grid size={12} container>
                            {formComponent}
                        </Grid>
                        <Grid size={12} container justifyContent="flex-end" spacing={1}>
                            <Grid>
                                <CoreButton
                                    disabled={bulkLoading}
                                    onClickHandler={onClose}
                                    variant="outlined"
                                >
                                    Cancel
                                </CoreButton>
                            </Grid>
                            <Grid>
                                <CoreButton
                                    isLoading={bulkLoading}
                                    type="submit"
                                    variant="contained"
                                    disabled={!watch('messageTemplate') || whatsAppMessagesLeft <=0 || selectedItems.length > whatsAppMessagesLeft}
                                >
                                    Send
                                </CoreButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}
