import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import moment from 'moment';
import {
    Grid2 as Grid,
} from '@mui/material';
import { tiffinsApi } from '../../../js/slices/api_slices';

import { BaseTextField, DatePicker, TuiAutoComplete, Hooks } from '../../../core/index';
const { useGetTiffinsQuery } = tiffinsApi;
const { useDebounce } = Hooks;

export default function LeadForm() {
    const { register, control, formState: { errors } } = useFormContext();

    const [tiffinInputValue, setTiffinInputValue] = useState('');
    const debouncedTiffinInput = useDebounce(tiffinInputValue, 500);

    const { data, isLoading: getTiffinLoading } = useGetTiffinsQuery({
        page: 1,
        pageSize: 20,
        contains: { name: debouncedTiffinInput },
    }, { refetchOnMountOrArgChange: true });

    return <Grid
        container
        size={12}
        justifyContent="center"
        sx={{
            p: 2,
        }}>
        <Grid size={6} container spacing={3} sx={{
            p: 4,
            mt: 1,
            backgroundColor: 'white',
            border: '1px solid #e0e0e0',
            borderColor: 'grey.300',
            borderRadius: '5px',
        }}>
            <Grid size={12} container spacing={1}>
                <Grid size={6}>
                    <BaseTextField
                        id='firstName'
                        name="firstName"
                        label="First Name"
                        errors={errors}
                        required={true}
                        validate={register('firstName', {
                            required: 'Name must be valid',
                            maxLength: {
                                value: 100,
                                message: 'Name must be less than 100 characters'
                            },
                        })}
                    />
                </Grid>
                <Grid size={6}>
                    <BaseTextField
                        id='lastName'
                        name="lastName"
                        label="Last Name"
                        errors={errors}
                        required={false}
                        validate={register('lastName', {
                            maxLength: {
                                value: 100,
                                message: 'Last Name must be less than 100 characters'
                            },
                        })}
                    />
                </Grid>
            </Grid>
            <Grid size={12} container spacing={1}>
                <Grid size={6}>
                    <BaseTextField
                        id='phoneNumber.number'
                        name="'phoneNumber.number"
                        label="Phone Number"
                        errors={errors}
                        required={false}
                        validate={register('phoneNumber.number', {
                            required: 'Phone Number must be valid',
                            maxLength: {
                                value: 20,
                                message: 'Phone Number must be less than 20 characters'
                            },
                        })}
                    />
                </Grid>
                <Grid size={6}>
                    <BaseTextField
                        id='email'
                        name="email"
                        label="Email"
                        errors={errors}
                        required={false}
                        validate={register('email', {
                            maxLength: {
                                value: 1000,
                                message: 'Email must be less than 1000 characters'
                            },
                        })}
                    />
                </Grid>
            </Grid>
            <Grid size={12} container spacing={1}>
                <Grid size={6}>
                    <Controller
                        control={control}
                        rules={{ required: 'Invalid Start Date' }}
                        name="startDate"
                        render={({ field: { onChange, value } }) => {
                            return (
                                <DatePicker
                                    control={control}
                                    onChange={(event) => {
                                        onChange(event);
                                    }}
                                    required={true}
                                    errors={errors}
                                    label="Start Date"
                                    value={value ? moment(value) : null}
                                />
                            );
                        }
                        }
                    />
                </Grid>
                <Grid size={6}>
                    <Controller
                        control={control}
                        rules={{ required: 'Invalid End Date' }}
                        name="endDate"
                        render={({ field: { onChange, value } }) => {
                            return (
                                <DatePicker
                                    control={control}
                                    onChange={(event) => {
                                        onChange(event);
                                    }}
                                    required={true}
                                    errors={errors}
                                    label="End Date"
                                    value={value ? moment(value) : null}
                                />
                            );
                        }
                        }
                    />
                </Grid>
            </Grid>
            <Grid size={12} container spacing={1}>
                <Grid size={6}>
                    <Controller
                        control={control}
                        rules={{ required: 'Meal plan must be valid' }}
                        name="mealPlan"
                        render={({ field: { onChange, value } }) => {
                            return (
                                <TuiAutoComplete
                                    loading={getTiffinLoading}
                                    errors={errors}
                                    onChange={(event, item) => {

                                    }}
                                    onInputChange={(event, newValue) => {
                                    }}
                                    value={value}
                                    labelKey='name'
                                    label="Select Meal Plan"
                                    noOptionsText="No Meal Plan Exists"
                                    options={data?.data?.items}
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid size={6}>
                    <BaseTextField
                        id='mealPlan.plan.price'
                        name="mealPlan.plan.price"
                        label="Price"
                        errors={errors}
                        required={true}
                        type='number'
                        inputProps={{ step: '0.01' }} // Allow decimal values
                        validate={register('mealPlan.plan.price', {
                            required: 'Price is required',
                            pattern: {
                                value: /^[0-9]*\.?[0-9]+$/,
                                message: 'Price must be a number',
                            },
                        })}
                    />
                </Grid>
            </Grid>
            <Grid container size={12} spacing={1}>
                <Grid size={{ xs: 12, sm: 3, md: 3 }}>
                    <BaseTextField
                        name="unit"
                        id="unit"
                        label='Unit'
                        errors={errors}
                        required={false}
                        validate={register('unit', {
                            maxLength: {
                                value: 500,
                                message: 'Unit must be less than 500',
                            },
                        })}
                    />
                </Grid>
                <Grid size={{ xs: 12, sm: 9, md: 9 }}>
                    <BaseTextField
                        name="address"
                        id="address"
                        label='Address'
                        errors={errors}
                        required={false}
                        validate={register('address', {
                            maxLength: {
                                value: 50000,
                                message: 'Address must be less than 50000',
                            },
                        })}
                    />
                </Grid>
            </Grid>
            <Grid size={12} container spacing={1}>
                <BaseTextField
                    multiline
                    rows={4}
                    id='comment'
                    name="comment"
                    label="Comment"
                    errors={errors}
                    required={false}
                    validate={register('comment', {
                        maxLength: {
                            value: 5000,
                            message: 'Comment must be less than 5000 characters'
                        },
                    })}
                />
            </Grid>
            <Grid size={12} container spacing={1}>
                <BaseTextField
                    multiline
                    rows={4}
                    id='deliveryInstructions'
                    name="deliveryInstructions"
                    label="Delivery Instructions"
                    errors={errors}
                    required={false}
                    validate={register('deliveryInstructions', {
                        maxLength: {
                            value: 5000,
                            message: 'Delivery instructions must be less than 5000 characters'
                        },
                    })}
                />
            </Grid>
            <Grid size={12} container spacing={1}>
                <BaseTextField
                    multiline
                    rows={4}
                    id='adminNotes'
                    name="adminNotes"
                    label="Admin Notes"
                    errors={errors}
                    required={false}
                    validate={register('adminNotes', {
                        maxLength: {
                            value: 5000,
                            message: 'Admin notes must be less than 5000 characters'
                        },
                    })}
                />
            </Grid>
        </Grid>
    </Grid>
}
