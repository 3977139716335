import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Grid2 as Grid,
  Typography,
  IconButton,
  ListItemText,
  List,
  ListItem,
  Box,
  Divider,
  Tab,
  Tooltip
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import MessageIcon from '@mui/icons-material/Message';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EastIcon from '@mui/icons-material/East';
import { TuiSpinner, DataNotFound, Hooks, Table } from '../../../core';
import { tiffinsApi, historyApi } from '../../../js/slices/api_slices';
import { getFormattedDateFromUnixTimestamp, getFormattedDateTimeFromUnixTimestamp, getFormattedTimeFromUnixTimestamp } from '../../../js/lib/utils';
import { getStopStatusChip } from '../customer_utils';

const { useGetTiffinSubscriberQuery } = tiffinsApi;
const { useGetHistoryQuery, useLazyGetSingleHistoryQuery } = historyApi;
const { useSnackBarNotification } = Hooks;

const deliveriesHeadCells = [{
  id: 'date',
  title: 'Date',
}, {
  id: 'mealPlan',
  title: 'Meal Plan',
}, {
  id: 'phoneNumber',
  title: 'Phone Number',
}, {
  id: 'address',
  title: 'Address',
}, {
  id: 'status',
  title: 'Status',
}, {
  id: 'deliveryTime',
  title: 'Delivery Time',
}, {
  id: 'image',
  title: 'Image',
}, {
  id: 'businessNote',
  title: 'Business Note',
}, {
  id: 'customerNote',
  title: 'Customer Note',
}, {
  id: 'routeName',
  title: 'Route',
}];

const pdfHeadCells = [{
  id: 'date',
  title: 'Date',
}, {
  id: 'mealPlan',
  title: 'Meal Plan',
}, {
  id: 'phoneNumber',
  title: 'Phone Number',
}, {
  id: 'address',
  title: 'Address',
}, {
  id: 'status',
  title: 'Status',
}, {
  id: 'deliveryTime',
  title: 'Delivery Time',
}];

const messagesHeadCells = [{
  id: 'date',
  title: 'Date',
}, {
  id: 'phoneNumber',
  title: 'Phone Number',
}, {
  id: 'status',
  title: 'Status',
}, {
  id: 'message',
  title: 'Message',
}, {
  id: 'sentAt',
  title: 'Sent At',
}];

export default function CustomerHistoryDrawer({ open, handleClose, item }) {
  const [tabValue, setTabValue] = useState('deliveries');
  const [deliveries, setDeliveries] = useState([]);
  const [messages, setMessages] = useState([]);
  const [useGetHistoryQueryTrigger] = useLazyGetSingleHistoryQuery();
  const { errorSnackBar } = useSnackBarNotification();

  const { data,
    isLoading
  } = useGetTiffinSubscriberQuery({
    id: item?.id,
  }, { refetchOnMountOrArgChange: true, skip: !(item?.id && open) });

  let { data: historyData, isLoading: historyIsLoading } = useGetHistoryQuery({
    page: 1,
    pageSize: 90,
    sortKey: '-date',
    customerId: item?.id,
  }, { refetchOnMountOrArgChange: true, skip: !(item?.id && open && (tabValue === 'deliveries' || tabValue === 'messages')) });

  const processItems = (items) => {
    const deliveries = [];
    const messages = [];
    items.forEach((item) => {
      const { routes, messages: messagesInItem, date, id: historyId } = item;
      if (routes?.[0]?.stops?.[0]) {
        const { customer: deliveryCustomer, id: stopId, address, imageName, noteForBusiness, noteForCustomer, status, events, routeId } = routes?.[0]?.stops?.[0] || {};
        deliveries.push({
          date: getFormattedDateFromUnixTimestamp(date),
          mealPlan: `${deliveryCustomer?.tiffin?.name} ${deliveryCustomer?.tiffin?.quantity > 1 ? '(Qty: ' + deliveryCustomer?.tiffin?.quantity + ')' : ''}`,
          phoneNumber: deliveryCustomer?.phoneNumber?.number,
          address: address?.description,
          status: getStopStatusChip(status),
          deliveryTime: events?.finish ? getFormattedTimeFromUnixTimestamp(events?.finish) : '--',
          imageName: (imageName &&
            <IconButton
              onClick={async () => {
                const res = await useGetHistoryQueryTrigger({ id: historyId, routeId, stopId });
                if (res?.data?.data?.imageUrl) {
                  window.open(res?.data?.data?.imageUrl, '_blank');
                } else {
                  errorSnackBar({ message: 'Image fetch failed' });
                }
              }}
            >
              <Tooltip title="View Image">
                <OpenInNewIcon />
              </Tooltip>
            </IconButton>) || '--',
          noteForBusiness: (noteForBusiness && <Tooltip title={noteForBusiness} placement='top' arrow>
            <MessageIcon />
          </Tooltip>) || '--',
          noteForCustomer: (noteForCustomer &&
            <Tooltip title={noteForCustomer} placement='top' arrow>
              <MessageIcon />
            </Tooltip>) || '--',
          routeName: routes?.[0]?.name,
          id: date,
        });
      }

      if (messagesInItem && messagesInItem.length > 0) {
        messagesInItem.forEach((messageInItem) => {
          const { customer, status: messageStatus, message, createdAt, id } = messageInItem;
          messages.push({
            date: getFormattedDateFromUnixTimestamp(date),
            phoneNumber: customer?.phoneNumber?.number,
            status: messageStatus,
            message: message ? <Tooltip title={message} placement='top' arrow><MessageIcon /></Tooltip> : '--',
            sentAt: getFormattedDateTimeFromUnixTimestamp(createdAt),
            id,
          });
        });
      }
    });
    return { deliveries, messages };
  };

  useEffect(() => {
    if (tabValue === 'deliveries' || tabValue === 'messages') {
      if (historyData?.data?.items && !deliveries.length && !messages.length) {
        const { deliveries, messages } = processItems(historyData.data.items);
        setDeliveries(deliveries);
        setMessages(messages);
      }
    } else {
      setDeliveries([]);
      setMessages([]);
    }
  }, [tabValue, historyData]);


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const downloadPastDeliveries = () => {
    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.setTextColor(128, 128, 128);
    const text = 'Delivery History';
    const centerOfPage = 105;
    let y = 10;
    doc.text(text, centerOfPage, y, { align: 'center' });

    const headCells = pdfHeadCells;
    const rowData = deliveries.map(delivery => ({
      ...delivery,
      status: delivery.status.props.label,
    }));

    y += 10;
    doc.setFontSize(12);
    doc.text(`Customer: ${item?.customer?.firstName} ${item?.customer?.lastName}`, centerOfPage, y, { align: 'center' });
    autoTable(doc, {
      theme: 'grid',
      head: [headCells.map(cell => cell.title)],
      body: rowData.map(row => headCells.map(cell => row[cell.id])),
      startY: y + 10,
    });
    doc.save(`${item?.customer?.firstName}_${item?.customer?.lastName}_delivery_history.pdf`);
  };

  const changesHistoryComponent = <List>
    {data?.data?.changesHistory?.map(({ createdBy, action, fieldName, newValue, oldValue, date }, index) => (
      <React.Fragment key={index}>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <>
                <Typography component="span" color="text.primary">
                  {`${createdBy?.firstName} ${createdBy?.lastName} ${action} the `}
                  <Typography component="span" fontWeight="bold">
                    {fieldName}
                  </Typography>
                  <Typography component="span" color="text.secondary" variant="body2" sx={{ ml: 1 }}>
                    {getFormattedDateTimeFromUnixTimestamp(date)}
                  </Typography>
                </Typography>
              </>
            }
            secondary={
              <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                <Typography component="span" variant="body2" color="text.secondary">
                  {oldValue}
                </Typography>
                <Box display="flex" alignItems="center" mx={1}>
                  <EastIcon fontSize="small" />
                </Box>
                <Typography component="span" variant="body2" color="text.primary">
                  {newValue}
                </Typography>
              </Box>
            }
          />
        </ListItem>
        {index < data.data.changesHistory.length - 1 && <Divider />}
      </React.Fragment>
    ))}
  </List>;

  const deliveriesHistoryComponent = <Table
    headers={deliveriesHeadCells}
    data={deliveries}
    setSelectedRow={() => { }}
  />;

  const messagesHistoryComponent = <Table
    headers={messagesHeadCells}
    data={messages}
    setSelectedRow={() => { }}
  />;

  return (
    <Drawer
      anchor="right"
      PaperProps={{
        sx: { width: '80%' },
      }}
      open={open}
      onClose={handleClose}
    >
      <Grid container sx={{ p: 2 }} spacing={1}>
        <Grid container size={12}>
          <Grid size={3} container justifyContent="flex-start" alignItems="center">
            <Grid size='auto'>
              <IconButton onClick={handleClose} sx={{ p: 0, pr: 1, pt: 0.5 }}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid size='auto'>
              <Typography variant="h6" fontWeight="bold">
                History
              </Typography>
            </Grid>
          </Grid>
          <Grid size={9} container justifyContent="flex-end" alignItems="center">
            {tabValue === 'deliveries' && (
              <Tooltip title="Download PDF">
                <IconButton
                  sx={{
                    color: '#008080',
                  }}
                  disabled={deliveries?.length <= 0}
                  onClick={downloadPastDeliveries}>
                  <PictureAsPdfIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
        <Grid size={12}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleTabChange}
                sx={{
                  '& .Mui-selected': {
                    color: '#008080',
                  },
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#008080',
                  },
                }}
              >
                <Tab label="Deliveries" value="deliveries" />
                <Tab label="Changes" value="changes" />
                <Tab label="Messages" value="messages" />
              </TabList>
            </Box>
            <TabPanel value="changes">
              <>
                {isLoading && <TuiSpinner />}
                {!isLoading && (
                  <Grid size={12}>
                    {isLoading ? <TuiSpinner /> : data?.data?.changesHistory?.length <= 0 ? <DataNotFound title="Changes" /> :
                      changesHistoryComponent}
                  </Grid>
                )}
              </>
            </TabPanel>
            <TabPanel value="deliveries">
              <>
                {historyIsLoading ? <TuiSpinner /> : deliveries?.length <= 0 ? <DataNotFound title="Deliveries" /> :
                  deliveriesHistoryComponent}
              </>
            </TabPanel>
            <TabPanel value="messages">
              <>
                {historyIsLoading ? <TuiSpinner /> : messages?.length <= 0 ? <DataNotFound title="Messages" /> :
                  messagesHistoryComponent}
              </>
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </Drawer >
  );
}