import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Divider,
  Drawer,
  Grid,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { DataGrid } from '@mui/x-data-grid';
import { CoreButton, TuiSpinner, DataNotFound, Hooks } from '../../../core';
import { getFormattedDateFromUnixTimestamp } from '../../../js/lib/utils';
import { isCancelled, isExpired } from '../customer_utils';
import CustomerHoldForm from './customer_hold_form';
import { tiffinsApi } from '../../../js/slices/api_slices';

const { useUpdateTiffinSubscriberMutation } = tiffinsApi;
const { useSnackBarNotification } = Hooks;

export default function CustomerHoldDrawer({ open, handleClose, viewOnly, item, fetchCustomer, refetchCustomerStats }) {
  const { successSnackBar, errorSnackBar } = useSnackBarNotification();
  const [action, setAction] = useState(null);
  const [showHoldForm, setShowHoldForm] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rows, setRows] = useState(item?.holdDates || []);

  useEffect(() => {
    setRows(item?.holdDates || []);
  }, [item]);

  const [updateCustomer, {
    data: updateCustomerData,
    isSuccess: updateCustomerSuccess,
    error: updateCustomerError,
    isError: updateCustomerIsError,
    isLoading: updateCustomerIsLoading,
  }] = useUpdateTiffinSubscriberMutation();

  useEffect(() => {
    if (updateCustomerSuccess && updateCustomerData) {
      successSnackBar({ message: 'Customer updated successfully' });
      setRows(updateCustomerData?.data?.holdDates || []);
      fetchCustomer({ id: item?.id });
      setAction(null);
    } else if (updateCustomerIsError && updateCustomerError) {
      errorSnackBar({ message: updateCustomerError?.data?.errorMessage });
    }
  }, [updateCustomerSuccess, updateCustomerIsError, updateCustomerData, updateCustomerError]);

  const handleFormSubmit = async (data) => {
    if (data?.startDate > item?.endDate) {
      errorSnackBar({ message: 'Hold start date must be less than subscription end date' });
      return;
    }

    const isOverlapping = rows.some(row => {
      const newStart = moment(data.startDate);
      const newEnd = moment(data.endDate);
      const existingStart = moment(row.startDate);
      const existingEnd = moment(row.endDate);

      return data?.id !== selectedRow?.id && (newStart.isBetween(existingStart, existingEnd, null, '[]') ||
        newEnd.isBetween(existingStart, existingEnd, null, '[]') ||
        existingStart.isBetween(newStart, newEnd, null, '[]') ||
        existingEnd.isBetween(newStart, newEnd, null, '[]'));
    });

    if (isOverlapping) {
      errorSnackBar({ message: 'Hold dates cannot overlap' });
      return;
    }
    await updateCustomer({
      id: item?.id,
      data: {
        ...item,
        holdDates: action === 'add' ? [...rows, data] : rows?.map(row => {
          if (row.id === selectedRow?.id) {
            return data;
          }
          return row;
        }),
      }
    })
    setSelectedRow(null);
    setShowHoldForm(false);
  };

  const columns = [
    { field: 'startDate', headerName: 'Start Date', width: 120, editable: false },
    { field: 'endDate', headerName: 'End Date', width: 120, editable: false },
    {
      field: 'extendSubscription', headerName: 'Extend Subscription', width: 150, editable: false,
      renderCell: (params) => {
        return params?.row?.extendSubscription ? 'Yes' : 'No';
      },
    },
    { field: 'reason', headerName: 'Reason', width: 350, editable: false },
    {
      field: 'action',
      type: 'actions',
      headerName: 'Actions',
      width: 130,
      cellClassName: 'actions',
      getActions: (data) => {
        const result = [];
        if (moment().endOf('day').isSameOrBefore(moment(data?.row?.endDate).endOf('day'))) {
          result.push(
            data?.row?.id === selectedRow?.id && updateCustomerIsLoading ? <TuiSpinner /> :
              <IconButton
                key={data?.row?.id}
                edge="end"
                disabled={moment(data?.row?.endDate).isBefore(moment().startOf('day'))}
                aria-label="update"
                onClick={() => {
                  setAction('update');
                  setSelectedRow(data?.row);
                  setShowHoldForm(true);
                }}>
                <EditOutlinedIcon />
              </IconButton>,
            data?.row?.id === selectedRow?.id && updateCustomerIsLoading ? <TuiSpinner /> :
              <IconButton
                key={data?.row?.id}
                edge="end"
                aria-label="delete"
                disabled={moment(data?.row?.startDate).isBefore(moment().startOf('day'))}
                onClick={async () => {
                  setAction('delete');
                  setSelectedRow(data?.row);
                  updateCustomer({
                    id: item?.id,
                    data: {
                      ...item,
                      holdDates: rows.filter(row => row.id !== data?.row?.id),
                    }
                  })
                }}>
                <DeleteOutlinedIcon />
              </IconButton>
          );
        }
        return result;
      },
    },
  ];

  return (
    <Drawer
      anchor="right"
      PaperProps={{
        sx: { width: '80%' },
      }}
      open={open}
      onClose={handleClose}>
      <Grid container sx={{ p: 2 }} spacing={1}>
        <Grid item container xs={12}>
          <Grid item xs={1}>
            <IconButton
              onClick={handleClose}
              sx={{
                p: 0,
                mb: 2,
              }} >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" fontWeight='bold'>Hold Date</Typography>
          </Grid>
          <Grid item xs={7} container justifyContent='flex-end'>
            {!viewOnly && <CoreButton
              variant='contained'
              isLoading={updateCustomerIsLoading}
              fullWidth={false}
              startIcon={<AddOutlinedIcon />}
              sx={{
                'maxHeight': 60,
                'backgroundColor': 'secondary.contrastText',
              }}
              disabled={isCancelled(item) || isExpired(item)}
              onClickHandler={() => {
                setAction('add');
                setShowHoldForm(true);
              }}>
              Add
            </CoreButton>}
          </Grid>
        </Grid>
        {!isCancelled(item) && !isExpired(item) &&
          <> <Grid
            item
            xs={12}
            container
            spacing={1}
            sx={{ mt: 1 }}
          >
          </Grid>
            <CustomerHoldForm
              open={showHoldForm}
              handleClose={() => setShowHoldForm(false)}
              action={action}
              parentHandleSubmit={handleFormSubmit}
              isLoading={false}
              item={selectedRow}
            />
          </>
        }
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {rows.length === 0 && <Grid item xs={12}>
          <DataNotFound title="Hold Date History" />
        </Grid>}
        {rows.length > 0 && <> <Grid item xs={12} container>
          <Grid item xs={6}>
            <Typography fontWeight="bold" variant="h6">Items</Typography>
          </Grid>
        </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                'height': '70vh',
                'width': '100%',
                '& .actions': {
                  color: 'text.secondary',
                },
                '& .textPrimary': {
                  color: 'text.primary',
                },
              }}
            >
              <DataGrid
                rows={rows?.map((row) => {
                  return {
                    ...row,
                    startDate: getFormattedDateFromUnixTimestamp(row?.startDate),
                    endDate: getFormattedDateFromUnixTimestamp(row?.endDate),
                  }
                })}
                columns={columns}
                editMode="row"
              />
            </Box>
          </Grid>
        </>
        }
      </Grid>
    </Drawer>
  );
}