import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { ConfirmationModal } from '../../core';

const steps = [
  {
    label: 'Payment Pending Alerts',
    description: 'Payment pending alerts will be sent automatically on the expected payment date. To enable this feature, go to settings and alerts page.',
  },
  {
    label: 'Payment Received Alerts Update',
    description: 'From now onwards, payment received alerts will also include the payment amount along with subscription start and end date.',
  },
  {
    label: 'Expiry Reminder Alert Time Change',
    description: 'Expiry reminder alert time has been changed from 12:00 AM to 9:00 AM in the morning.',
  },
  {
    label: 'Cancelled Subscriptions Limit',
    description: 'From March 1, 2025, you will not be able to cancel more than limits in your plan. To view limits, please go to settings and billings page. If you want to keep it in software, reach out to us to get "cancel limit add-on" in your plan to increase the limits.',
  },
];

export default function WhatsNewComponent({ displayModal, setDisplayModal, ...rest }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const stepper = <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
    <Paper
      square
      elevation={0}
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: 50,
        pl: 2,
        bgcolor: 'background.default',
      }}
    >
      <Typography>{steps[activeStep].label}</Typography>
    </Paper>
    <Box sx={{ height: 255, maxWidth: 400, width: '100%', p: 2 }}>
      {steps[activeStep].description}
    </Box>
    <MobileStepper
      variant="text"
      steps={maxSteps}
      position="static"
      activeStep={activeStep}
      nextButton={
        <Button
          size="small"
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
        >
          Next
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
          Back
        </Button>
      }
    />
  </Box>;

  return (
    <ConfirmationModal
      {...rest}
      open={displayModal}
      handleClose={setDisplayModal}
      title="Whats New"
      actionText={stepper}
      hideCancelOption={true}
      actionHandler={setDisplayModal}
      type='Ok'
    />
  );
}
