import React, { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  Checkbox,
  Grid2 as Grid,
  Typography,
  Tooltip,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import RouteAreaDrawer from '../components/route_area_drawer';
import HelpIcon from '@mui/icons-material/Help';
import { BaseTextField, TuiAutoComplete, Hooks, DateTimePicker, CoreButton } from '../../../core/index';
import { googleMapsApi, employeesApi } from '../../../js/slices/api_slices';
import { USERS, SALARY_TYPES } from '../../../js/lib/constants';

const { useGetPlacesQuery } = googleMapsApi;
const { useGetEmployeesQuery } = employeesApi;
const routeCostTypes = [SALARY_TYPES.PER_HOUR, SALARY_TYPES.PER_STOP, SALARY_TYPES.PER_PACKAGE, SALARY_TYPES.PER_KM, SALARY_TYPES.PER_ROUTE];

export default function RouteForm({ viewOnly, item }) {
  const { register, control, watch, formState: { errors }, setValue } = useFormContext();
  const navigate = useNavigate();
  const [newDriverInputValue, setNewDriverInputValue] = useState('');
  const debouncedDriverInputValue = Hooks.useDebounce(newDriverInputValue, 500);
  const [startAddressInputValue, setStartAddressInputValue] = useState('');
  const [finishAddressInputValue, setFinishAddressInputValue] = useState('');
  const debouncedStartAddressInput = Hooks.useDebounce(startAddressInputValue, 500);
  const debouncedFinishAddressInput = Hooks.useDebounce(finishAddressInputValue, 500);
  const settings = useSelector((store) => store?.settings?.settings);
  const [showRouteAreaDrawer, setShowRouteAreaDrawer] = useState(false);

  const { data: startAddressData,
    isLoading: startAddressLoading } = useGetPlacesQuery({
      input: debouncedStartAddressInput,
    }, { refetchOnMountOrArgChange: true });

  const { data: finishAddressData,
    isLoading: finishAddressLoading } = useGetPlacesQuery({
      input: debouncedFinishAddressInput,
    }, { refetchOnMountOrArgChange: true });

  const { data: existingDriversData,
    isLoading: existingDriversIsLoading } = useGetEmployeesQuery({
      type: USERS.TYPES.EMPLOYEE.DRIVER,
      status: Object.values(USERS.STATUS).filter((item) => item !== USERS.STATUS.BLOCKED),
      contains: { 'firstName': debouncedDriverInputValue, 'lastName': debouncedDriverInputValue },
    }, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    const driver = watch('driver');
    if (driver) {
      if (driver?.salary?.type) {
        setValue('cost.type', driver?.salary?.type);
      }
      if (driver?.salary?.value) {
        setValue('cost.value', driver?.salary?.value);
      }
    } else {
      setValue('cost.type', null);
      setValue('cost.value', null);
    }
  }, [watch('driver')])

  return <><Grid
    container
    size={12}
    justifyContent="center"
    sx={{
      p: 2,
    }}>
    <Grid size={8} container spacing={3} sx={{
      p: 4,
      mt: 1,
      backgroundColor: 'white',
      border: '1px solid #e0e0e0',
      borderColor: 'grey.300',
      borderRadius: '5px',
    }}>
      <Grid size={12} container spacing={1}>
        <Grid size={6}>
          <BaseTextField
            id='name'
            name="name"
            label="Name"
            disabled={viewOnly}
            errors={errors}
            required={true}
            validate={register('name', {
              required: 'Name must be valid',
              maxLength: {
                value: 100,
                message: 'Name must be less than 100 characters'
              },
              validate: (value) => {
                const trimmedValue = value?.trim().toLowerCase();
                if (trimmedValue === 'none') {
                  return 'Name "None" is not allowed';
                }
                return true;
              }
            })}
          />
        </Grid>
        <Grid size={6}>
          <Controller
            control={control}
            rules={{ required: 'Invalid Time' }}
            name="startTime"
            render={({ field: { onChange, value } }) => {
              return (
                <DateTimePicker
                  control={control}
                  onChange={(event) => {
                    onChange(event);
                  }}
                  disabled={viewOnly}
                  required={true}
                  errors={errors}
                  label="Start Date & Time"
                  value={value}
                  minDate={moment().startOf('day')}
                  maxDate={moment().endOf('day')}
                />
              );
            }
            }
          />
        </Grid>
      </Grid>
      <Grid size={12} container spacing={1}>
        <Grid size={4}>
          <Controller
            control={control}
            rules={{ required: 'Driver must be valid' }}
            name="driver"
            render={({ field: { onChange, value } }) => {
              return (
                <TuiAutoComplete
                  name="driver"
                  id="driver"
                  loading={existingDriversIsLoading}
                  freeSolo
                  errors={errors}
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  inputValue={newDriverInputValue}
                  onInputChange={(event, newInputValue) => {
                    if (newInputValue !== newDriverInputValue) {
                      setNewDriverInputValue(newInputValue);
                    }
                  }}
                  value={value}
                  label="Select Driver"
                  labelKey='firstName'
                  options={existingDriversData?.data?.items}
                  helperText={existingDriversData?.data?.items?.length === 0 ?
                    <Typography
                      variant="h7"
                      fontWeight="bold"
                      display="inline">
                      No Driver Exists
                      <Box ml={1} display="inline">
                        <CoreButton
                          sx={{
                            p: 0,
                            m: 0,
                            color: 'secondary.contrastText',
                            textDecoration: 'underline'
                          }}
                          fullWidth={false}
                          disableRipple
                          onClickHandler={() => navigate('/employees/new')}
                        >
                          Add Driver
                        </CoreButton>
                      </Box>
                    </Typography>
                    : ''}
                />
              );
            }}
          />
        </Grid>
        <Grid size={4}>
          <Controller
            control={control}
            name="cost.type"
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <InputLabel
                    id="cost.type"
                    sx={{
                      color: errors?.['cost.type']?.message ? 'secondary.contrastText.100' : 'default',
                    }}>
                    Salary Type (Optional)
                  </InputLabel>
                  <Select
                    disabled={viewOnly}
                    labelId="cost.type"
                    id="cost.type"
                    error={!!errors?.['cost.type']?.message}
                    label="Salary Type (Optional)"
                    onChange={(event) => field.onChange(event.target.value)}
                    value={field.value || ''} // replace null with an empty string
                  >
                    {routeCostTypes.map((type) => <MenuItem key={type} value={type}>{type}</MenuItem>)}
                  </Select>
                  <Typography fontSize={12} mt={1} ml={2} color='secondary.contrastText.100'>{errors?.['cost.type']?.message}</Typography>
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid size={4}>
          <BaseTextField
            id='cost.value'
            name="cost.value"
            label="Salary (Optional)"
            disabled={viewOnly}
            type='number'
            startAdornment='$'
            errors={errors}
            required={false}
            validate={register('cost.value')}
            inputProps={{ step: '0.01' }} // Allow decimal values
          />
        </Grid>
      </Grid>
      <Grid size={12}>
        <Controller
          control={control}
          rules={{ required: 'Address must be valid' }}
          name="startAddress"
          render={({ field: { onChange, value } }) => {
            return (
              <TuiAutoComplete
                disabled={viewOnly}
                name="startAddress"
                id="startAddress"
                loading={startAddressLoading}
                freeSolo
                errors={errors}
                onChange={(event, item) => {
                  onChange(item);
                }}
                inputValue={startAddressInputValue}
                onInputChange={(event, newInputValue) => {
                  setStartAddressInputValue(newInputValue);
                }}
                value={value}
                label="Start Address"
                labelKey='description'
                options={startAddressData?.data?.items}
              />
            );
          }}
        />
      </Grid>
      <Grid size={12}>
        <Controller
          control={control}
          name="finishAddress"
          render={({ field: { onChange, value } }) => {
            return (
              <TuiAutoComplete
                disabled={viewOnly}
                name="finishAddress"
                id="finishAddress"
                loading={finishAddressLoading}
                freeSolo
                errors={errors}
                onChange={(event, item) => {
                  onChange(item);
                }}
                required={watch('reverse') ? true : false}
                inputValue={finishAddressInputValue}
                onInputChange={(event, newInputValue) => {
                  setFinishAddressInputValue(newInputValue);
                }}
                value={value}
                label="Finish Address"
                labelKey='description'
                options={finishAddressData?.data?.items}
              />
            );
          }}
        />
      </Grid>
      <Grid
        size={12}
        spacing={1}
        container>
        <Grid size={6} container sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '4px' }}>
          <Grid size={10} container sx={{ padding: '4px' }}>
            <Typography sx={{ p: 0, pr: 0, pt: 1 }}>Reverse</Typography>
            <Tooltip
              sx={{ p: 0 }}
              arrow
              placement="top"
              title='Reverse the route direction if the start and finish addresses are provided'>
              <HelpIcon fontSize='small' color='secondary' sx={{ mt: 1.2 }} />
            </Tooltip>
          </Grid>
          <Grid size={2} sx={{ padding: '4px' }}>
            <Controller
              control={control}
              name="reverse"
              render={({ field }) => (
                <Checkbox
                  id='reverse'
                  name='reverse'
                  {...field}
                  checked={field.value}
                  sx={{
                    pl: 0,
                    color: 'secondary.contrastText',
                    '&.Mui-checked': {
                      color: 'secondary.contrastText',
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid size={6} container>
          <FormControl fullWidth>
            <InputLabel
              shrink
              sx={{
                color: errors?.['avgStopTime']?.message ? 'secondary.contrastText.100' : 'default',
              }}
            >
              Average Stop Time
            </InputLabel>
            <Controller
              rules={{ required: 'Invalid Selection' }}
              control={control}
              name="avgStopTime"
              render={({ field }) => (
                <Select
                  id="average-stop-time"
                  error={!!errors?.['avgStopTime']?.message}
                  label="Average Stop Time"
                  onChange={(event) => field.onChange(event.target.value)}
                  value={field?.value}
                >
                  {Array.from({ length: 10 }, (_, i) => i + 1).map((value) => (
                    <MenuItem key={value} value={value * 60}>
                      {value} {value === 1 ? 'minute' : 'minutes'}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid size={12} container justifyContent='flex-end'>
        <CoreButton
          toolTipTitle={
            !settings?.services?.routeRecommendation?.eligible ? (
              "Plan upgrade required for this feature"
            ) : !settings?.services?.routeRecommendation?.enable ? (
              'Enable this feature in Settings'
            ) : (
              'Set delivery route area to get route recommendation during customer creation'
            )
          }
          fullWidth={false}
          variant="contained"
          disabled={!(settings?.services?.routeRecommendation?.enable && settings?.services?.routeRecommendation?.eligible)}
          onClickHandler={() => setShowRouteAreaDrawer(true)}
        >
          Set Route Area
        </CoreButton>
      </Grid>
    </Grid>
  </Grid>
    <RouteAreaDrawer viewOnly={false} item={item} open={showRouteAreaDrawer} handleClose={() => setShowRouteAreaDrawer(false)} />
  </>

}
