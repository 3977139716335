import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

export default function CoreButton({
  children,
  type,
  variant,
  fullWidth = true,
  onClickHandler,
  isLoading = false,
  disabled = false,
  sx = {},
  toolTipTitle,
  ...rest
}) {
  const button = (
    <LoadingButton
      type={type}
      fullWidth={fullWidth}
      loading={isLoading}
      variant={variant}
      onClick={onClickHandler}
      disabled={disabled}
      sx={{
        ...sx,
        backgroundColor: variant === 'contained' ? '#008080' : 'transparent',
      }}
      {...rest}
    >
      {children}
    </LoadingButton>
  );

  return toolTipTitle ? (
    <Tooltip title={toolTipTitle} arrow>
      <span>{button}</span>
      </Tooltip>
  ) : (
    button
  );
}

CoreButton.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary.contrastText']),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  toolTipTitle: PropTypes.string,
};