import React, { useEffect, useState } from 'react';
import {
  Grid2 as Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Select,
  ListItemText,
  OutlinedInput,
} from '@mui/material';
import moment from 'moment-timezone';
import DownloadIcon from '@mui/icons-material/DownloadOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { STORAGE_KEYS } from '../../../js/lib/constants';
import { tiffinsApi } from '../../../js/slices/api_slices';
import { TuiAppBar, Table, DatePicker, TuiSpinner, Hooks, CoreButton, ConfirmationModal } from '../../../core';
import { capitalizeFirstLetter, getFormattedDateFromUnixTimestamp, getFormattedDateTimeFromUnixTimestamp } from '../../../js/lib/utils';
import DinespotLogo from '../../../assets/images/dinespot_black_logo.png';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';

const { useGetTiffinCookingPlanQuery } = tiffinsApi;
const { useSnackBarNotification } = Hooks;

const tiffinItemsHeadCells = [{
  id: 'name',
  title: 'Name',
}, {
  id: 'quantity',
  title: 'Quantity',
}, {
  id: 'unit',
  title: 'Unit',
}, {
  id: 'packets',
  title: 'Packets',
}];

const packingListHeadCells = [{
  field: 'index',
  headerName: 'No.',
  width: 20,
  editable: false
}, {
  field: 'name',
  headerName: 'Name',
  flex: 1,
  editable: false
}, {
  field: 'address',
  headerName: 'Address',
  flex: 3,
  editable: false
}, {
  field: 'meal',
  headerName: 'Meal Plan',
  flex: 1,
  editable: false
}, {
  field: 'quantity',
  headerName: 'Qty',
  flex: 0.5,
  editable: false
},
{
  field: 'changedItems',
  headerName: 'Item Changes Per Meal',
  flex: 2,
  editable: false,
  renderCell: (params) => (
    <Typography
      component="div"
      sx={{ whiteSpace: 'pre-line' }} // To break the line in the cell
    >
      {params.value}
    </Typography>
  )
}, {
  field: 'comments',
  headerName: 'Comments',
  flex: 2,
  editable: false,
  renderCell: (params) => (
    <Typography
      component="div"
      sx={{ whiteSpace: 'pre-line' }} // To break the line in the cell
    >
      {params.value}
    </Typography>
  )
}];

const tiffinHeadCells = [{
  id: 'name',
  title: 'Name',
}, {
  id: 'quantity',
  title: 'Quantity',
}];

const defaultStickerFields = ['Customer Name', 'Customer Phone Number', 'Customer Address', 'Meal Name / Quantity', 'Meal Items', 'Route Name'];
const stickerLabelStyles = {
  one_and_two_five_eight_inch: '1" x 2" 5/8 (30 Labels / Letter Size)',
  regular: 'Regular (Plain Paper / Letter Size)',
};

export default function TiffinsCookingPlan() {
  const settings = useSelector((store) => store?.settings?.settings);
  moment.tz.setDefault(settings?.momentTimezone);
  const [loading, setLoading] = useState(false);
  const [cookingPlanDownload, setCookingPlanDownload] = useState(false);
  const [packingListDownload, setPackingListDownload] = useState(false);
  const [stickersDownload, setStickersDownload] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [stickerFields, setStickerFields] = useState(STORAGE_KEYS.STICKER_FIELDS in localStorage ? JSON.parse(localStorage.getItem(STORAGE_KEYS.STICKER_FIELDS)) : defaultStickerFields);
  const [labelStyle, setLabelStyle] = useState(STORAGE_KEYS.STICKER_LABEL_STYLE in localStorage ? localStorage.getItem(STORAGE_KEYS.STICKER_LABEL_STYLE) : stickerLabelStyles.regular);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [packingList, setPackingList] = useState({
    homeDelivery: [],
    pickUp: [],
  });
  const [cookingPlan, setCookingPlan] = useState({
    overall: {
      meals: [],
      mealItems: [],
    },
    homeDelivery: {
      meals: [],
      mealItems: [],
    },
    pickUp: {
      meals: [],
      mealItems: [],
    },
  });
  const [cookingPlanExpanded, setCookingPlanExpanded] = useState(true);
  const [packingListExpanded, setPackingListExpanded] = useState(true);
  const { successSnackBar } = useSnackBarNotification();

  const {
    data,
    isSuccess,
    isFetching,
  } = useGetTiffinCookingPlanQuery({
    date: moment(selectedDate).endOf('day').valueOf(),
  }, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (isSuccess && data) {
      const { cookingPlan, packingList } = data?.data || {};
      setCookingPlan(cookingPlan);
      setPackingList(packingList);
    }
  }, [data, isSuccess]);


  const handleCookingPlanDownload = () => {
    setLoading(true);
    const { overall, homeDelivery, pickUp } = cookingPlan;
    if (cookingPlan?.overall?.meals?.length) {
      const doc = new jsPDF();
      doc.setFontSize(16);
      doc.setTextColor(128, 128, 128);
      let text = `Cooking Plan: ${getFormattedDateFromUnixTimestamp(selectedDate, 'DD MMMM (dddd)')}`;
      const centerOfPage = 105;
      let y = 10;
      doc.text(text, centerOfPage, y, { align: 'center' });
      let textWidth = doc.getTextWidth(text);
      doc.setDrawColor(128, 128, 128);
      doc.setLineWidth(0.5);
      doc.line(centerOfPage - textWidth / 2, ++y, centerOfPage + textWidth / 2, y);

      doc.setFontSize(14);
      y = 20;
      text = 'Overall';
      doc.text(text, centerOfPage, y, { align: 'center' });
      textWidth = doc.getTextWidth(text)
      doc.line(centerOfPage - textWidth / 2, ++y, centerOfPage + textWidth / 2, y);
      y += 5;
      autoTable(doc, {
        theme: 'grid',
        columnStyles: { 0: { cellWidth: 45 }, 1: { cellWidth: 25 } },
        head: [tiffinHeadCells.map(({ title }) => title)],
        body: overall?.meals?.length ? overall.meals.map(({ name, quantity }) => [name, quantity]) : [],
        foot: [['Total', overall?.meals?.reduce((acc, { quantity }) => acc + quantity, 0)]],
        startY: y,
        margin: { left: 10, bottom: 15 },
        styles: { cellPadding: 4 },
        tableWidth: 'wrap',
      });

      autoTable(doc, {
        theme: 'grid',
        columnStyles: { 0: { cellWidth: 30 }, 1: { cellWidth: 25 }, 2: { cellWidth: 30 }, 3: { cellWidth: 30 } },
        head: [tiffinItemsHeadCells.map(({ title }) => title)],
        body: overall?.mealItems?.length ? overall?.mealItems?.map(({ name, quantity, unit, packetDetails }) =>
          [
            capitalizeFirstLetter(name),
            quantity,
            unit,
            packetDetails?.map(({ quantityPerPacket, packets }) => {
              return `${quantityPerPacket} x ${packets} (Packets)`;
            }).join('\n')
          ]) : [],
        startY: y,
        margin: { left: 90, bottom: 15 },
        styles: { cellPadding: 4 },
        tableWidth: 'wrap',
      });

      homeDelivery.meals.forEach((item) => {
        const { route, items } = item;
        if (items?.length) {
          doc.addPage();
          y = 20;
          text = route?.name ? `Route name: ${route?.name}` : 'Without Route';
          doc.text(text, centerOfPage, y, { align: 'center' });
          textWidth = doc.getTextWidth(text);
          doc.line(centerOfPage - textWidth / 2, ++y, centerOfPage + textWidth / 2, y);
          y += 5;

          autoTable(doc, {
            theme: 'grid',
            columnStyles: { 0: { cellWidth: 45 }, 1: { cellWidth: 25 } },
            head: [tiffinHeadCells.map(({ title }) => title)],
            body: items?.length ? items.map(({ name, quantity }) => [name, quantity]) : [],
            foot: [['Total', items?.reduce((acc, { quantity }) => acc + quantity, 0)]],
            startY: y,
            margin: { left: 10, bottom: 15 },
            styles: { cellPadding: 4 },
            tableWidth: 'wrap',
          });

          const mealItemsInRoute = homeDelivery.mealItems.find((mealItem) => mealItem.route?.id === route?.id)?.items;

          autoTable(doc, {
            theme: 'grid',
            columnStyles: { 0: { cellWidth: 30 }, 1: { cellWidth: 25 }, 2: { cellWidth: 30 }, 3: { cellWidth: 30 } },
            head: [tiffinItemsHeadCells.map(({ title }) => title)],
            body: mealItemsInRoute?.length ? mealItemsInRoute.map(({ name, quantity, unit, packetDetails }) =>
              [
                capitalizeFirstLetter(name),
                quantity,
                unit,
                packetDetails?.map(({ quantityPerPacket, packets }) => {
                  return `${quantityPerPacket} x ${packets} (Packets)`;
                }).join('\n')
              ]) : [],
            startY: y,
            margin: { left: 90, bottom: 15 },
            styles: { cellPadding: 4 },
            tableWidth: 'wrap',
          });
        }
      });

      if (pickUp?.meals?.length) {
        doc.addPage();
        y = 20;
        text = 'Pick Up';
        doc.text(text, centerOfPage, y, { align: 'center' });
        textWidth = doc.getTextWidth(text)
        doc.line(centerOfPage - textWidth / 2, ++y, centerOfPage + textWidth / 2, y);
        y += 5;

        autoTable(doc, {
          theme: 'grid',
          columnStyles: { 0: { cellWidth: 45 }, 1: { cellWidth: 25 } },
          head: [tiffinHeadCells.map(({ title }) => title)],
          body: pickUp?.meals?.length ? pickUp.meals.map(({ name, quantity }) => [name, quantity]) : [],
          foot: [['Total', pickUp?.meals?.reduce((acc, { quantity }) => acc + quantity, 0)]],
          startY: y,
          margin: { left: 10, bottom: 15 },
          styles: { cellPadding: 4 },
          tableWidth: 'wrap',
        });

        autoTable(doc, {
          theme: 'grid',
          columnStyles: { 0: { cellWidth: 30 }, 1: { cellWidth: 25 }, 2: { cellWidth: 30 }, 3: { cellWidth: 30 } },
          head: [tiffinItemsHeadCells.map(({ title }) => title)],
          body: pickUp?.mealItems?.length ? pickUp?.mealItems?.map(({ name, quantity, unit, packetDetails }) =>
            [
              capitalizeFirstLetter(name),
              quantity,
              unit,
              packetDetails?.map(({ quantityPerPacket, packets }) => {
                return `${quantityPerPacket} x ${packets} (Packets)`;
              }).join('\n')
            ]) : [],
          startY: y,
          margin: { left: 90, bottom: 15 },
          styles: { cellPadding: 4 },
          tableWidth: 'wrap',
        });
      }

      doc.setFontSize(8);
      doc.setFont('helvetica', 'bold');
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        var img = new Image();
        img.src = DinespotLogo;
        doc.addImage(img, 'PNG', 10, 285, 20, 5);
        doc.text(185, 288, `Page ${i} / ${doc.getNumberOfPages()}`);
        doc.text(centerOfPage, 288, getFormattedDateTimeFromUnixTimestamp(Date.now(), true, true, 'YYYY/MM/DD'), { align: 'center' });
      }

      doc.save(`cooking_plan_${getFormattedDateTimeFromUnixTimestamp(Date.now(), true, true, 'YYYY/MM/DD')}.pdf`);
    } else {
      successSnackBar({ message: 'No data available.' });
    }
    setLoading(false);
  };

  const handlePackingListDownload = async () => {
    setLoading(true);
    if (packingList?.homeDelivery?.length || packingList?.pickUp?.length) {
      const doc = new jsPDF();
      doc.setFontSize(16);
      doc.setTextColor(128, 128, 128);
      let text = `Packing List: ${getFormattedDateFromUnixTimestamp(selectedDate, 'DD MMMM (dddd)')}`;
      const x = 105;
      let y = 10;
      doc.text(text, x, y, { align: 'center' });
      let textWidth = doc.getTextWidth(text);
      doc.setDrawColor(128, 128, 128);
      doc.setLineWidth(0.5);
      doc.line(x - textWidth / 2, ++y, x + textWidth / 2, y);
      const headers = ['#', 'Name', 'Meal', 'Qty', 'Item Changes Per Meal', 'Comments'];

      y += 10;

      packingList.homeDelivery.forEach((item, index) => {
        const mealCount = item?.customers?.map(({ tiffin }) => tiffin?.quantity).reduce((acc, val) => acc + val, 0);
        if (index !== 0) {
          if (mealCount <= 0) {
            return;
          }
          doc.addPage();
        }

        doc.setFontSize(18);
        let routeName = item?.route?.name ? `Route name: ${item?.route?.name}` : 'Without Route';
        routeName += ` (${mealCount} Meal${mealCount === 1 ? '' : 's'})`;
        const routeY = index === 0 ? y : 10;
        doc.text(routeName, x, routeY, { align: 'center' });
        const textWidth = doc.getTextWidth(routeName);
        const underlineY = routeY + 1;
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.5);
        doc.line(x - textWidth / 2, underlineY, x + textWidth / 2, underlineY);

        const tiffinData = {};
        const tableData = [];
        item.customers.forEach((customer, stopIndex) => {
          const { tiffin } = customer;
          const { changedItems } = tiffin;
          if (tiffinData[tiffin?.name]) {
            tiffinData[tiffin?.name] = tiffinData[tiffin?.name] + 1;
          } else {
            tiffinData[tiffin?.name] = 1;
          }

          tableData.push([
            stopIndex + 1,
            customer?.isNew ? `${customer?.firstName} ${customer?.lastName} (New)` : `${customer?.firstName} ${customer?.lastName}`,
            tiffin?.name,
            tiffin?.quantity,
            changedItems?.length
              ? [...changedItems]
                .sort((a, b) => a.status.localeCompare(b.status))
                .map(item => `${item?.status} - ${capitalizeFirstLetter(item?.name)} (${item?.quantity} ${item?.unit?.toLowerCase()})`)
                .join('\n')
              : '',
            customer?.comment
          ]);
        });

        autoTable(doc, {
          theme: 'grid',
          head: [headers],
          body: tableData,
          startY: routeY + 10,
          margin: { left: 10, right: 10, bottom: 15 },
          styles: { cellPadding: 3 },
        });
      });

      const pickUpMealCount = packingList?.pickUp?.length || 0;
      if (pickUpMealCount) {
        doc.addPage();
        y = 10;
        text = 'Pick Up';
        text += ` (${pickUpMealCount} Meal${pickUpMealCount === 1 ? '' : 's'})`;
        doc.text(text, x, y, { align: 'center' });
        textWidth = doc.getTextWidth(text);
        doc.line(x - textWidth / 2, y + 1, x + textWidth / 2, y + 1);
        y += 5;

        autoTable(doc, {
          theme: 'grid',
          head: [headers],
          body: packingList.pickUp.map((customer, index) => {
            const { tiffin } = customer;
            const { changedItems } = tiffin;
            return [
              index + 1,
              customer?.isNew ? `${customer?.firstName} ${customer?.lastName} (New)` : `${customer?.firstName} ${customer?.lastName}`,
              tiffin?.name,
              changedItems?.length
                ? [...changedItems]
                  .sort((a, b) => a.status.localeCompare(b.status))
                  .map(item => `${item?.status} - ${item?.name} (${item?.quantity} ${item?.unit?.toLowerCase()})`)
                  .join('\n')
                : '',
              customer?.comment
            ];
          }),
          startY: y + 5,
          margin: { left: 10, right: 10, bottom: 15 },
          styles: { cellPadding: 3 },
        });
      }

      doc.setFont('helvetica', 'bold');
      doc.setFontSize(8);
      doc.setTextColor(128, 128, 128);
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        var img = new Image();
        img.src = DinespotLogo;
        doc.addImage(img, 'PNG', 10, 285, 20, 5);
        doc.text(185, 288, `Page ${i} / ${doc.getNumberOfPages()}`);
        doc.text(x, 288, getFormattedDateTimeFromUnixTimestamp(Date.now(), true, true, 'YYYY/MM/DD'), { align: 'center' });
      }

      doc.save(`packing_list_${getFormattedDateTimeFromUnixTimestamp(Date.now(), true, true, 'YYYY/MM/DD')}.pdf`);
    }
    setLoading(false);
  };

  const handleRegularStickersDownload = () => {
    setLoading(true);
    if (packingList?.homeDelivery?.length || packingList?.pickUp?.length) {
      const doc = new jsPDF({
        format: 'letter',
      });
      doc.setFont('helvetica');
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      const boxWidth = 60; // Adjusted to fit 3 boxes in a row
      const margin = 1;
      const padding = 2;
      let x = 10;
      let y = 10;

      const drawSticker = (columns, x, y, boxHeight) => {
        doc.setDrawColor(0, 0, 0);
        doc.setFillColor(255, 255, 255);
        doc.rect(x, y, boxWidth, boxHeight, 'FD');

        let currentY = y + 5;

        columns.forEach((item, index) => {
          const { text } = item;
          const lines = doc.splitTextToSize(text, boxWidth - 2 * padding);
          lines.forEach((line, lineIndex) => {
            doc.text(line, x + 2.5, currentY);
            if (!(index === columns.length - 1 && lineIndex === lines.length - 1)) {
              currentY += 5;
            }
          });
        });
      };

      const processCustomers = (customers, type, routeName) => {
        let maxBoxHeight = 0;
        const stickersData = customers.map((customer) => {
          const columns = [];
          if (stickerFields.includes('Customer Name')) {
            if (stickerFields.includes('Customer Phone Number') && customer?.phoneNumber?.number) {
              columns.push({
                column: 'Customer Name / Phone Number',
                maxLine: 1,
                text: `${customer?.firstName} ${customer?.lastName} / ${customer?.phoneNumber?.number}`
              });
            } else {
              columns.push({
                column: 'Customer Name',
                maxLine: 1,
                text: `${customer?.firstName} ${customer?.lastName}`
              });
            }
          }
          if (stickerFields.includes('Customer Address')) {
            const address = customer?.unit ? `${customer.unit} - ${customer?.address?.split(',')?.[0]}` : customer?.address?.split(',')?.[0];
            columns.push({
              text: address,
              column: 'Customer Address'
            });
          }
          if (stickerFields.includes('Meal Name / Quantity')) {
            columns.push({
              text: `${customer?.tiffin?.name} / ${customer?.tiffin?.quantity}`,
              column: 'Meal Name / Quantity'
            })
          }
          if (stickerFields.includes('Meal Items')) {
            columns.push({
              column: 'Meal Items',
              quantity: customer?.tiffin?.quantity,
              text: customer?.tiffin?.items?.filter(({ quantity }) => quantity > 0)?.map(({ name, quantity }) => {
                return quantity > 1 ? `${name} (${quantity})` : name;
              }).join(', ')
            });
          }
          if (stickerFields.includes('Route Name') && type === 'homeDelivery' && routeName) {
            columns.push({
              text: routeName,
              column: 'Route Name'
            });
          }
          const lines = columns.flatMap(column => doc.splitTextToSize(column?.text, boxWidth - 2 * padding));
          const boxHeight = lines.length * 5 + 2 * padding;
          if (boxHeight > maxBoxHeight) {
            maxBoxHeight = boxHeight;
          }
          return { columns, boxHeight };
        });

        stickersData.forEach(({ columns }) => {
          drawSticker(columns, x, y, maxBoxHeight);
          x += boxWidth + margin;
          if (x + boxWidth > doc.internal.pageSize.width - 10) {
            x = 10;
            y += maxBoxHeight + margin;
            if (y + maxBoxHeight > doc.internal.pageSize.height - 10) {
              doc.addPage();
              x = 10;
              y = 10;
            }
          }
        });
      };

      packingList.homeDelivery.forEach((item) => {
        processCustomers(item.customers, 'homeDelivery', item?.route?.name);
      });

      processCustomers(packingList.pickUp, 'pickUp');

      doc.setFontSize(8);
      doc.setTextColor(128, 128, 128);
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        var img = new Image();
        img.src = DinespotLogo;
        doc.addImage(img, 'PNG', 10, 285, 20, 5);
        doc.text(185, 288, `Page ${i} / ${doc.getNumberOfPages()}`);
        doc.text(105, 288, getFormattedDateTimeFromUnixTimestamp(Date.now(), true, true, 'YYYY/MM/DD'), { align: 'center' });
      }

      doc.save(`stickers_${getFormattedDateTimeFromUnixTimestamp(Date.now(), true, true, 'YYYY/MM/DD')}.pdf`);
    } else {
      successSnackBar({ message: 'No data available.', key: new Date().getTime(), autoHideDuration: 3000, action: null, moreOptions: {} });
    }
    setLoading(false);
  };

  const handleOneAndTwoFiveByEightInchStickersDownload = () => {
    const inchesToMm = (inches) => inches * 25.4;
    const marginTop = inchesToMm(0.5);
    const marginBottom = inchesToMm(0.42);
    const doc = new jsPDF({
      format: [inchesToMm(8.5), inchesToMm(11)],
      unit: 'mm',
      margins: { top: marginTop, bottom: marginBottom, left: 0, right: 0 }
    });

    const boxWidth = inchesToMm(2.64);
    const boxHeight = inchesToMm(1.01);
    const spaceX = inchesToMm(0.11);
    const spaceY = inchesToMm(-0.01);
    const padding = inchesToMm(0.16);
    const columnsPerPage = 3;

    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;

    const totalWidth = columnsPerPage * boxWidth + (columnsPerPage - 1) * spaceX;
    const marginLeft = (pageWidth - totalWidth) / 2;

    let x = marginLeft;
    let y = marginTop;

    const drawSticker = (columns, x, y) => {
      doc.setDrawColor(0, 0, 0);
      doc.setFillColor(255, 255, 255);
      doc.setFont('helvetica');
      doc.setFontSize(8);

      let currentY = y + padding;
      let totalLines = 0;

      columns.forEach((item) => {
        const { text, maxLine } = item;
        let lines = doc.splitTextToSize(text, boxWidth - 2 * padding);
        if (lines.length > maxLine) {
          lines = lines.slice(0, maxLine);
          lines[maxLine - 1] += '...';
        }
        lines.forEach((line) => {
          if (totalLines >= 5) return; // Stop if total lines exceed 5
          if (item.column === 'Customer Name' || item.column === 'Customer Name / Phone Number') {
            doc.setFontSize(11); // Increase font size for Customer Name
            if (doc.getTextWidth(line) > boxWidth - 2 * padding) {
              doc.setFontSize(8); // Reduce font size if text overflows
            }
          } else {
            doc.setFontSize(8); // Reset font size for other columns
          }
          doc.text(line, x + padding, currentY);
          currentY += 4; // Move to the next line after each column
          totalLines++;
        });
      });
    };

    const processCustomers = (customers, type, routeName) => {
      customers.forEach((customer) => {
        const columns = [];
        if (stickerFields.includes('Customer Name')) {
          if (stickerFields.includes('Customer Phone Number') && customer?.phoneNumber?.number) {
            columns.push({
              column: 'Customer Name / Phone Number',
              maxLine: 1,
              text: `${customer?.firstName} ${customer?.lastName} / ${customer?.phoneNumber?.number}`
            });
          } else {
            columns.push({
              column: 'Customer Name',
              maxLine: 1,
              text: `${customer?.firstName} ${customer?.lastName}`
            });
          }
        }

        if (stickerFields.includes('Customer Address') && customer?.address) {
          const address = customer?.unit ? `${customer.unit} - ${customer?.address?.split(',')?.[0]}` : customer?.address?.split(',')?.[0];
          columns.push({
            column: 'Customer Address',
            maxLine: 1,
            text: address
          });
        }
        if (stickerFields.includes('Route Name') && type === 'homeDelivery' && routeName) {
          columns.push({
            column: 'Route Name',
            maxLine: 1,
            text: routeName
          });
        }
        if (stickerFields.includes('Meal Name / Quantity')) {
          columns.push({
            column: 'Meal Name / Meal Quantity',
            maxLine: 1,
            text: `${customer?.tiffin?.name}`
          });
        }
        if (stickerFields.includes('Meal Items')) {
          columns.push({
            column: 'Meal Items',
            maxLine: 2,
            text: customer?.tiffin?.items?.filter(({ quantity }) => quantity > 0)?.map(({ name, quantity }) => {
              return quantity > 1 ? `${name} (${quantity})` : name;
            }).join(', ')
          });
        }

        for (let i = 0; i < customer?.tiffin?.quantity; i++) {
          const stickerColumns = columns.map(column => {
            if (column.column === 'Meal Name / Meal Quantity' && customer?.tiffin?.quantity > 1) {
              return {
                ...column,
                text: `${column.text} (#${i + 1} of ${customer?.tiffin?.quantity})`
              };
            }
            return column;
          });
          drawSticker(stickerColumns, x, y);
          x += boxWidth + spaceX;
          if (x + boxWidth > pageWidth) {
            x = marginLeft;
            y += boxHeight + spaceY;
            if (y + boxHeight > pageHeight) {
              doc.addPage();
              x = marginLeft;
              y = marginTop;
            }
          }
        }
      });
    };

    packingList.homeDelivery.forEach((item) => {
      processCustomers(item.customers, 'homeDelivery', item?.route?.name);
    });

    processCustomers(packingList.pickUp, 'pickUp');

    for (let i = 1; i <= doc.getNumberOfPages(); i++) {
      doc.setPage(i);
      var img = new Image();
      img.src = DinespotLogo;
      doc.addImage(img, 'PNG', 10, 270, 20, 5);
      doc.text(185, 274, `Page ${i} / ${doc.getNumberOfPages()}`);
      doc.text(105, 274, getFormattedDateTimeFromUnixTimestamp(Date.now(), true, true, 'YYYY/MM/DD'), { align: 'center' });
    }

    doc.save(`stickers_${getFormattedDateTimeFromUnixTimestamp(Date.now(), true, true, 'YYYY/MM/DD')}.pdf`);
  };

  const CookingPlanContent = ({ title, content, total }) => {
    const { mealItems, meals } = content;
    return <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          {title}
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>{`${total} Meals`}</Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Grid container spacing={3} justifyContent='center'>
          <Grid size={4}>
            <Table headers={tiffinHeadCells} data={meals} sort={true} />
          </Grid>
          <Grid size={5}>
            <Table headers={tiffinItemsHeadCells} data={mealItems?.map((mealItem) => {
              return {
                name: capitalizeFirstLetter(mealItem?.name),
                quantity: mealItem?.quantity,
                unit: mealItem?.unit,
                packets: mealItem?.packetDetails?.map(({ quantityPerPacket, packets }) => `${quantityPerPacket} x ${packets} (Packets)`).join('\n')
              }
            })} sort={true} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  }

  const PackingListHomeDeliveryContent = ({ title, item }) => {
    const totalCustomers = item?.customers?.length;
    const processedItems = item?.customers?.map((customer, index) => {
      return {
        id: customer?.id,
        index: index + 1,
        name: `${customer?.firstName} ${customer?.lastName} ${customer?.isNew ? ' (New)' : ''}`,
        address: `${customer.unit ? `${customer.unit} - ` : ''}${customer?.address}`,
        meal: customer?.tiffin?.name,
        quantity: customer?.tiffin?.quantity,
        changedItems: customer?.tiffin?.changedItems?.length
          ? customer?.tiffin?.changedItems?.map(({ status, name, quantity, unit }) => `${status} - ${name} (${quantity} ${unit})`).join('\n')
          : '',
        comments: customer?.comment,
      }
    });

    return <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${title}-panel-content`}
        id={`${title}-panel-header`}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          {title}
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>{`${totalCustomers} Customers`}</Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <DataGrid
          rows={processedItems}
          columns={packingListHeadCells}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pageSizeOptions={[5, 10, 20, 50]} // Add support for rows per page
        />
      </AccordionDetails>
    </Accordion>
  }

  const cookingPlanAccordion = <Accordion expanded={cookingPlanExpanded} onChange={() => setCookingPlanExpanded(!cookingPlanExpanded)}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="cooking-panel-content"
      id="cooking-panel-header"
    >
      <Typography>Cooking Plan</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <CookingPlanContent
        title='Overall'
        content={cookingPlan?.overall}
        total={cookingPlan?.overall?.meals?.reduce((total, meal) => total + meal.quantity, 0)}
      />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="home-delivery-panel-content"
          id="home-delivery-panel-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Home Delivery</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {`${cookingPlan?.homeDelivery?.meals.reduce((total, route) => total + route?.items?.reduce((sum, tiffin) => sum + tiffin.quantity, 0), 0)} Meals in ${cookingPlan?.homeDelivery?.meals?.length} Routes`}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          {cookingPlan?.homeDelivery?.meals.map((meal, index) => {
            const routeName = meal?.route?.id ? `Route name: ${meal?.route?.name}` : 'Without Route';
            const mealItems = cookingPlan?.homeDelivery?.mealItems?.find((item) => meal?.route?.id === item?.route?.id)?.items;
            const total = meal?.items?.reduce((total, item) => total + item?.quantity, 0);
            return (
              <Grid key={meal?.id || index} size={12} sx={{ mt: 1 }}>
                <CookingPlanContent
                  title={routeName}
                  content={{
                    meals: meal?.items,
                    mealItems,
                  }}
                  total={total}
                />
              </Grid>
            )
          }
          )}
        </AccordionDetails>
      </Accordion>
      <CookingPlanContent
        title='Pickup'
        content={cookingPlan?.pickUp}
        total={cookingPlan?.pickUp?.meals?.reduce((total, meal) => total + meal.quantity, 0)}
      />
    </AccordionDetails>
  </Accordion>;

  const packingListAccordion = <Accordion expanded={packingListExpanded} onChange={() => setPackingListExpanded(!packingListExpanded)}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="packing-list-panel-content"
      id="packing-list-panel-header"
    >
      <Typography>Packing List</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="home-delivery-panel-content"
          id="home-delivery-panel-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Home Delivery</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {`${packingList?.homeDelivery?.reduce((total, route) => total + route?.customers?.length, 0)} Customers in ${packingList?.homeDelivery?.length} Routes`}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          {packingList?.homeDelivery?.map((item, index) => (
            <Grid key={item?.id || index} size={12} sx={{ mt: 1 }}>
              <PackingListHomeDeliveryContent
                title={item?.route?.id ? `Route name: ${item?.route?.name}` : 'Without Route'}
                item={item}
              />
            </Grid>
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="pick-up-panel-content"
          id="pick-up-panel-header">
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Pick Up</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {`${packingList?.pickUp?.length} Customers`}
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <DataGrid
            rows={packingList?.pickUp?.map((customer, index) => {
              return {
                id: customer?.id,
                index: index + 1,
                name: `${customer?.firstName} ${customer?.lastName} ${customer?.isNew ? ' (New)' : ''}`,
                address: `${customer.unit ? `${customer.unit} - ` : ''}${customer?.address}`,
                meal: customer?.tiffin?.name,
                quantity: customer?.tiffin?.quantity,
                changedItems: customer?.tiffin?.changedItems?.length
                  ? customer?.tiffin?.changedItems?.map(({ status, name, quantity, unit }) => `${status} - ${name} (${quantity} ${unit})`).join('\n')
                  : '',
                comments: customer?.comment,
              }
            })}
            columns={packingListHeadCells}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            pageSizeOptions={[5, 10, 20, 50]} // Add support for rows per page
          />
        </AccordionDetails>
      </Accordion>
    </AccordionDetails>
  </Accordion>;

  const downloadModal = <ConfirmationModal
    open={showDownloadModal}
    handleClose={() => setShowDownloadModal(false)}
    title={
      <Grid container spacing={1}>
        <Grid size={12}>
          <Typography variant="h6">Select and click on Download</Typography>
        </Grid>
        <FormGroup>
          <FormControlLabel
            label="Cooking Plan"
            control={
              <Checkbox
                checked={cookingPlanDownload}
                onChange={(event) => setCookingPlanDownload(event.target.checked)}
                sx={{
                  color: 'secondary.contrastText',
                  '&.Mui-checked': {
                    color: 'secondary.contrastText',
                  },
                }}
              />
            }
          />
          <FormControlLabel
            label="Packing List"
            control={
              <Checkbox
                checked={packingListDownload}
                onChange={(event) => setPackingListDownload(event.target.checked)}
                sx={{
                  color: 'secondary.contrastText',
                  '&.Mui-checked': {
                    color: 'secondary.contrastText',
                  },
                }}
              />
            }
          />
          <FormControlLabel
            label="Stickers/Labels"
            control={
              <Checkbox
                checked={stickersDownload}
                onChange={(event) => setStickersDownload(event.target.checked)}
                sx={{
                  color: 'secondary.contrastText',
                  '&.Mui-checked': {
                    color: 'secondary.contrastText',
                  },
                }}
              />
            }
          />
        </FormGroup>
        {stickersDownload && <Grid size={12} container spacing={1}>
          <Grid size={12}>
            <FormControl fullWidth required={true}>
              <InputLabel sx={{ backgroundColor: 'white', paddingRight: '4px' }}>Select Fields</InputLabel>
              <Select
                multiple
                id="sticker-fields"
                label="Stickers Fields"
                value={stickerFields}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(', ')}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setStickerFields(
                    // On autofill we get a stringified value.
                    typeof value === 'string' ? value.split(',') : value,
                  );
                }}
              >
                {defaultStickerFields.map((column) =>
                  <MenuItem key={column} value={column}>
                    <Checkbox checked={stickerFields.includes(column)} />
                    <ListItemText primary={column} />
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid size={12}>
            <FormControl fullWidth size="small" sx={{ mt: 1 }}>
              <FormControl
                required={true}
                fullWidth={false}
              >
                <InputLabel>
                  Select Label Size
                </InputLabel>
                <Select
                  id="sticker-label-style"
                  label="Select Label Size"
                  onChange={(event) => setLabelStyle(event.target.value)}
                  value={labelStyle}
                >
                  {Object.entries(stickerLabelStyles).map(([key, value]) => (
                    <MenuItem key={key} value={value}>{value}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormControl>
          </Grid>
        </Grid>}
      </Grid>
    }
    type="Download"
    actionHandler={() => {
      if (cookingPlanDownload) {
        handleCookingPlanDownload();
      }
      if (packingListDownload) {
        handlePackingListDownload();
      }
      if (stickersDownload) {
        localStorage.setItem(STORAGE_KEYS.STICKER_FIELDS, JSON.stringify(stickerFields));
        localStorage.setItem(STORAGE_KEYS.STICKER_LABEL_STYLE, labelStyle);
        if (labelStyle === stickerLabelStyles.one_and_two_five_eight_inch) {
          handleOneAndTwoFiveByEightInchStickersDownload();
        } else {
          handleRegularStickersDownload();
        }
      }
      setShowDownloadModal(false);
    }}
  />;

  return (<>
    <Grid container spacing={1}>
      <Grid container size={12}>
        <Grid size={3}>
          <TuiAppBar title="Cook & Pack" />
        </Grid>
        <Grid size={9} sx={{ mt: 1 }} container justifyContent='flex-end' spacing={1}>
          <Grid size='auto'>
            <DatePicker
              size='small'
              onChange={(event) => {
                setSelectedDate(moment(event));
              }}
              value={selectedDate}
              label="Date"
            />
          </Grid>
          <Grid size="auto">
            <CoreButton
              isLoading={loading}
              fullWidth={false}
              disabled={false}
              variant='outlined'
              startIcon={<DownloadIcon />}
              onClickHandler={() => setShowDownloadModal(!showDownloadModal)}
            >
              Download
            </CoreButton>
          </Grid>
        </Grid>
      </Grid>
      {isFetching && <TuiSpinner />}
      {!isFetching && <><Grid size={12} sx={{ mt: 2 }}>
        {cookingPlanAccordion}
      </Grid>
        <Grid size={12} sx={{ mt: 2 }}>
          {packingListAccordion}
        </Grid></>}
    </Grid>
    {downloadModal}
  </>
  );
}
