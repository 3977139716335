import {createSlice} from '@reduxjs/toolkit';
import businessApi from '../api_slices/business_api_slice';

const initialState = {
  business: {},
};

const businessSlice = createSlice({
  name: 'businessSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      businessApi.endpoints.getBusiness.matchFulfilled,
      (state, {payload}) => {
        const {data} = payload;
        state.business = data;
      },
    ),
    builder.addMatcher(
      businessApi.endpoints.updateBusiness.matchFulfilled,
      (state, {payload}) => {
        const {data} = payload;
        state.business = data;
      },
    );
  },
});

const {reducer} = businessSlice;

export default reducer;
