import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import {
  IconButton,
  Divider,
  Drawer,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton
} from '@mui/material';

import MenuIcon from '@mui/icons-material/MenuOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftOutlined';

import LogoutComponent from '../../login/components/logout_component';
import WhatsNewComponent from '../../whats_new/whats_new_component';
import Logo from '../../../assets/images/dinespot_black_logo.png';
import ChristmasCap from '../../../assets/images/christmas_cap.png';
import { drawerNavLocations } from './drawer_locations';
import { SIDE_DRAWER_WIDTH, STORAGE_KEYS } from '../../../js/lib/constants';

const openedMixin = (theme) => ({
  width: SIDE_DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const AppDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: SIDE_DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const whatsNewLastUpdatedTimeStamp = 1737712599000;

export default function HomePageDrawer() {
  const location = useLocation();
  const [showLogout, setShowLogout] = useState(false);
  const [showWhatsAppNew, setShowWhatsAppNew] = useState(localStorage.getItem(STORAGE_KEYS.WHATS_NEW_MODAL_UPDATED_AT) ?
    Number(localStorage.getItem(STORAGE_KEYS.WHATS_NEW_MODAL_UPDATED_AT)) < whatsNewLastUpdatedTimeStamp : true);
  const [open, setOpen] = useState(true);
  const [selectedPath, setSelectedPath] = useState(location?.pathname);
  const roles = useSelector((store) => store?.roles.roles);
  const navigate = useNavigate();

  const handleDrawer = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  useEffect(() => {
    setSelectedPath(location?.pathname);
  },
    [location]);

  const isAnySettingsRolesAvailable = () => {
    return roles?.settings?.general?.read ||
      roles?.settings?.billing?.read ||
      roles?.settings?.business?.read ||
      roles?.settings?.leadPortal?.read;
  };

  const firstGroup = drawerNavLocations.filter(item => item.group === 1);
  const secondGroup = drawerNavLocations.filter(item => item.group === 2);

  const isChristmas = () => {
    const today = new Date();
    return (today.getMonth() === 11 && (today.getDate() === 24 || today.getDate() === 25));
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <AppDrawer variant="permanent" open={open} PaperProps={{
          sx: {
            boxShadow: 3,
            borderRadius: 3
          },
        }} >
          <DrawerHeader>
            {open && <>
              <Box sx={{ position: 'relative' }}>
                <img alt='logo' src={Logo} width='130' onClick={handleDrawer} />
                {isChristmas() && (
                  <img
                    alt='christmas cap'
                    src={ChristmasCap}
                    style={{
                      position: 'absolute',
                      top: -17,
                      left: -12,
                      width: '30px', // Adjust the size as needed
                      height: '30px', // Adjust the size as needed
                    }}
                  />
                )}
              </Box>
              <IconButton onClick={handleDrawer} sx={{ color: 'white' }}>
                <ChevronLeftIcon sx={{
                  color: 'black',
                }} />
              </IconButton></>}
            {!open && <IconButton onClick={handleDrawer} sx={{ color: 'white', pr: 2.25 }}>
              <MenuIcon
                sx={{ color: 'black' }}
              />
            </IconButton>}
          </DrawerHeader>
          <Divider sx={{ borderColor: 'grey' }} />
          <List sx={{ flexGrow: 1 }}>
            {firstGroup.map(({ path, name, icon, rolesAvailability }) => (
              <ListItem
                key={name}
              >
                {roles && (roles[rolesAvailability]?.read || (rolesAvailability === 'settings' && isAnySettingsRolesAvailable())) && (
                  <ListItemButton
                    sx={{
                      minHeight: 10,
                      justifyContent: open ? 'initial' : 'center',
                      backgroundColor: selectedPath.includes(path) ? '#008080' : 'transparent',
                      boxShadow: selectedPath.includes(path) ? 3 : 'none', // Adds shadow when selected
                      borderRadius: '10px', // Adds rounded corners when selected
                      '&:hover': {
                        backgroundColor: '#008080',
                        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                          color: 'white',
                        },
                        boxShadow: 3, // Adds shadow
                        borderRadius: '10px', // Adds rounded corners
                      },
                    }}
                    onClick={() => {
                      navigate(path);
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        color: selectedPath.includes(path) ? 'white' : 'black',
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={name}
                      sx={{
                        opacity: open ? 1 : 0,
                        color: selectedPath.includes(path) ? 'white' : 'black',
                      }}
                    />
                  </ListItemButton>
                )}
              </ListItem>
            ))}
          </List>
          <List sx={{ mt: 'auto' }}>
            {secondGroup.map(({ path, name, icon }) => (
              <ListItem
                key={name}
                disablePadding
                sx={{ display: 'block', p: 0.7 }} // Adds margin-bottom for spacing
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    backgroundColor: selectedPath.includes(path) ? '#008080' : 'transparent',
                    boxShadow: selectedPath.includes(path) ? 3 : 'none', // Adds shadow when selected
                    borderRadius: '10px', // Adds rounded corners when selected
                    '&:hover': {
                      backgroundColor: '#008080',
                      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                        color: 'white',
                      },
                      boxShadow: 3, // Adds shadow
                      borderRadius: '10px', // Adds rounded corners
                    },
                  }}
                  onClick={() => {
                    if (path === '/logout') {
                      setShowLogout(true);
                      return;
                    } else if (path === '/whats-new') {
                      setShowWhatsAppNew(true);
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1 : 'auto',
                      color: selectedPath.includes(path) ? 'white' : 'black',
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={name}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: selectedPath.includes(path) ? 'white' : 'black',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </AppDrawer>
      </Box>
      <LogoutComponent displayModal={showLogout} setDisplayModal={setShowLogout} />
      <WhatsNewComponent
        displayModal={showWhatsAppNew}
        setDisplayModal={() => {
          setShowWhatsAppNew(false);
          localStorage.setItem(STORAGE_KEYS.WHATS_NEW_MODAL_UPDATED_AT, Date.now().toString());
        }} />
    </>
  );
}
