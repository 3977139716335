import React, { useEffect } from 'react';
import { Grid2 as Grid, Divider } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';

import TiffinForm from '../components/tiffin_form';
import TiffinGoodsForm from '../components/tiffin_goods_form';
import { tiffinsApi } from '../../../js/slices/api_slices';

import { TuiAppBar, Hooks, Footer, CoreButton } from '../../../core/index';
import { STORAGE_KEYS } from '../../../js/lib/constants';

const { useSnackBarNotification, useSessionStoragePersist } = Hooks;
const { useCreateTiffinMutation } = tiffinsApi;

export default function CreteTiffinView() {
  const navigate = useNavigate();

  let initialData = sessionStorage.getItem(STORAGE_KEYS.TIFFIN);
  if (initialData) {
    try {
      let initialJsonData = JSON.parse(initialData);
      initialData = initialJsonData;
    } catch (err) {
      console.error('Error parsing initialData', err);
    }
  } else {
    initialData = {
      goodsCostPercent: 0,
      items: [],
    }
  }

  const { register, handleSubmit, formState: { errors }, control, watch, setValue, reset } = useForm({
    defaultValues: {
      ...initialData,
      pickUpPriceChange: false
    },
  });
  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const [addNewTiffin, {
    data,
    isSuccess,
    error,
    isError,
    isLoading,
  }] = useCreateTiffinMutation();

  useEffect(() => {
    if (isSuccess && data) {
      successSnackBar({ message: 'Meal plan created successfully' });
      navigate(-1);
    } if (isError && error) {
      errorSnackBar({ message: error?.data?.errorMessage });
    }
    return () => {
      if (isSuccess) {
        sessionStorage.removeItem(STORAGE_KEYS.TIFFIN);
      }
    }
  }, [isSuccess, isError, data, error]);

  useSessionStoragePersist({ value: watch(), sessionStorageKey: STORAGE_KEYS.TIFFIN });

  return (
    <Grid container spacing={1}>
      <Grid size={12}>
        <TuiAppBar title='New Meal Plan' />
      </Grid>
      <Grid
        height='80vh'
        component='form'
        onSubmit={handleSubmit((userInput) => {
          addNewTiffin(userInput);
        })}
        sx={{ mt: 1 }}
        container
        direction="column">
        <Grid size={12} container spacing={2} sx={{ p: 1, height: 'inherit' }}>
          <Grid size={6}>
            <TiffinForm
              action='create'
              setValue={setValue}
              control={control}
              register={register}
              errors={errors}
              watch={watch}
            />
          </Grid>
          <Grid size={6}>
            <TiffinGoodsForm
              reset={reset}
              action='create'
              watch={watch}
              setValue={setValue}
              errors={errors}
              control={control}
              isLoading={isLoading} />
          </Grid>
        </Grid>
        <Footer>
          <Grid container size={12} spacing={2} justifyContent='flex-end' sx={{ p: 1 }}>
            <Grid size='auto'>
              <CoreButton
                fullWidth={false}
                onClickHandler={() => {
                  reset({
                    name: '',
                    description: '',
                    items: [],
                    showInCustomerPortal: false,
                    price: {
                      delivery: {
                        single: '',
                        monthly: '',
                        weekly: '',
                      },
                      pickUp: {
                        single: '',
                        monthly: '',
                        weekly: '',
                      },
                    }
                  });
                  sessionStorage.removeItem(STORAGE_KEYS.TIFFIN)
                }}
                variant="outlined"
                startIcon={<ClearAllOutlinedIcon />}
              >
                Clear
              </CoreButton>
            </Grid>
            <Grid size='auto'>
              <Divider orientation='vertical' />
            </Grid>
            <Grid size='auto'>
              <CoreButton
                fullWidth={false}
                onClickHandler={() => navigate(-1)}
                variant="outlined"
                startIcon={<ArrowBackIosNewOutlinedIcon />}
              >
                Back
              </CoreButton>
            </Grid>
            <Grid size='auto'>
              <CoreButton
                isLoading={isLoading}
                type='submit'
                fullWidth={false}
                variant="contained"
                startIcon={<SaveOutlinedIcon />}
              >
                Save
              </CoreButton>
            </Grid>
          </Grid>
        </Footer>
      </Grid>
    </Grid>
  );
}
