import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { CssBaseline } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

import RoutingIndex from './routes';
import store from './store/store';
import { theme } from './theme';

function ErrorFallback({ error }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
}

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <CssBaseline />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <RoutingIndex />
          <ToastContainer
            position="bottom-left"
            icon={({ type }) => {
              switch (type) {
                case 'info':
                  return <InfoIcon style={{ color: 'blue' }} />;
                case 'error':
                  return <ErrorIcon style={{ color: 'red' }} />;
                case 'success':
                  return <CheckCircleIcon style={{ color: 'green' }} />;
                case 'warning':
                  return <WarningIcon style={{ color: 'orange' }} />;
                default:
                  return null;
              }
            }}
          />
        </ErrorBoundary>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
