import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
    Grid2 as Grid,
    Typography,
    IconButton,
    Tooltip,
    Box,
    CircularProgress
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MessageIcon from '@mui/icons-material/Message';
import WarningIcon from '@mui/icons-material/Warning';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import DinespotLogo from '../../../assets/images/dinespot_black_logo.png';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { TuiSpinner, CoreButton, Hooks, DataNotFound, TuiAppBar, Table, Footer } from '../../../core';
import { stopsApi, deliveryRoutesApi } from '../../../js/slices/api_slices';
import MapViewDrawer from '../components/map_view_drawer';
import { getFormattedTimeFromUnixTimestamp, getFormattedDateFromUnixTimestamp, getFormattedDateTimeFromUnixTimestamp } from '../../../js/lib/utils';
import { getStopStatusChip } from '../routes_utils';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const { useSnackBarNotification, useDebounce, usePageNumberAndSearch } = Hooks;
const { useLazyGetStopsQuery, useLazyGetStopQuery, useGetStopsQuery } = stopsApi;
const { useGetDeliveryRouteQuery } = deliveryRoutesApi

const headCells = [{
    id: 'stop',
    title: 'Stop',
}, {
    id: 'name',
    title: 'Name',
}, {
    id: 'mealPlan',
    title: 'Meal Plan',
}, {
    id: 'address',
    title: 'Address',
}, {
    id: 'status',
    title: 'Status',
}, {
    id: 'deliveryTime',
    title: 'Delivery Time',
}, {
    id: 'image',
    title: 'Image',
}, {
    id: 'businessNote',
    title: 'Business Note',
}, {
    id: 'customerNote',
    title: 'Customer Note',
}];

export default function RouteStopsView() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [fetchLoading, setFetchLoading] = useState(false);
    const [trigger] = useLazyGetStopsQuery({});
    const [showMapDrawer, setShowMapDrawer] = useState(false);
    const [stops, setStops] = useState([]);
    const [processedStops, setProcessedStops] = useState([]);
    const [useGetStopQueryTrigger, { isLoading }] = useLazyGetStopQuery();
    const { errorSnackBar } = useSnackBarNotification();
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [page, setPage, searchText, setSearchText] = usePageNumberAndSearch();
    const debouncedSearchTerm = useDebounce(searchText, 500);

    const { data: routeDetails } = useGetDeliveryRouteQuery({
        id,
    }, { refetchOnMountOrArgChange: true, skip: !id });

    const { data: searchData, isSuccess, isFetching: searchFetching } = useGetStopsQuery({
        page: 1,
        pageSize: 20,
        contains: {
            'customer.firstName': debouncedSearchTerm,
            'customer.lastName': debouncedSearchTerm,
            'address.description': debouncedSearchTerm,
        },
        'routeId': id,
        sortKey: '+position',
    }, { refetchOnMountOrArgChange: true, skip: !debouncedSearchTerm });

    const fetchAllPages = async (page = 1, pageSize = 200) => {
        const response = await trigger({
            'page': page,
            'pageSize': pageSize,
            'routeId': id,
            'sortKey': '+position'
        });
        let data = response?.data?.data;
        const totalCount = data?.totalCount;
        const totalPages = Math.ceil(totalCount / pageSize);

        if (page < totalPages) {
            const nextPageData = await fetchAllPages(page + 1, pageSize);
            data = { ...data, items: [...data.items, ...nextPageData.items] };
        }

        return data;
    };

    function updateStopsInList(stops) {
        if (stops?.length) {
            const processedSubscribers = [];
            for (const stop of stops) {
                const { position, customer, address, status, imageName, noteForBusiness, noteForCustomer, id, events, arrival, unassigned, duration } = stop;
                const { phoneNumber, tiffin, deliveryTimeWindow, firstName, lastName, unit } = customer;
                processedSubscribers.push({
                    position,
                    name: `${firstName || ''} ${lastName || ''}`,
                    mealPlan: `${tiffin?.name} ${tiffin?.quantity > 1 ? '(Qty: ' + tiffin?.quantity + ')' : ''}`,
                    address,
                    unit,
                    status,
                    arrival,
                    duration,
                    events,
                    imageName,
                    noteForBusiness,
                    noteForCustomer,
                    phoneNumber,
                    id,
                    unassigned,
                    deliveryTimeWindow
                });
            }
            setStops(stops);
            setProcessedStops(processedSubscribers);
        }
    }

    const fetchAllStops = async () => {
        setFetchLoading(true);
        let stops = await fetchAllPages();
        updateStopsInList(stops?.items);
        setFetchLoading(false);
    };

    useEffect(() => {
        if (id && !searchText) {
            fetchAllStops();
        }
    }, [id, searchText]);

    useEffect(() => {
        if (isSuccess && searchData?.data?.items) {
            updateStopsInList(searchData?.data?.items);
        }
    }, [searchData, isSuccess]);

    const processedItems = processedStops?.map((item) => {
        const { id, position, name, mealPlan, address, imageName, noteForBusiness, noteForCustomer, status, events, unassigned, unit, duration, deliveryTimeWindow } = item;
        const warningIcon = (
            <Tooltip
                title={<span style={{ fontSize: '0.9rem' }} dangerouslySetInnerHTML={{ __html: "Added to the end of the route because delivery time window constraints are not met.<br />Start route earlier or adjust the delivery time window for this customer." }} />}
                placement='top'
                arrow
            >
                <WarningIcon style={{ color: '#FFA500', verticalAlign: 'middle', marginRight: '4px' }} />
            </Tooltip>
        );
        const deliveryTimeWindowStartExists = deliveryTimeWindow?.start && deliveryTimeWindow?.start !== 0;
        const deliveryTimeWindowEndExists = deliveryTimeWindow?.end && deliveryTimeWindow?.end !== 0;
        const deliveryTimeWindowIcon = (
            <Tooltip
                title={
                    deliveryTimeWindowStartExists && deliveryTimeWindowEndExists
                        ? `Deliver between ${getFormattedTimeFromUnixTimestamp(deliveryTimeWindow?.start)}-${getFormattedTimeFromUnixTimestamp(deliveryTimeWindow?.end)}`
                        : deliveryTimeWindowEndExists
                            ? `Deliver before ${getFormattedTimeFromUnixTimestamp(deliveryTimeWindow?.end)}`
                            : deliveryTimeWindowStartExists
                                ? `Deliver after ${getFormattedTimeFromUnixTimestamp(deliveryTimeWindow?.start)}`
                                : ''
                }
                placement='top'
                arrow>
                <AccessTimeIcon
                    style={{ color: '#FFA500', verticalAlign: 'middle', marginRight: '4px' }}
                />
            </Tooltip>
        );
        const timeToStop = duration + (position * routeDetails?.data?.avgStopTime);
        return {
            stop: position + 1,
            name: unassigned ? <>{name} {warningIcon}</> : deliveryTimeWindow?.start || deliveryTimeWindow?.end ? <>{name} {deliveryTimeWindowIcon}</> : name,
            mealPlan,
            address: `${unit ? `${unit} -` : ''}${address?.description}`,
            status: getStopStatusChip(status),
            deliveryTime: <>
                <Typography variant='body2'>
                    Actual: {events?.finish ? getFormattedTimeFromUnixTimestamp(events?.finish) : '--'}
                </Typography>
                <Typography variant='body2' color='secondary'>
                    Expected: {duration != null ? getFormattedTimeFromUnixTimestamp(moment(routeDetails?.data?.startTime).add(timeToStop, 'seconds')) : '--'}
                </Typography>
            </>,
            imageName: (imageName &&
                <IconButton
                    onClick={async () => {
                        const res = await useGetStopQueryTrigger(id);
                        if (res?.data?.data?.imageUrl) {
                            window.open(res?.data?.data?.imageUrl, '_blank');
                        } else {
                            errorSnackBar({ message: 'Image fetch failed' });
                        }
                    }}
                >
                    <Tooltip title="View Image">
                        <OpenInNewIcon />
                    </Tooltip>
                </IconButton>) || '--',
            noteForBusiness: (noteForBusiness && <Tooltip title={noteForBusiness} placement='top' arrow>
                <MessageIcon />
            </Tooltip>) || '--',
            noteForCustomer: (noteForCustomer &&
                <Tooltip title={noteForCustomer} placement='top' arrow>
                    <MessageIcon />
                </Tooltip>) || '--',
            backgroundColor: unassigned ? '#ffe0e0' : '#ffffff',
            id
        }
    });

    const handleDownload = async () => {
        if (stops?.length) {
            setDownloadLoading(true);
            const doc = new jsPDF();
            let routeName = routeDetails?.data?.name;
            doc.setFontSize(16);
            doc.setTextColor(128, 128, 128);
            let text = `Stop List: ${getFormattedDateFromUnixTimestamp(routeDetails?.data?.startTime, 'DD MMMM (dddd)')}`;
            const x = 105;
            let y = 10;
            doc.text(text, x, y, { align: 'center' });
            let textWidth = doc.getTextWidth(text);
            let underlineY = y + 1;
            doc.setDrawColor(128, 128, 128);
            doc.setLineWidth(0.5);
            doc.line(x - textWidth / 2, underlineY, x + textWidth / 2, underlineY);

            doc.setFontSize(18);
            doc.setFont('helvetica', 'bold');
            y = 20;
            text = `Route name: ${routeName}`;
            doc.text(text, x, y, { align: 'center' });
            textWidth = doc.getTextWidth(text);
            underlineY = y + 1;
            doc.setDrawColor(128, 128, 128);
            doc.setLineWidth(0.5);
            doc.line(x - textWidth / 2, underlineY, x + textWidth / 2, underlineY);

            const tableData = [];
            stops.forEach((stop) => {
                tableData.push([
                    stop?.position + 1,
                    stop?.customer?.firstName + ' ' + stop?.customer?.lastName,
                    `${stop?.customer?.unit ? `${stop?.customer?.unit} -` : ''} ${stop?.address?.description}`,
                    stop?.customer?.phoneNumber?.number,
                    stop?.customer?.deliveryInstructions,
                ]);
            });

            const headers = ['Stop', 'Name', 'Address', 'Contact Number', 'Drop Point'];

            autoTable(doc, {
                head: [headers],
                body: tableData,
                theme: 'grid',
                startY: 30,
                margin: { left: 10, right: 10, bottom: 15 },
            });

            doc.setFontSize(8);
            doc.setFont('helvetica', 'bold');
            for (let i = 1; i <= doc.getNumberOfPages(); i++) {
                doc.setPage(i);
                var img = new Image();
                img.src = DinespotLogo;
                doc.addImage(img, 'PNG', 10, 285, 20, 5);
                doc.text(185, 288, `Page ${i} / ${doc.getNumberOfPages()}`);
                doc.text(105, 288, getFormattedDateTimeFromUnixTimestamp(Date.now(), true, true, 'YYYY/MM/DD'), { align: 'center' });
            }

            doc.save(`route_${routeName}_stop_list_${getFormattedDateTimeFromUnixTimestamp(routeDetails?.data?.startTime, true, true, 'YYYY/MM/DD')}.pdf`);
            setDownloadLoading(false);
        }
    };


    if (isLoading) return <TuiSpinner />;

    const menuItems = <Grid size={12} container spacing={1} sx={{ mt: 0.5 }}>
        {stops?.length > 0 && <>
            <Grid size='auto'>
                <Tooltip title="Download Stop List">
                    {downloadLoading ? <CircularProgress size={20} /> :
                        <IconButton
                            sx={{
                                mt: 0.8,
                                p: 0.6,
                                border: '1px solid', // Add border
                                borderColor: 'grey.500', // Set border color
                                borderRadius: '10%', // Make the border rounded
                            }}
                            onClick={handleDownload}>
                            <FileDownloadOutlinedIcon />
                        </IconButton>}
                </Tooltip>
            </Grid>
            <Grid size='auto'>
                <Tooltip title="View stops in Google Map">
                    <IconButton
                        sx={{
                            mt: 0.8,
                            p: 0.6,
                            border: '1px solid', // Add border
                            borderColor: 'grey.500', // Set border color
                            borderRadius: '10%', // Make the border rounded
                        }}
                        onClick={() => setShowMapDrawer(true)}>
                        <PublicOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        </>}
    </Grid>;

    return (
        <>
            <Grid container spacing={2}>
                <Grid size={12}>
                    <TuiAppBar
                        setSearchText={setSearchText}
                        searchText={searchText?.toString()}
                        searchHelperText='Name/Address'
                        menuItems={menuItems}
                        title={<Box display="flex" alignItems="center">
                            <Typography variant="h6" fontWeight="bold" sx={{ pl: 1 }}>
                                {`${routeDetails?.data?.name || 'Route'} Stops`}
                            </Typography>
                            <IconButton
                                onClick={() => fetchAllStops()}
                                aria-label="refresh"
                                size="medium">
                                <RefreshIcon fontSize="inherit" style={{ color: '#008080' }} />
                            </IconButton>
                        </Box>}
                    />
                </Grid>
                {(fetchLoading || searchFetching) ? <TuiSpinner /> : <> <Grid size={12} sx={{ pb: 15 }}>
                    {processedItems?.length > 0 && <Table headers={headCells} data={processedItems} isLoading={isLoading} selectedRow={() => { }} setSelectedRow={() => { }} />}
                </Grid>
                    <Grid size={12}>
                        {!stops?.length && <DataNotFound title="stops" />}
                    </Grid></>}
            </Grid>
            <Footer>
                <Grid
                    container
                    justifyContent='flex-end'
                    size={12}
                    spacing={2}
                    sx={{ p: 1 }}
                >
                    <CoreButton
                        startIcon={<ArrowBackIosNewOutlinedIcon />}
                        fullWidth={false}
                        variant='outlined'
                        onClick={() => navigate('/routes')}
                    >
                        Back
                    </CoreButton>
                </Grid >
            </Footer>
            <MapViewDrawer stops={stops} item={routeDetails?.data} open={showMapDrawer} handleClose={() => setShowMapDrawer(false)} />
        </>
    );
}
