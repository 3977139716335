import React from 'react';
import { useSelector } from 'react-redux';
import { Grid2 as Grid, Typography } from '@mui/material';
import { capitalizeFirstLetter, getFormattedDateFromUnixTimestamp } from '../../../js/lib/utils';

const InfoRow = ({ label, value }) => (
  <Grid size={12} container spacing={1}>
    <Grid size={6}>
      <Typography>{label}</Typography>
    </Grid>
    <Grid size={6}>
      <Typography>{value}</Typography>
    </Grid>
  </Grid>
);

export default function BillingSettings() {
  const settings = useSelector((store) => store?.settings.settings);

  const plan = settings?.plan || {};
  const customers = plan.customers || {};
  const messages = plan.messages || {};

  return (
    <Grid container sx={{ p: 2, backgroundColor: 'white', borderRadius: '20px', border: '1px solid #E0E0E0' }} spacing={2}>
      <Grid size={12} container>
        <InfoRow label="Plan Name" value={capitalizeFirstLetter(plan.name)} />
        <InfoRow label="Current Subscription Expiration" value={getFormattedDateFromUnixTimestamp(plan.endDate)} />
        <InfoRow label="Customers Limit (Non Cancelled)" value={customers.max} />
        <InfoRow label="Customers Limit (Cancelled)" value={customers.cancelled} />
        <InfoRow label="Messages Limit (WhatsApp)" value={`${messages.whatsApp?.used}/${messages.whatsApp?.total}`} />
        <InfoRow label="Messages Limit (Sms)" value={`${messages.sms?.used}/${messages.sms?.total}`} />
      </Grid>
    </Grid>
  );
}
