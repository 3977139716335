import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid2 as Grid, Checkbox, Typography, IconButton, Menu, MenuItem, ListItemIcon } from '@mui/material';
import DeleteIconOutlined from '@mui/icons-material/DeleteOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { expensesApi } from '../../../js/slices/api_slices';
import { Hooks, Table, ConfirmationModal } from '../../../core/index';
import { getFormattedDateTimeFromUnixTimestamp } from '../../../js/lib/utils';

const headCells = [{
  id: 'checkbox',
  title: 'Select',
},
{
  id: 'category',
  title: 'Category',
},
{
  id: 'description',
  title: 'Description',
}, {
  id: 'amount',
  title: 'Amount',
}, {
  id: 'hstAmount',
  title: 'Hst Amount',
}, {
  id: 'date',
  title: 'Date & Time',
}, {
  id: 'action',
  title: 'Actions',
},];

const { useDeleteExpenseMutation } = expensesApi;

export default function ExpenseListComponent({ items,
  isLoading,
  selectedItems,
  setSelectedItems,
  selectAllChecked,
  selectAllHandler,
  setItems,
  page,
  setPage }) {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const { errorSnackBar, successSnackBar } = Hooks.useSnackBarNotification();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const [deleteExpense, {
    data: deleteExpenseData,
    isSuccess: deleteExpenseIsSuccess,
    error: deleteExpenseError,
    isError: deleteExpenseIsError,
    isLoading: deleteExpenseIsLoading,
  }] = useDeleteExpenseMutation();

  useEffect(() => {
    if (deleteExpenseIsSuccess && deleteExpenseData) {
      successSnackBar({ message: 'Expense deleted successfully' });
      const newItems = items.filter((item) => item.id !== deleteExpenseData?.data?.id);
      if (!newItems.length && Number(page) > 1) {
        setPage(page - 1);
      }
      setItems(newItems);
    } if (deleteExpenseIsError && deleteExpenseError) {
      errorSnackBar({ message: deleteExpenseError?.data?.errorMessage });
    }
    setSelectedItem(null);
  }, [deleteExpenseIsSuccess, deleteExpenseData, deleteExpenseError, deleteExpenseIsError]);

  const menuOptions = (item) => [{
    id: 'delete',
    title: 'Delete',
    icon: <DeleteIconOutlined fontSize="small" />,
    disabled: false,
    action: () => {
      setShowDeleteConfirmationModal(true);
    }
  }];

  const processedItems = items?.map((item) => {
    const { category, description, amount, hstAmount, dateTime, id } = item;
    return {
      checkbox: <Checkbox
        size='small'
        checked={selectedItems?.has(item?.id)}
        onClick={(event) => {
          if (event.target.checked) {
            selectedItems.add(item?.id)
            setSelectedItems(new Set(selectedItems));
          } else {
            selectedItems.delete(item?.id)
            setSelectedItems(new Set(selectedItems));
          }
          event.stopPropagation();
        }} />,
      category: category || '--',
      description: description || '--',
      amount: amount ? `$ ${amount.toFixed(2)}` : '--',
      hstAmount: hstAmount ? `$ ${hstAmount.toFixed(2)}` : '--',
      date: dateTime ? getFormattedDateTimeFromUnixTimestamp(dateTime) : '--',
      action: <div>
        <IconButton
          aria-label={`long-more-${id}`}
          id={`long-button-${id}`}
          aria-controls={open ? `long-menu-${id}` : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event) => {
            setSelectedItem(item);
            handleClick(event);
            event.stopPropagation();
          }}
        >
          <MoreVertIcon />
        </IconButton>
        {selectedItem?.id === item?.id && <Menu
          id={`long-menu-id-${id}`}
          MenuListProps={{
            'aria-labelledby': `long-button-${id}`,
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {menuOptions(item).map(({ id, title, icon, action, disabled }) => [
            <MenuItem
              disabled={disabled}
              key={id}
              onClick={(e) => {
                action();
                handleClose(e);
              }}>
              <ListItemIcon>
                {icon}
              </ListItemIcon>
              {title}
            </MenuItem>,
          ])}
        </Menu>}
      </div>,
      id,
    };
  });

  const deleteConfirmationModal = <ConfirmationModal
    open={showDeleteConfirmationModal}
    handleClose={() => setShowDeleteConfirmationModal(false)}
    title={<Grid container spacing={2}>
      <Grid size={12}>
        <Typography variant='h6'>Are you sure want to delete this expense?</Typography>
      </Grid>
    </Grid>}
    type="Delete"
    actionHandler={() => {
      setShowDeleteConfirmationModal(false);
      deleteExpense({ id: selectedItem?.id });
    }}
  />;


  return (
    <>
      <Grid container>
        <Table
          selectAllHandler={selectAllHandler}
          selectAllChecked={selectAllChecked}
          headers={headCells}
          data={processedItems}
          isLoading={isLoading}
          selectedRow={selectedItem}
          setSelectedRow={(row) => {
            navigate(row.id);
          }} />
      </Grid>
      {deleteConfirmationModal}
    </>
  );
}
