import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Box,
  Grid,
  Divider,
  Typography,
  FormLabel,
  FormControl,
  FormControlLabel,
  Checkbox
} from '@mui/material';

export default function GeneralSettings() {
  const { control, watch } = useFormContext();
  return (
    <Grid container sx={{
      p: 2,
      backgroundColor: 'white',
      borderRadius: '20px',
      border: '1px solid #E0E0E0',
    }}>
      <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
        <Grid item xs={6}>
          <Grid item xs={12} container>
            <Typography id="restaurant-type-change">
              Image Upload
            </Typography>
          </Grid>
          <Grid item xs={12} container>
            <FormLabel id="service-radio-buttons-group-label">Enable driver to upload image on successful delivery</FormLabel>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <FormControl fullWidth size="small" sx={{ mt: 1 }}>
              <Controller
                rules={{ required: 'Invalid Selection' }}
                control={control}
                name="settings.services.imageUpload.enable"
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={watch('settings.services.imageUpload.enable')}
                        disabled={!watch('settings.services.imageUpload.eligible')}
                        sx={{
                          color: 'secondary.contrastText',
                          '&.Mui-checked': {
                            color: 'secondary.contrastText',
                          },
                        }}
                      />
                    }
                    sx={{
                      '.MuiFormControlLabel-label': { ml: 1 },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {watch('settings.services.imageUpload.enable') &&
        <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
          <Grid item xs={6}>
            <Grid item xs={12} container sx={{ ml: 5 }}>
              <Typography>
                Make image upload mandatory for drivers
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <FormControl fullWidth size="small">
                <Controller
                  rules={{ required: 'Invalid Selection' }}
                  control={control}
                  name="settings.services.imageUpload.isRequired"
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          sx={{
                            color: 'secondary.contrastText',
                            '&.Mui-checked': {
                              color: 'secondary.contrastText',
                            },
                          }}
                        />
                      }
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      }
      <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
        <Grid item xs={6}>
          <Grid item xs={12} container>
            <Typography id="restaurant-type-change">
              Show Route Distance
            </Typography>
          </Grid>
          <Grid item xs={12} container>
            <FormLabel id="service-radio-buttons-group-label">Enable driver to see the total route distance</FormLabel>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <FormControl fullWidth size="small" sx={{ mt: 1 }}>
              <Controller
                rules={{ required: 'Invalid Selection' }}
                control={control}
                name="settings.mobileAppSettings.showRouteDistance"
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={watch('settings.mobileAppSettings.showRouteDistance')}
                        sx={{
                          color: 'secondary.contrastText',
                          '&.Mui-checked': {
                            color: 'secondary.contrastText',
                          },
                        }}
                      />
                    }
                    sx={{
                      '.MuiFormControlLabel-label': { ml: 1 },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
        <Grid item xs={6}>
          <Grid item xs={12} container>
            <Typography id="restaurant-type-change">
              Show Route Duration
            </Typography>
          </Grid>
          <Grid item xs={12} container>
            <FormLabel id="service-radio-buttons-group-label">Enable driver to see the total route duration</FormLabel>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <FormControl fullWidth size="small" sx={{ mt: 1 }}>
              <Controller
                rules={{ required: 'Invalid Selection' }}
                control={control}
                name="settings.mobileAppSettings.showRouteDuration"
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={watch('settings.mobileAppSettings.showRouteDuration')}
                        sx={{
                          color: 'secondary.contrastText',
                          '&.Mui-checked': {
                            color: 'secondary.contrastText',
                          },
                        }}
                      />
                    }
                    sx={{
                      '.MuiFormControlLabel-label': { ml: 1 },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  );
}
