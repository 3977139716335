import React, { useEffect } from 'react';
import { Grid2 as Grid, Divider } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';

import EmployeeForm from '../components/employee_form';
import { employeesApi } from '../../../js/slices/api_slices';

import { TuiAppBar, Hooks, CoreButton, Footer } from '../../../core/index';
import { STORAGE_KEYS } from '../../../js/lib/constants';

const { useSnackBarNotification, useSessionStoragePersist } = Hooks;
const { useCreateEmployeeMutation } = employeesApi;

export default function CreateEmployeeView() {
  const navigate = useNavigate();
  let initialData = sessionStorage.getItem(STORAGE_KEYS.EMPLOYEE);
  if (initialData) {
    try {
      let initialJsonData = JSON.parse(initialData);
      initialData = initialJsonData;
    } catch (err) {
      console.error('Error parsing initialData', err);
    }
  }

  const { register, handleSubmit, formState: { errors }, control, getValues, watch, reset } = useForm({
    defaultValues: initialData,
    mode: 'onChange',
  });
  const { errorSnackBar, successSnackBar } = useSnackBarNotification();

  const [addNewEmployee, {
    data: employeeData,
    isSuccess: employeeIsSuccess,
    error: employeeError,
    isError: employeeIsError,
    isLoading: employeeIsLoading,
  }] = useCreateEmployeeMutation();

  useEffect(() => {
    if (employeeIsSuccess && employeeData) {
      successSnackBar({ message: 'Employee created successfully' });
      navigate(-1);
    } if (employeeIsError && employeeError) {
      errorSnackBar({ message: employeeError?.data?.errorMessage });
    }
    return () => {
      if (employeeIsSuccess) {
        sessionStorage.removeItem(STORAGE_KEYS.EMPLOYEE);
      }
    }
  }, [employeeIsSuccess, employeeIsError, employeeData, employeeError]);

  useSessionStoragePersist({ value: watch(), sessionStorageKey: STORAGE_KEYS.EMPLOYEE });

  return (
    <Grid
      component='form'
      onSubmit={handleSubmit(async (userInput) => {
        addNewEmployee(userInput);
      })}
      container
      spacing={1}>
      <Grid size={12}>
        <TuiAppBar title='New Employee' />
      </Grid>
      <Grid
        sx={{ mt: 1 }}
        container
      >
        <EmployeeForm
          action='create'
          getValues={getValues}
          watch={watch}
          control={control}
          register={register}
          errors={errors}
          viewOnly={false}
        />
      </Grid>
      <Footer>
        <Grid
          container
          justifyContent='flex-end'
          size={12}
          spacing={2}
          sx={{ pr: 2 }}
        >
          <Grid size='auto'>
            <CoreButton
              fullWidth={false}
              onClickHandler={() => {
                reset({
                  firstName: '',
                  lastName: '',
                  email: '',
                  phoneNumber: {
                    countryCode: '+1',
                    number: '',
                  },
                  type: null,
                  licenseNumber: '',
                  address: {
                    description: '',
                  },
                  salary: {
                    type: '',
                    value: '',
                  },
                  vehicle: {
                    name: '',
                    plateNumber: '',
                    vinNumber: '',
                    color: '',
                  }
                })
                sessionStorage.removeItem(STORAGE_KEYS.EMPLOYEE)
              }}
              variant="outlined"
              startIcon={<ClearAllOutlinedIcon />}
            >
              Clear
            </CoreButton>
          </Grid>
          <Divider orientation='vertical' flexItem />
          <Grid size='auto'>
            <CoreButton
              startIcon={<ArrowBackIosNewOutlinedIcon />}
              fullWidth={false}
              variant='outlined'
              onClick={() => navigate(-1)}
              disabled={employeeIsLoading}
            >
              Back
            </CoreButton>
          </Grid>
          <Grid size='auto'>
            <CoreButton
              startIcon={<SaveOutlinedIcon />}
              type='submit'
              fullWidth={false}
              variant='contained'
              isLoading={employeeIsLoading}
            >
              Save
            </CoreButton>
          </Grid>
        </Grid>
      </Footer>
    </Grid>
  );
}
