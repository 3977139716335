import React, { useEffect, useState } from 'react';
import { useFieldArray, Controller, useForm } from 'react-hook-form';
import {
  Typography,
  Grid2 as Grid,
  Box,
  Modal,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  FormHelperText,
  Checkbox,
  Tooltip,
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import StarsIcon from '@mui/icons-material/Stars';

import { CoreButton, Hooks, TuiAutoComplete, BaseTextField, ToggleDays, Table } from '../../../core/index';
import { DAYS, UNITS } from '../../../js/lib/constants';

import { tiffinsApi } from '../../../js/slices/api_slices';
const { useSnackBarNotification, useDebounce } = Hooks;
const { useGetTiffinItemsQuery, useCreateTiffinItemMutation, useGetTiffinQuery } = tiffinsApi;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid',
  borderRadius: '5px',
  p: 2,
};

export default function CustomerMealPlanGoodsForm({ setValue, control, item }) {
  const { fields, remove, append, update } = useFieldArray({
    control,
    name: 'tiffin.items',
  });

  const [addNewTiffinItem] = useCreateTiffinItemMutation();
  const [open, setOpen] = useState(false);
  const [customDaysAndQuantityChecked, setCustomDaysAndQuantityChecked] = useState(false);

  const [editIndex, setEditIndex] = useState(null);
  const [createItem, setCreateItem] = useState(false);

  const [inputItemName, setInputItemName] = useState('');
  const debouncedTiffinInput = useDebounce(inputItemName, 250);
  const { successSnackBar } = useSnackBarNotification();

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const { data: tiffinDetails } = useGetTiffinQuery({
    id: item?.tiffin?.id,
  }, { refetchOnMountOrArgChange: true, skip: !item?.tiffin?.id });

  const { data: tiffinItems,
    isSuccess: tiffinItemFetchSuccess,
    isLoading: tiffinItemFetchLoading } = useGetTiffinItemsQuery({
      page: 1,
      pageSize: 20,
      contains: { name: debouncedTiffinInput },
    }, { refetchOnMountOrArgChange: true, skip: debouncedTiffinInput ? false : true });

  useEffect(() => {
    if (tiffinItemFetchSuccess && !tiffinItems?.data?.items?.length) {
      setCreateItem(true);
    }
  }, [tiffinItemFetchSuccess]);

  const deleteItemHandler = (id) => {
    const itemIndex = fields.findIndex((item) => item.id === id);
    if (itemIndex > -1) {
      remove(itemIndex);
    }
  };

  // Initialize useForm for the nested form
  const {
    handleSubmit: handleNestedSubmit,
    control: nestedControl,
    watch: nestedWatch,
    setValue: setNestedValue,
    reset: nestedReset,
    register: nestedRegister,
    formState: { errors: nestedErrors, isDirty: nestedIsDirty },
  } = useForm({
    defaultValues: {
      name: '',
      quantity: '',
      unit: '',
      days: {
        0: 1,
        1: 1,
        2: 1,
        3: 1,
        4: 1,
        5: 1,
        6: 1,
      },
    },
  });

  const resetFields = () => {
    nestedReset({
      name: '',
      quantity: '',
      unit: '',
      days: {
        0: 1,
        1: 1,
        2: 1,
        3: 1,
        4: 1,
        5: 1,
        6: 1,
      },
    });
    setInputItemName('');
    setEditIndex(null);
  }

  const onSubmitNestedForm = (data, e) => {
    if (editIndex !== null) {
      update(editIndex, data);
      successSnackBar({ message: 'Item updated successfully' });
    } else {
      append(data);
      successSnackBar({ message: 'Item added successfully' });
    }
    if (createItem) {
      addNewTiffinItem(data);
    }
    resetFields();
    setValue('itemsChanged', true);
  };

  useEffect(() => {
    if (!customDaysAndQuantityChecked) {
      const quantity = Number(nestedWatch('quantity'));
      setNestedValue('days', {
        0: quantity,
        1: quantity,
        2: quantity,
        3: quantity,
        4: quantity,
        5: quantity,
        6: quantity,
      });
    }
  }, [nestedWatch('quantity')]);

  const isCustomizedItem = (item) => {
    const matchingItems = tiffinDetails?.data?.items?.filter(tiffinItem =>
      tiffinItem.name.toLowerCase() === item.name.toLowerCase() &&
      tiffinItem.unit.toLowerCase() === item.unit.toLowerCase() &&
      Object.keys(tiffinItem.days).every(day => tiffinItem.days[day] === item.days[day])
    );

    if (matchingItems?.length === 0) {
      return true;
    }
    return false;
  };

  const formModal = <Modal
    open={open}
    keepMounted
    aria-labelledby="modal-modal-item-form"
  >
    <Box sx={style}>
      <Typography variant="h6" sx={{ mt: 1, mb: 1, color: 'secondary.contrastText.main' }}>
        {editIndex !== null ? 'Edit' : 'Add'} item
      </Typography>
      <Grid component='form' container size={12} onSubmit={(e) => {
        e.stopPropagation();
        handleNestedSubmit(onSubmitNestedForm)(e);
      }}>
        <Grid container spacing={2} size={12}>
          <Grid size={12} container spacing={1} sx={{ mt: 1 }}>
            <Grid size={5}>
              <Controller
                control={nestedControl}
                name="name"
                render={({ field }) => (
                  <TuiAutoComplete
                    id="name"
                    name="name"
                    freeSolo
                    errors={nestedErrors}
                    onChange={(event, item) => {
                      if (item) {
                        setNestedValue('quantity', item?.quantity);
                        setNestedValue('unit', UNITS[item?.unit]);
                      }
                      field.onChange(item?.name);
                    }}
                    inputValue={inputItemName}
                    onInputChange={(event, newInputValue) => {
                      setInputItemName(newInputValue);
                      field.onChange(newInputValue);
                    }}
                    labelKey='label'
                    helperText="e.g Rice"
                    label="Name"
                    options={tiffinItems?.data?.items.map(({ name, quantity, unit }) => ({
                      name,
                      quantity,
                      unit,
                      label: `${name}-${quantity}-${unit}`,
                    }))}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <BaseTextField
                id='quantity'
                label="Quantity"
                type="number"
                errors={nestedErrors}
                required={!customDaysAndQuantityChecked}
                disabled={customDaysAndQuantityChecked}
                inputProps={{
                  min: 0,
                  style: { textAlign: 'center' },
                  step: '0.5',
                }}
                validate={nestedRegister('quantity')}
              />
            </Grid>
            <Grid size={4}>
              <Controller
                control={nestedControl}
                name="unit"
                render={({ field }) => (
                  <FormControl
                    required={true}
                    fullWidth
                  >
                    <InputLabel
                      sx={{
                        color: nestedErrors?.['unit']?.message ? 'secondary.contrastText.100' : 'default',
                      }}>
                      Unit
                    </InputLabel>
                    <Select
                      {...field}
                      id="unit"
                      error={!!nestedErrors?.['unit']?.message}
                      label="Unit"
                      onChange={(event) => field.onChange(event.target.value)}
                      value={field?.value}
                    >
                      {Object.values(UNITS).map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                    </Select>
                    <Typography fontSize={12} mt={1} ml={2} color='secondary.contrastText.100'>{nestedErrors?.type?.message}</Typography>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          <Grid size={12} container spacing={1} sx={{ mt: 2 }}>
            <Grid container alignItems="baseline" spacing={2}>
              <Grid>
                <Checkbox
                  sx={{
                    color: 'secondary.contrastText',
                    '&.Mui-checked': {
                      color: 'secondary.contrastText',
                    },
                  }}
                  edge="end"
                  onChange={(e) => setCustomDaysAndQuantityChecked(e.target.checked)}
                  checked={customDaysAndQuantityChecked}
                  inputProps={{
                    'aria-labelledby': 'switch-list-label-bluetooth',
                  }}
                />
              </Grid>
              <Grid>
                <Typography id="pick-up-price-type-change">
                  Advanced Days & Quantity
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {customDaysAndQuantityChecked && <Grid size={12}>
            <Controller
              rules={{ required: 'Select valid days and quantity' }}
              control={nestedControl}
              name="days"
              render={({ field }) => (
                <FormControl fullWidth error={!!nestedErrors?.days?.message}>
                  <Grid container>
                    <Grid size={2}>
                      <FormLabel component="legend">Days & Quantity</FormLabel>
                    </Grid>
                    <Grid size={10} container justifyContent='flex-end'>
                      <ToggleDays field={field} existingDays={field?.value} decimalAllowed={true} />
                      <FormHelperText>{nestedErrors?.days?.message}</FormHelperText>
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            />
          </Grid>}
          <Grid size={12}>
            <Divider />
          </Grid>
          <Grid size={12} justifyContent='flex-end' container spacing={1}>
            <Grid size="auto">
              <CoreButton
                variant='outlined'
                fullWidth={false}
                isLoading={tiffinItemFetchLoading}
                onClickHandler={handleClose}
                startIcon={<CloseOutlinedIcon />}
              >Close
              </CoreButton>
            </Grid>
            <Grid size="auto">
              <CoreButton
                variant='contained'
                fullWidth={false}
                isLoading={tiffinItemFetchLoading}
                type="submit"
                disabled={!nestedIsDirty}
                startIcon={<SaveOutlinedIcon />}
              >Save
              </CoreButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </Modal>;

  return (
    <>
      <Grid
        container
        size={12}
        sx={{
          backgroundColor: 'white',
          borderRadius: '20px',
          border: '1px solid #E0E0E0',
          height: '100%',
        }}>
        <Grid
          container
          spacing={1}
          sx={{
            p: 2
          }}>
          <Grid container size={12} sx={{ mt: 1 }} spacing={1}>
            <Grid container size={12}>
              <Grid size={6}>
                <Typography color="secondary.contrastText.main">List of the goods</Typography>
              </Grid>
              <Grid item size={6} justifyContent='flex-end'>
                <Grid size="auto" container justifyContent='flex-end' sx={{
                  height: 30,
                }}>
                  <CoreButton
                    startIcon={<AddOutlinedIcon />}
                    variant='contained'
                    fullWidth={false}
                    onClickHandler={() => {
                      resetFields();
                      handleOpen();
                    }}
                  >Add Item
                  </CoreButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid size={12}>
              <Box
                sx={{
                  'height': '85vh',
                  'width': '100%',
                  '& .actions': {
                    color: 'text.secondary',
                  },
                  '& .textPrimary': {
                    color: 'text.primary',
                  },
                }}
              >
                <Table
                  headers={[
                    { title: 'Name', id: 'name' },
                    { title: 'Unit', id: 'unit' },
                    { title: 'Days & Quantity', id: 'days' },
                    { title: 'Actions', id: 'actions' }
                  ]}
                  data={fields.map((item, index) => ({
                    id: item.id,
                    name: (
                      <>
                        {item.name}
                        {isCustomizedItem(item) && (
                          <Tooltip title={'Customized Item'} placement="top">
                            <StarsIcon sx={{ fontSize: 14, color: 'orange' }} />
                          </Tooltip>
                        )}
                      </>
                    ),
                    unit: item.unit,
                    days: item?.days ? Object.keys(item.days).map((day) => `${DAYS.find(({ value }) => value === parseInt(day)).shortName}:${item.days[day]}`).join(', ') : '',
                    actions: (
                      <>
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={() => {
                            const checkAllDaysSame = Object.values(item?.days).every((val) => val === item?.days[0]);
                            setCustomDaysAndQuantityChecked(!checkAllDaysSame);
                            setInputItemName(item?.name);
                            nestedReset({
                              name: item?.name,
                              quantity: checkAllDaysSame ? item?.days[0] : '',
                              unit: item?.unit,
                              days: item?.days
                            });
                            setEditIndex(index);
                            handleOpen();
                          }}
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => deleteItemHandler(item.id)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </>
                    )
                  }))}
                  setSelectedRow={() => { }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {formModal}
    </>
  );
}