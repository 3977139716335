import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Modal,
  Grid2 as Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Divider,
  IconButton,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@mui/material';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { CoreButton, BaseTextField, Hooks, TimePicker, TuiAutoComplete } from '../../../core';
import { tiffinsApi, googleMapsApi } from '../../../js/slices/api_slices';
import { getFormattedDateFromUnixTimestamp } from '../../../js/lib/utils';
import { APIS, DELIVERY_METHOD, UNITS } from '../../../js/lib/constants';

const style = {
  position: 'absolute',
  top: '20%',
  left: '50%',
  transform: 'translate(-50%)',
  width: '60vw',
  maxHeight: '70vh',
  bgcolor: 'background.paper',
  border: '1px solid',
  borderRadius: '5px',
  p: 2,
  overflowY: 'auto',
};

const { useGetPlacesQuery } = googleMapsApi;
const { useUpdateSubscriberOperationMutation } = tiffinsApi;
const { useDebounce, useSnackBarNotification } = Hooks;

export default function CustomerDeliveryEditForm({ open, handleClose, item, rows, setRows, customer, isLoading, fetchCustomer }) {
  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const [showDeliveryWindow, setShowDeliveryWindow] = useState(false);

  // Address states
  const [addressInputValue, setAddressInputValue] = useState('');
  const debouncedAddressInput = useDebounce(addressInputValue, 500);


  const { data: addressData,
    isLoading: addressLoading } = useGetPlacesQuery({
      input: debouncedAddressInput,
    }, { refetchOnMountOrArgChange: true, skip: addressInputValue ? false : true });

  const [updateTiffinSubscriber, {
    data: updateData,
    isSuccess: updateIsSuccess,
    error: updateError,
    isError: updateIsError,
    isLoading: updateLoading,
  }] = useUpdateSubscriberOperationMutation();

  useEffect(() => {
    if (updateIsSuccess && updateData) {
      successSnackBar({ message: 'Customer updated successfully' });
      const { deliveryChanges } = updateData?.data || {};
      const updatedObject = deliveryChanges?.find((deliveryChange) => deliveryChange.date === item?.id);
      const updatedRows = rows.map((row) => {
        if (row.id === item?.id) {
          return {
            ...row,
            date: getFormattedDateFromUnixTimestamp(updatedObject?.date),
            tiffin: updatedObject?.tiffin,
            quantity: updatedObject?.tiffin?.quantity,
            address: updatedObject.address,
            unit: updatedObject.unit,
            updated: true,
            addons: updatedObject?.addons,
            updateSubscriptionEndDate: updatedObject?.updateSubscriptionEndDate,
          };
        }
        return row;
      });
      setRows(updatedRows);
      fetchCustomer({ id: customer?.id });
      handleClose();
    } if (updateIsError && updateError) {
      errorSnackBar({ message: updateError?.data?.errorMessage });
    }
  }, [updateIsSuccess, updateIsError, updateData, updateError]);

  const { control, handleSubmit, watch, formState: { errors, isDirty }, reset, register, setValue } = useForm({
    defaultValues: {
      ...item,
      date: item?.date ? moment(item.date) : null,
      tiffin: {
        ...item?.tiffin,
        quantity: item?.quantity,
      }
    }
  });

  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "addons"
  });

  useEffect(() => {
    const isDeliveryWindowPresent = watch('deliveryTimeWindow.start') || watch('deliveryTimeWindow.end');
    setShowDeliveryWindow(isDeliveryWindowPresent);
  }, [watch('deliveryTimeWindow.start'), watch('deliveryTimeWindow.end')]);

  useEffect(() => {
    if (item) {
      reset({
        ...item,
        date: item?.date ? moment(item.date) : null,
        tiffin: {
          ...item?.tiffin,
          quantity: item?.quantity,
        }
      });
    }
  }, [item]);

  const addNewAddOn = () => {
    append({
      name: '',
      quantity: 1,
      unit: '',
    });
  };

  const onSubmit = (data) => {
    data = {
      id: customer?.id,
      operation: APIS.CUSTOMER_OPERATIONS.EDIT_DELIVERY,
      data: {
        date: moment(data?.date).valueOf(),
        deliveryTimeWindow: {
          start: data?.deliveryTimeWindow?.start ? moment(data?.deliveryTimeWindow?.start).valueOf() : null,
          end: data?.deliveryTimeWindow?.end ? moment(data?.deliveryTimeWindow?.end).valueOf() : null,
        },
        updateSubscriptionEndDate: data?.updateSubscriptionEndDate,
        address: data?.address,
        tiffin: data?.tiffin,
        quantity: data?.quantity,
        unit: data?.unit,
        deliveryInstructions: data?.deliveryInstructions,
        comment: data?.comment,
        addons: data?.addons,
      },
    }
    updateTiffinSubscriber(data);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Grid container spacing={1}>
          <Grid container size={12}>
            <Typography variant="h6" sx={{ mt: 1, mb: 1, color: 'secondary.contrastText.main' }}>
              Edit Delivery for {getFormattedDateFromUnixTimestamp(item?.date)}
            </Typography>
          </Grid>
          <Grid
            size={12}
            container
            spacing={3}
            sx={{ mt: 1 }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid size={12} container spacing={1}>
              <Grid size={4}>
                <BaseTextField
                  id='tiffin.name'
                  name="tiffin.name"
                  label="Meal Plan Name"
                  disabled={true}
                  errors={errors}
                  required={true}
                  validate={register('tiffin.name')}
                />
              </Grid>
              <Grid size={3}>
                <BaseTextField
                  id='tiffin.quantity'
                  name="tiffin.quantity"
                  label="Quantity"
                  type='number'
                  inputProps={{
                    min: 0,
                    step: 1
                  }}
                  errors={errors}
                  required={false}
                  validate={register('tiffin.quantity')}
                />
              </Grid>
              <Grid size={5}>
                <BaseTextField
                  id='comment'
                  name="comment"
                  label="Packing Instructions"
                  errors={errors}
                  required={false}
                  validate={register('comment')}
                />
              </Grid>
            </Grid>
            {(item?.quantity != customer?.deliveryDays[moment(item?.date).day()] || Number(watch('tiffin.quantity')) !== item?.quantity) && <Grid size={12} container spacing={1}>
              <Controller
                control={control}
                name="updateSubscriptionEndDate"
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value}
                        onChange={(event) => {
                          onChange(event.target.checked);
                        }}
                        sx={{
                          color: 'secondary.contrastText',
                          '&.Mui-checked': {
                            color: 'secondary.contrastText',
                          },
                        }}
                      />
                    }
                    label="Update Subscription End Date"
                  />
                )}
              />
            </Grid>}
            <Grid container spacing={1} size={12}>
              <Grid size={1}>
                <BaseTextField
                  id='unit'
                  name="Unit"
                  label="Unit"
                  errors={errors}
                  required={false}
                  validate={register('unit')}
                />
              </Grid>
              <Grid size={6}>
                <Controller
                  control={control}
                  name="address"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TuiAutoComplete
                        id="address"
                        name="address"
                        loading={addressLoading}
                        freeSolo
                        errors={errors}
                        onChange={(event, item) => {
                          onChange(item);
                        }}
                        onInputChange={(event, newInputValue) => {
                          setAddressInputValue(newInputValue);
                        }}
                        required={customer?.shipping === DELIVERY_METHOD.HOME_DELIVERY}
                        value={(value?.description || value?.description === '') ? value : null}
                        helperText={'Please do not enter unit number in the address field'}
                        label='Address'
                        labelKey='description'
                        options={addressData?.data?.items}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid size={5}>
                <BaseTextField
                  id='deliveryInstructions'
                  name="Delivery Instructions"
                  label="Delivery Instructions"
                  errors={errors}
                  required={false}
                  validate={register('deliveryInstructions')}
                />
              </Grid>
            </Grid>
            <Grid size={12} container spacing={1}>
              <Grid size={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showDeliveryWindow}
                      sx={{
                        color: 'secondary.contrastText',
                        '&.Mui-checked': {
                          color: 'secondary.contrastText',
                        },
                      }}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setShowDeliveryWindow(isChecked);
                        if (!isChecked) {
                          setValue('deliveryTimeWindow.start', null, { shouldDirty: true });
                          setValue('deliveryTimeWindow.end', null, { shouldDirty: true });
                        }
                      }}
                    />
                  }
                  label='Add Delivery Time Window'
                />
              </Grid>
              {showDeliveryWindow && <Grid size={4}>
                <Controller
                  rules={{
                    validate: (value) => {
                      if (watch('deliveryTimeWindow.end') && moment(value).isAfter(watch('deliveryTimeWindow.end'))) {
                        return 'Start time must be before end time';
                      }
                      return true;
                    }
                  }}
                  control={control}
                  name='deliveryTimeWindow.start'
                  render={({ field: { onChange, value } }) => (
                    <TimePicker
                      name='deliveryTimeWindow.start'
                      id='deliveryTimeWindow.start'
                      maxTime={watch('deliveryTimeWindow.end') ? moment(watch('deliveryTimeWindow.end')) : null}
                      required={false}
                      control={control}
                      onChange={onChange}
                      errors={errors}
                      value={value ? moment(value) : null}
                      label='Delivery Window Start'
                    />
                  )}
                />
              </Grid>}
              {showDeliveryWindow && <Grid size={4}>
                <Controller
                  rules={{
                    validate: (value) => {
                      if (watch('deliveryTimeWindow.start') && moment(value).isBefore(watch('deliveryTimeWindow.start'))) {
                        return 'End time must be after start time';
                      }
                      return true;
                    }
                  }}
                  control={control}
                  name='deliveryTimeWindow.end'
                  render={({ field: { onChange, value } }) => (
                    <TimePicker
                      name='deliveryTimeWindow.end'
                      id='deliveryTimeWindow.end'
                      required={false}
                      control={control}
                      onChange={onChange}
                      minTime={watch('deliveryTimeWindow.start') ? moment(watch('deliveryTimeWindow.start')) : null}
                      errors={errors}
                      value={value ? moment(value) : null}
                      label='Delivery Window End'
                    />
                  )}
                />
              </Grid>}
            </Grid>
            <Grid size={12}>
              <Grid size={12} container>
                <Grid size={6} container justifyContent='flex-start'>
                  <Typography variant="subtitle1" color="textSecondary">
                    Add-Ons
                  </Typography>
                </Grid>
                <Grid size={6} container justifyContent='flex-end'>
                  <IconButton onClick={addNewAddOn}>
                    <LibraryAddIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
            <Grid size={12} container spacing={0}>
              {fields.map((item, index) => {
                return (
                  <Grid key={item.id} size={12} container spacing={1}>
                    <Grid size={4}>
                      <BaseTextField
                        id={`addons.${index}.name`}
                        name={`addons.${index}.name`}
                        label="Name"
                        errors={errors}
                        required={true}
                        validate={register(`addons.${index}.name`)}
                      />
                    </Grid>
                    <Grid size={3}>
                      <BaseTextField
                        id={`addons.${index}.quantity`}
                        name={`addons.${index}.quantity`}
                        label="Quantity"
                        type="number"
                        errors={errors}
                        required={true}
                        inputProps={{
                          min: 0,
                          style: { textAlign: 'center' },
                          step: '0.5',
                        }}
                        validate={register(`addons.${index}.quantity`)}
                      />
                    </Grid>
                    <Grid size={4}>
                      <Controller
                        control={control}
                        name={`addons.${index}.unit`}
                        render={({ field }) => (
                          <FormControl
                            required={true}
                            fullWidth
                          >
                            <InputLabel
                              sx={{
                                color: errors?.[`addons.${index}.unit`]?.message ? 'secondary.contrastText.100' : 'default',
                              }}>
                              Unit
                            </InputLabel>
                            <Select
                              {...field}
                              id={`addons.${index}.unit`}
                              error={!!errors?.[`addons.${index}.unit`]?.message}
                              label="Unit"
                              onChange={(event) => field.onChange(event.target.value)}
                              value={field?.value}
                            >
                              {Object.values(UNITS).map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid size={1}>
                      <IconButton onClick={() => remove(index)}>
                        <DeleteOutlineOutlined />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid size={12} justifyContent='flex-end' container spacing={1} sx={{ mt: 2 }}>
              <Grid size="auto">
                <CoreButton
                  variant='contained'
                  fullWidth={false}
                  isLoading={isLoading}
                  onClickHandler={handleClose}
                >Close
                </CoreButton>
              </Grid>
              <Grid size="auto">
                <CoreButton
                  variant='contained'
                  fullWidth={false}
                  isLoading={updateLoading}
                  disabled={!isDirty}
                  type="submit"
                >Update
                </CoreButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal >
  );
}