import React from 'react';
import {
    Drawer,
    Grid,
    Typography,
    Box,
    Tooltip,
} from '@mui/material';

import StarsIcon from '@mui/icons-material/Stars';

import { Table } from '../../../core';
import { DAYS } from '../../../js/lib/constants';
import { tiffinsApi } from '../../../js/slices/api_slices';

const { useGetTiffinQuery } = tiffinsApi;

const headCells = [{
    id: 'name',
    title: 'Name',
}, {
    id: 'unit',
    title: 'Unit',
}, {
    id: 'days',
    title: 'Days & Quantity',
}];

export default function TiffinGoodsDrawer({ item, open, handleClose }) {

    const { data: tiffinDetails } = useGetTiffinQuery({
        id: item?.tiffin?.id,
    }, { refetchOnMountOrArgChange: true, skip: !item?.tiffin?.id });

    const isCustomizedItem = (item) => {
        const matchingItems = tiffinDetails?.data?.items?.filter(tiffinItem =>
            tiffinItem.name.toLowerCase() === item.name.toLowerCase() &&
            tiffinItem.unit.toLowerCase() === item.unit.toLowerCase() &&
            Object.keys(tiffinItem.days).every(day => tiffinItem.days[day] === item.days[day])
        );

        if (matchingItems?.length === 0) {
            return true;
        }
        return false;
    };

    const processedItems = item?.tiffin?.items?.map((item) => {
        const { id, name, unit, days } = item;
        return {
            name: (
                <>
                    {name}
                    {isCustomizedItem(item) && (
                        <Tooltip title={'Customized Item'} placement="top">
                            <StarsIcon sx={{ fontSize: 14, color: 'orange' }} />
                        </Tooltip>
                    )}
                </>
            ),
            unit,
            days: Object.keys(days).map((day) => {
                return `${DAYS[day].shortName}: ${days[day]}`;
            }).join(', '),
            id,
        };
    });

    return (
        <Drawer
            anchor="right"
            PaperProps={{
                sx: { width: '40vw' },
            }}
            open={open}
            onClose={handleClose}>
            <Grid
                sx={{
                    backgroundColor: 'white',
                    borderRadius: '20px',
                    height: '100%',
                }}>
                <Grid item xs={12} container spacing={1}
                    sx={{
                        p: 2,
                        height: 'inherit',
                    }}>
                    <Grid item xs={12} container height='0%' spacing={1}>
                        <Grid item xs={12} container>
                            <Grid item xs={6}>
                                <Typography fontWeight="bold" variant="h6">Items</Typography>
                                <Typography variant="body">{`${item?.customer?.firstName} / ${item?.tiffin?.name}`}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                display="flex" flexDirection="column" alignItems="stretch" padding={1}
                                sx={{
                                    'height': '90vh',
                                    'width': '100%',
                                    '& .actions': {
                                        color: 'text.secondary',
                                    },
                                    '& .textPrimary': {
                                        color: 'text.primary',
                                    },
                                }}
                            >
                                <Table headers={headCells} data={processedItems} setSelectedRow={() => { }} />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Drawer>
    );
}