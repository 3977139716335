import React, { useMemo } from 'react';
import { Grid2 as Grid, Typography, Drawer, IconButton, Tooltip } from '@mui/material';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import moment from 'moment-timezone';
import { Table, TuiSpinner } from '../../../core/index';
import { getRouteStatusChip } from '../routes_utils';
import { getFormattedDateTimeFromUnixTimestamp } from '../../../js/lib/utils';
import RouteHistoryStopsDrawer from './route_history_stops_drawer';
import { historyApi } from '../../../js/slices/api_slices';

const headCells = [{
  id: 'startTime',
  title: 'Start Time',
}, {
  id: 'status',
  title: 'Status',
}, {
  id: 'endTime',
  title: 'End Time',
}, {
  id: 'mealCount',
  title: 'Meals',
}, {
  id: 'stopCount',
  title: 'Stops',
}, {
  id: 'distance',
  title: 'Distance',
}, {
  id: 'duration',
  title: 'Duration',
},
{
  id: 'driver',
  title: 'Driver',
}, {
  id: 'cost',
  title: 'Cost',
},];

const { useGetHistoryQuery } = historyApi;

export default function RouteHistoryDrawer({ open, handleClose, item }) {
  const [selectedItem, setSelectedItem] = React.useState(null);
  let { data, isLoading } = useGetHistoryQuery({
    page: 1,
    pageSize: 90,
    sortKey: '-date',
    routeId: item?.id,
  }, { refetchOnMountOrArgChange: true, skip: !(item?.id && open) });

  const sortedStops = useMemo(() => {
    return selectedItem?.routes?.[0]?.stops?.slice().sort((a, b) => a.position - b.position);
  }, [selectedItem]);

  const processedItems = data?.data?.items?.map((item) => {
    const { routes } = item;
    const { stopCount, mealCount, driver, status, startTime, events, id, distance, duration, cost } = routes?.[0] || {};
    return {
      startTime: <>
        <Typography variant='body2'>
          Actual: {events?.start ? getFormattedDateTimeFromUnixTimestamp(events?.start) : '--'}
        </Typography>
        <Typography variant='body2' color='secondary'>
          Expected: {getFormattedDateTimeFromUnixTimestamp(startTime)}
        </Typography>
      </>,
      status: getRouteStatusChip(status),
      endTime: events?.finish ? getFormattedDateTimeFromUnixTimestamp(events?.finish) : '--',
      mealCount: mealCount || '--',
      stopCount: stopCount || '--',
      distance: `${distance != null && distance > 0 ? `${(distance / 1000).toFixed(2)} km` : '--'}`,
      duration: <>
        <Typography variant='body2'>
          Actual: {events?.start && events?.finish ? `${moment.duration(moment(events.finish).diff(moment(events.start))).hours()} hr ${moment.duration(moment(events.finish).diff(moment(events.start))).minutes()} min` : '--'}
        </Typography>
        <Typography variant='body2' color='secondary'>
          Expected: {duration != null && duration > 0 ? `${moment.duration(duration, 'seconds').hours()} hr ${moment.duration(duration, 'seconds').minutes()} min` : '--'}
        </Typography>
      </>,
      driver: `${driver?.firstName} ${driver?.lastName}`,
      cost: (
        <Tooltip title={cost?.value ? `$${cost?.value.toFixed(2)} ${cost?.type}` : ''}>
          <span>
            {cost?.total ? `$${cost?.total.toFixed(2)}` : '--'}
          </span>
        </Tooltip>
      ),
      id,
      secondaryId: startTime,
    };
  });

  return (
    <>
      <Drawer
        anchor="right"
        PaperProps={{
          sx: { width: '80%' },
        }}
        open={open}
        onClose={handleClose}>
        <Grid container sx={{ p: 2 }} spacing={1}>
          <Grid container size={12}>
            <Grid size='auto'>
              <IconButton
                onClick={handleClose}
                sx={{ p: 0, pr: 1, pt: 0.5 }}
              >
                <ArrowBackOutlined />
              </IconButton>
            </Grid>
            <Grid size='auto'>
              <Typography variant="h6" fontWeight='bold'>{`${item?.name} History`}</Typography>
            </Grid>
          </Grid>
          {isLoading && <TuiSpinner />}
          {!isLoading && <Grid size={12}>
            <Table
              headers={headCells}
              data={processedItems}
              isLoading={isLoading}
              setSelectedRow={(row) => {
                const selectedRouteFromResult = data?.data?.items.find((item) => item?.routes?.find((route) => route?.id === row?.id && route?.startTime === row?.secondaryId));
                setSelectedItem(selectedRouteFromResult);
              }} />
          </Grid>}
        </Grid>
      </Drawer>
      {selectedItem !== null &&
        <RouteHistoryStopsDrawer
          stops={sortedStops}
          selectedItem={selectedItem}
          open={selectedItem != null}
          handleClose={() => setSelectedItem(null)}
        />}
    </>
  );
}
