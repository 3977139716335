import React from 'react';
import { toast } from 'react-toastify';

export default function useSnackBarNotification() {

  const getDefaultOptions = (type, toastId = Date.now(), autoClose = 3000, action, moreOptions = {}) => {
    return {
      type,
      toastId,
      autoClose,
      ...moreOptions,
    };
  };

  const successSnackBar = ({ message, toastId, autoClose, action, moreOptions }) => {
    toast.success(message, getDefaultOptions('success', toastId, autoClose, action, moreOptions));
  };

  const errorSnackBar = ({ message = 'Internal Server Error', toastId, autoClose, action, moreOptions }) => {
    toast.error(message, getDefaultOptions('error', toastId, autoClose, action, moreOptions));
  };

  const warnSnackbar = ({ message, toastId, autoClose, action, moreOptions }) => {
    toast.warn(message, getDefaultOptions('warning', toastId, autoClose, action, moreOptions));
  };

  const defaultSnackbar = ({ message, toastId, autoClose, action, moreOptions }) => {
    toast(message, getDefaultOptions('default', toastId, autoClose, action, moreOptions));
  };

  return {
    successSnackBar,
    errorSnackBar,
    warnSnackbar,
    defaultSnackbar,
  };
}
