import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Grid,
  Divider,
  Typography,
  FormLabel,
  FormControl,
  Chip,
  MenuItem,
  Select,
  InputLabel,
  IconButton,
} from '@mui/material';
import DeleteIconOutlined from '@mui/icons-material/DeleteOutlined';
import { TIMEZONES } from '../../../js/lib/constants';
import { TuiAutoComplete, ConfirmationModal, Hooks, BaseTextField, TuiSpinner, CoreButton } from '../../../core/index';
import { logout } from '../../../js/slices/reducer_slices/user_reducer_slice';
import { googleMapsApi, businessApi } from '../../../js/slices/api_slices/index';
import DataImportWizard from './data_import_wizard';

const { useGetPlacesQuery } = googleMapsApi;
const { useDeleteBusinessMutation } = businessApi;

export default function BusinessSettings({ business }) {
  const [openImportWizard, setOpenImportWizard] = useState(false);
  const [file, setFile] = useState(null);
  const { control, formState: { errors }, register, watch } = useFormContext();
  const [addressInputValue, setAddressInputValue] = useState(watch('settings.address.description') || '');
  const debouncedAddressInput = Hooks.useDebounce(addressInputValue, 500);
  const navigate = useNavigate();
  const { errorSnackBar, successSnackBar } = Hooks.useSnackBarNotification();
  const dispatch = useDispatch();

  const [deleteBusiness, {
    data: deleteBusinessData,
    isSuccess: deleteBusinessIsSuccess,
    error: deleteBusinessError,
    isError: deleteBusinessIsError,
    isLoading: deleteBusinessIsLoading,
  }] = useDeleteBusinessMutation();

  const { data: addressData,
    isLoading: addressLoading } = useGetPlacesQuery({
      input: debouncedAddressInput,
    }, { refetchOnMountOrArgChange: true, skip: addressInputValue ? false : true });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    if (deleteBusinessIsSuccess && deleteBusinessData) {
      setShowConfirmationModal(false);
      successSnackBar({ message: 'Business deleted successfully' });
      dispatch(logout());
      navigate('/login', { replace: true });
      // redirect to login
    } if (deleteBusinessIsError && deleteBusinessError) {
      errorSnackBar({ message: deleteBusinessError?.data?.errorMessage });
    }
  }, [deleteBusinessData, deleteBusinessIsSuccess, deleteBusinessIsError, deleteBusinessError]);

  const deleteConfirmationModal = <ConfirmationModal
    open={showConfirmationModal}
    handleClose={() => setShowConfirmationModal(false)}
    title={'Delete Account Permanently'}
    type="Delete"
    actionText="Are you sure want to delete account and related data permanently?. You will be automatically logged out and need to create an account and data from fresh"
    actionHandler={() => {
      deleteBusiness({ id: business?.id });
      setShowConfirmationModal(false);
    }}
  />;

  if (deleteBusinessIsLoading) {
    return <TuiSpinner />;
  }

  return (<Grid container sx={{
    p: 2,
    backgroundColor: 'white',
    borderRadius: '20px',
    border: '1px solid #E0E0E0',
  }}>
    <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
      <Grid item xs={6}>
        <Grid item xs={12} container>
          <Typography id="restaurant-name-change">
            Name
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6} container>
        <Grid item xs={10}>
          <BaseTextField
            id='business.name'
            name="business.name"
            label="Name"
            validate={register('business.name', {
              required: 'Name must be valid',
            })}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
      <Grid item xs={6}>
        <Grid item xs={12} container>
          <Typography id="restaurant-email-change">
            Email
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6} container>
        <Grid item xs={10}>
          <BaseTextField
            id='business.email'
            name="business.email"
            label="Email"
            validate={register('business.email', {
              required: 'Email must be valid',
            })}
            disabled={true}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
      <Grid item xs={6}>
        <Grid item xs={12} container>
          <Typography id="restaurant-phone-number-change">
            Phone Number
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6} container>
        <Grid item xs={4}>
          <BaseTextField
            id='business.phoneNumber'
            name="business.phoneNumber"
            label="Phone Number"
            errors={errors}
            startAdornment='+1'
            validate={register('business.phoneNumber.number', {
              required: 'Phone number is required',
              maxLength: {
                value: 10,
                message: 'Length must be 10',
              },
            },
            )}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
      <Grid item xs={6}>
        <Grid item xs={12} container>
          <Typography id="restaurant-address-change">
            Address
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <Grid item xs={10}>
            <Controller
              control={control}
              rules={{ required: 'Address must be valid' }}
              onChange={([, data]) => data}
              name="settings.address"
              render={({ field: { onChange, value } }) => (
                <TuiAutoComplete
                  id="settings.address"
                  name="settings.address"
                  loading={addressLoading}
                  freeSolo
                  errors={errors}
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  inputValue={addressInputValue}
                  onInputChange={(event, newInputValue) => {
                    setAddressInputValue(newInputValue);
                  }}
                  label="Restaurant Address"
                  labelKey='description'
                  options={addressData?.data?.items}
                />
              )}
            />
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
    <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
      <Grid item xs={6}>
        <Grid item xs={12} container>
          <Typography id="hst-number-change">
            HST
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6} container>
        <Grid item xs={4}>
          <BaseTextField
            disabled={true}
            id='settings.hstPercent'
            name="settings.hstPercent"
            label="HST Percent"
            errors={errors}
            type='number'
            helperText='HST will be updated once address is updated'
            validate={register('settings.hstPercent', {
              required: 'HST is required',
            },
            )}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
      <Grid item xs={6}>
        <Grid item xs={12} container>
          <Typography id="restaurant-timezone-change">
            Timezone
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <Grid item xs={10}>
            <Controller
              control={control}
              name="settings.timezone"
              render={({ field }) => {
                return (
                  <FormControl required={true} fullWidth>
                    <InputLabel
                      id="settings.timezone"
                      sx={{
                        color: errors?.['settings.timezone']?.message ? 'secondary.contrastText.100' : 'default',
                      }}>
                      Timezone
                    </InputLabel>
                    <Select
                      labelId="settings.timezone"
                      id="settings.timezone"
                      error={!!errors?.['settings.timezone']?.message}
                      label="Timezone"
                      onChange={(event) => field.onChange(event.target.value)}
                      value={field?.value} >
                      {Object.entries(TIMEZONES).map(([key, value]) => <MenuItem key={key} value={key}>{key}</MenuItem>)}
                    </Select>
                  </FormControl>
                );
              }}
            />
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
    <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
      <Grid item xs={6}>
        <Grid item xs={12} container>
          <Typography id="restaurant-import-data">
            Import
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <Grid item xs={10}>
            <CoreButton
              fullWidth={false}
              variant="contained"
              onClick={() => setOpenImportWizard(true)}
            >
              Import Data
            </CoreButton>
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
    <Grid item xs={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
      <Grid item xs={6}>
        <Grid item xs={12} container>
          <Typography id="restaurant-logo-change">
            Delete Account
          </Typography>
        </Grid>
        <Grid item xs={12} container>
          <FormLabel id="service-radio-buttons-group-label">Permanently delete an account</FormLabel>
        </Grid>
      </Grid>
      <Grid item xs={6} container spacing={2}>
        <Grid item xs={10}>
          <Alert
            severity="error"
            action={
              <Controller
                control={control}
                name="deleteAccount"
                defaultValue={false}
                render={({ field }) => (
                  <IconButton onClick={() => setShowConfirmationModal(true)}>
                    <DeleteIconOutlined />
                  </IconButton>
                )}
              />
            }
          >
            This action will delete all the data related to the account. This action is irreversible.
          </Alert>
        </Grid>
      </Grid>
    </Grid>
    {deleteConfirmationModal}
    <DataImportWizard open={openImportWizard} handleClose={() => setOpenImportWizard(false)} onChange={setFile} />
  </Grid>
  );
}
