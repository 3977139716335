import React, { useEffect, useState } from 'react';
import { Grid2 as Grid, Typography, IconButton, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { TuiAppBar, Hooks, BasePagination, DataNotFound, TuiSpinner, CoreButton, ConfirmationModal } from '../../../core';
import { expensesApi } from '../../../js/slices/api_slices';
import ListComponent from '../components/expense_list_component';
import ExpenseFilterModal from '../components/expense_filter_modal';
import { STORAGE_KEYS } from '../../../js/lib/constants';

const { useGetExpensesQuery, useLazyGetExpensesQuery, useBulkOperationMutation } = expensesApi;
const { useResponsivePageSize, usePageNumberAndSearch, useDebounce } = Hooks;

export default function ExpensesView() {
    const [pageSize, setPageSize] = useResponsivePageSize();
    const navigate = useNavigate();
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [filterCount, setFilterCount] = useState(0);
    const [items, setItems] = useState([]);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    let storageFilter = sessionStorage.getItem(STORAGE_KEYS.EXPENSE_FILTER);
    if (storageFilter) {
        storageFilter = JSON.parse(storageFilter);
    } else {
        sessionStorage.setItem(STORAGE_KEYS.EXPENSE_FILTER, JSON.stringify({}));
    }
    const [filterObject, setFilterObject] = useState({
        startDate: storageFilter?.startDate || '',
        endDate: storageFilter?.endDate || '',
    });

    const [page, setPage, searchText, setSearchText] = usePageNumberAndSearch();

    const debouncedSearchTerm = useDebounce(searchText, 500);
    const [trigger] = useLazyGetExpensesQuery({});

    const expenseFetchQuery = {
        page,
        pageSize,
        contains: { 'name': debouncedSearchTerm },
        sortKey: '-dateTime',
        startTimeFrom: filterObject?.startDate,
        startTimeEnd: filterObject?.endDate,
    };

    const { data, isLoading, isSuccess, refetch } = useGetExpensesQuery(expenseFetchQuery, { refetchOnMountOrArgChange: true });

    const [bulkOperation, {
        isLoading: bulkLoading,
        isSuccess: bulkSuccess,
        isError: bulkError,
    }] = useBulkOperationMutation();

    useEffect(() => {
        if (data && isSuccess) {
            setItems(data?.data?.items);
        }
    }, [data, isSuccess]);

    useEffect(() => {
        if (bulkSuccess) {
            refetch();
        }
    }, [bulkSuccess]);

    const fetchAllPages = async (page = 1) => {
        const copiedQuery = { ...expenseFetchQuery };
        copiedQuery.page = page;
        const response = await trigger({ ...copiedQuery, pageSize: 200 });

        let data = response?.data?.data;
        const totalCount = data.totalCount;
        const totalPages = Math.ceil(totalCount / pageSize);
        if (page < totalPages) {
            const nextPageData = await fetchAllPages(copiedQuery.page + 1);
            data = { ...data, items: [...data.items, ...nextPageData.items] };
        }

        return data;
    };

    const selectAllOtherExpenses = async () => {
        setShowLoading(true);
        const allExpenses = await fetchAllPages();
        const allExpensesIds = allExpenses?.items?.map((item) => item?.id);
        setSelectedItems(new Set(allExpensesIds));
        setItems(allExpenses?.items);
        setShowLoading(false);
        return Promise.resolve();
    };

    useEffect(() => {
        if (selectedItems.size && items?.length && (selectedItems?.size === items?.length || selectedItems?.size === data?.data?.totalCount)) {
            setSelectAllChecked(true);
        } else if (selectedItems.size && items?.length && selectedItems?.size !== items?.length) {
            setSelectAllChecked(false);
        }
    }, [selectedItems])

    const selectAllHandler = (e) => {
        if (e.target.checked) {
            setSelectedItems(new Set(items?.map((item) => item?.id)));
            setSelectAllChecked(true);
        } else {
            setSelectAllChecked(false);
            setSelectedItems(new Set());
        }
    };

    const filterModal =
        <ExpenseFilterModal
            open={showFilterModal}
            filterObject={filterObject}
            handleClose={() => setShowFilterModal(false)}
            setFilterCount={setFilterCount}
            setFilterObject={setFilterObject}
            setPage={setPage}
        />;

    const selectedItemsMenu = (
        <Grid container sx={{ mt: 1 }} justifyContent="flex-end" direction="row" spacing={1}>
            <Grid>
                <CoreButton
                    variant="contained"
                    onClickHandler={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowDeleteConfirmationModal(true);
                    }}
                    startIcon={<DeleteForeverOutlinedIcon />}
                >
                    Delete
                </CoreButton>
            </Grid>
        </Grid>
    );

    const deleteConfirmationModal = <ConfirmationModal
        open={showDeleteConfirmationModal}
        handleClose={() => setShowDeleteConfirmationModal(false)}
        title='Permanent Delete'
        actionText={`Are you sure want to delete ${selectedItems?.size} expenses?`}
        type="Delete"
        actionHandler={async () => {
            setShowLoading(true);
            bulkOperation({ operation: 'delete', ids: [...selectedItems] });
            setSelectedItems(new Set());
            setSelectAllChecked(false);
            setShowLoading(false);
            setShowDeleteConfirmationModal(false);
        }}
    />;


    if (isLoading || bulkLoading) {
        return <TuiSpinner />
    }

    return (
        <>
            <Grid container sx={{ height: data?.data?.items?.length <= 0 ? '100%' : null }} spacing={1}>
                <Grid size={12}>
                    <TuiAppBar
                        buttonClickHandler={() => {
                            navigate('new', { state: location.state });
                        }}
                        setSearchText={setSearchText}
                        searchText={searchText}
                        selectedItems={[...selectedItems]}
                        selectedItemsMenu={selectedItemsMenu}
                        searchHelperText='Name'
                        title={<Box display="flex" alignItems="center">
                            <Typography variant="h6" fontWeight="bold" sx={{ pl: 1 }}>
                                Expenses
                            </Typography>
                            <IconButton
                                onClick={() => refetch()}
                                aria-label="refresh"
                                size="medium">
                                <RefreshIcon fontSize="inherit" style={{ color: '#008080' }} />
                            </IconButton>
                        </Box>}
                        filter={{ filterModal, showFilterModal, setShowFilterModal, filterCount }}
                    />
                </Grid>
                <Grid size={12}>
                    {!items?.length && <DataNotFound title="expenses" />}
                </Grid>
                <Grid size="auto" sx={{ ml: 1 }}>
                    {selectedItems?.size > 0 &&
                        data?.data?.totalCount / pageSize > 1 &&
                        selectedItems?.size !== data?.data?.totalCount &&
                        <CoreButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            disableRipple
                            sx={{
                                color: 'secondary.contrastText',
                                textDecoration: 'underline',
                            }}
                            isLoading={showLoading}
                            onClickHandler={() => {
                                // fetch subscriber recursively and push ids to selectedItems
                                selectAllOtherExpenses()
                            }}
                        >
                            {`Select all ${data?.data?.totalCount} expenses`}
                        </CoreButton>}
                </Grid>
                <Grid size={12} sx={{ pb: 15 }}>
                    {items?.length > 0 && <ListComponent
                        isLoading={isLoading}
                        items={items}
                        setItems={setItems}
                        setPage={setPage}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        selectAllHandler={selectAllHandler}
                        selectAllChecked={selectAllChecked}
                        page={page}
                    />}
                </Grid>
            </Grid>
            <Grid
                size={12}
                container justifyContent='flex-end' alignItems="flex-end" sx={{ position: 'fixed', bottom: 0, left: 0, backgroundColor: 'white', zIndex: 1000 }}>
                <BasePagination
                    data={data}
                    pageSize={pageSize}
                    page={page - 1}
                    pageSizeChangeHandler={(event) => {
                        setPageSize(parseInt(event.target.value));
                        setPage(1);
                    }}
                    pageChangeHandler={(event, val) => {
                        setPage(val + 1);
                    }}
                />
            </Grid>
            {deleteConfirmationModal}
        </>
    );
}
