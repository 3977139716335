import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Grid2 as Grid,
  Typography,
  FormLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

export default function AlertSettings() {
  const { control, watch } = useFormContext();

  const serviceTemplate = (title, subtitle, emailKey, whatsAppKey, smsKey) => <Grid size={12} container spacing={1} sx={{ pl: 1, pt: 3 }}>
    <Grid size={6}>
      <Grid size={12} container>
        <Typography>
          {title}
        </Typography>
      </Grid>
      <Grid size={12} container>
        <FormLabel id="service-radio-buttons-group-label">{subtitle}</FormLabel>
      </Grid>
    </Grid>
    <Grid size={6}>
      <FormControl fullWidth size="small" sx={{ mt: 1 }}>
        <Grid size={12}>
          {[{
            service: 'Email',
            key: emailKey,
          }, {
            service: 'WhatsApp',
            key: whatsAppKey,
          }, {
            service: 'SMS',
            key: smsKey,
          }].map(({ service, key }) => <Controller
            key={service}
            rules={{ required: 'Invalid Selection' }}
            control={control}
            name={`settings.services.${key}.enable`}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    edge="end"
                    checked={field.value}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{
                      color: 'secondary.contrastText',
                      '&.Mui-checked': {
                        color: 'secondary.contrastText',
                      },
                    }}
                    disabled={!watch(`settings.services.${key}.eligible`)}
                  />
                }
                label={service}
                sx={{
                  '.MuiFormControlLabel-label': { ml: 1 },
                }}
              />
            )}
          />)}
        </Grid>
      </FormControl>
    </Grid>
  </Grid>;


  return (
    <Grid container
      sx={{
        p: 2,
        backgroundColor: 'white',
        borderRadius: '20px',
        border: '1px solid #E0E0E0',
      }}>
      <Grid size={12} container>
        {serviceTemplate('Lead Alerts', 'Receive notifications for new customer order submissions', 'leadAlertEmail', 'leadAlertWhatsApp', 'leadAlertSms')}
        {serviceTemplate('Cancel Subscription', 'Customer will receive notification about cancellation of the subscription', 'cancelSubscriptionEmail', 'cancelSubscriptionWhatsApp', 'cancelSubscriptionSms')}
        {serviceTemplate('New Subscription', 'Sent upon customer creation, includes start date, end date, address and meal plan name', 'newSubscriptionEmail', 'newSubscriptionWhatsApp', 'newSubscriptionSms')}
        {serviceTemplate('Payment Received', 'Sent upon payment receive, includes new amount, start date and end date', 'paymentReceivedEmail', 'paymentReceivedWhatsApp', 'paymentReceivedSms')}
        {serviceTemplate('Payment Pending', 'Sent on the expected payment date if the payment is marked pending', 'paymentPendingEmail', 'paymentPendingWhatsApp', 'paymentPendingSms')}
        {serviceTemplate('Expiry Reminder', 'Expiry reminders are sent daily starting two days before expiry until the tiffin expires', 'expiryReminderEmail', 'expiryReminderWhatsApp', 'expiryReminderSms')}
        {/* {(watch('settings.services.expiryReminderEmail.enable') || watch('settings.services.expiryReminderWhatsApp.enable') || watch('settings.services.expiryReminderSms.enable')) && <Grid size={12} container justifyContent='flex-end' spacing={1} sx={{ pl: 1, pt: 3 }}>
        <Grid size={6}>
          <FormControl fullWidth size="small" sx={{ mt: 1 }}>
            <Grid size={12}>
              <BaseTextField
                disabled
                multiline
                value={watch('settings.services.expiryReminderSms.message')}
                rows={3}
                name="settings.services.expiryReminderSms.message"
                id="settings.services.expiryReminderSms.message"
                label='Expiry Reminder Message'
                errors={errors}
                required={false}
              />
            </Grid>
          </FormControl>
        </Grid>
      </Grid>} */}
        {serviceTemplate('Pre-Delivery', 'Customers will receive an estimated delivery time notification when the driver begins the route', 'preDeliveryEmail', 'preDeliveryWhatsApp', 'preDeliverySms')}
        {/* {(watch('settings.services.preDeliveryEmail.enable') || watch('settings.services.preDeliveryWhatsApp.enable') || watch('settings.services.preDeliverySms.enable')) && <Grid size={12} container justifyContent='flex-end' spacing={1} sx={{ pl: 1, pt: 3 }}>
        <Grid size={6}>
          <FormControl fullWidth size="small" sx={{ mt: 1 }}>
            <Grid size={12}>
              <BaseTextField
                multiline
                disabled
                value={watch('settings.services.preDeliverySms.message')}
                rows={3}
                name="settings.services.preDeliverySms.message"
                id="settings.services.preDeliverySms.message"
                label='Pre-Delivery Message'
                errors={errors}
                required={false}
              />
            </Grid>
          </FormControl>
        </Grid>
      </Grid>} */}
        {serviceTemplate('Post-Delivery', 'Sent upon tiffin delivery, includes delivery time, image, and any driver notes', 'postDeliveryEmail', 'postDeliveryWhatsApp', 'postDeliverySms')}
        {/* {(watch('settings.services.postDeliveryEmail.enable') || watch('settings.services.postDeliveryWhatsApp.enable') || watch('settings.services.postDeliverySms.enable')) && <Grid size={12} container justifyContent='flex-end' spacing={1} sx={{ pl: 1, pt: 3 }}>
        <Grid size={6}>
          <FormControl fullWidth size="small" sx={{ mt: 1 }}>
            <Grid size={12}>
              <BaseTextField
                multiline
                disabled
                value={watch('settings.services.postDeliverySms.message')}
                rows={3}
                name="settings.services.postDeliverySms.message"
                id="settings.services.postDeliverySms.message"
                label='Post-Delivery Message'
                errors={errors}
                required={false}
              />
            </Grid>
          </FormControl>
        </Grid>
      </Grid>} */}
      </Grid>
    </Grid >
  );
}
