import React from 'react';
import { Grid2 as Grid } from '@mui/material';
import Lottie from 'react-lottie';
import Loader from '../assets/loader/loader.json';

export default function TuiSpinner() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Grid container justifyContent='center'>
      <Lottie options={defaultOptions} height={500} width={500} />
    </Grid>
  );
}