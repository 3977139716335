import React, { useEffect, useState } from 'react';
import {
    Drawer,
    Grid2 as Grid,
    Typography,
    IconButton,
    Tooltip,
    Chip,
    CircularProgress
} from '@mui/material';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import EditIconOutlined from '@mui/icons-material/EditOutlined';
import { TuiSpinner, DataNotFound, Table, Hooks, ConfirmationModal } from '../../../core';
import { historyApi, tiffinsApi } from '../../../js/slices/api_slices';
import { getFormattedDateFromUnixTimestamp } from '../../../js/lib/utils';
import moment from 'moment';
import CustomerDeliveryEditForm from './customer_delivery_edit_form';
import { APIS } from '../../../js/lib/constants';

const upcomingDeliveriesHeadCells = [{
    id: 'date',
    title: 'Date',
}, {
    id: 'mealPlan',
    title: 'Meal Plan',
}, {
    id: 'quantity',
    title: 'Qty',
}, {
    id: 'address',
    title: 'Address',
}, {
    id: 'updateSubscriptionEndDate',
    title: 'End Date Updated',
}, {
    id: 'actions',
    title: 'Actions',
}];

const { useGetHistoryQuery } = historyApi;
const { useUpdateSubscriberOperationMutation } = tiffinsApi;
const { useSnackBarNotification } = Hooks;

export default function CustomerManageUpcomingDeliveryDrawer({ open, handleClose, item, fetchCustomer }) {
    const { errorSnackBar, successSnackBar } = useSnackBarNotification();
    const [selectedItem, setSelectedItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showDeliveryEditForm, setShowDeliveryEditForm] = useState(false);
    const [showResetConfirmationModal, setShowResetConfirmationModal] = useState(false);
    const [rows, setRows] = useState([]);

    let { data, isLoading } = useGetHistoryQuery({
        page: 1,
        pageSize: 90,
        sortKey: '-date',
        customerId: item?.id,
    }, { refetchOnMountOrArgChange: true, skip: !(item?.id && open) });

    const [updateTiffinSubscriber] = useUpdateSubscriberOperationMutation();

    const upcomingDeliveries = (item) => {
        const { startDate, endDate, deliveryDays, tiffin, customer, deliveryChanges, comment, deliveryInstructions, deliveryTimeWindow } = item;
        const deliveries = [];
        const start = moment(startDate);
        const end = moment(endDate);
        let currentDate = moment().endOf('day');
        currentDate = start.isAfter(currentDate) ? start : currentDate;
        while (currentDate <= end) {
            const day = currentDate.day();
            const id = currentDate.valueOf();
            const deliveryChange = deliveryChanges.find(change => change.date === id);
            if (deliveryChange) {
                deliveries.push({
                    ...deliveryChange,
                    updated: true,
                    id,
                    quantity: deliveryChange.tiffin?.quantity,
                });
            } else {
                if (deliveryDays[day] > 0) {
                    deliveries.push({
                        date: id,
                        tiffin,
                        updated: false,
                        quantity: deliveryDays[day],
                        address: customer?.address,
                        unit: customer?.unit,
                        addons: customer?.addons,
                        comment,
                        deliveryInstructions,
                        deliveryTimeWindow,
                        id
                    });
                }
            }

            currentDate.add(1, 'days');
        }
        return deliveries;
    };


    const handleDialogClose = () => {
        setShowDeliveryEditForm(false);
        setSelectedItem(null);
    };

    const handleDownload = () => {
        setLoading(true);
        downloadUpcomingDeliveries();
        setLoading(false);
    };

    useEffect(() => {
        setRows(upcomingDeliveries(item));
    }, []);

    const downloadUpcomingDeliveries = () => {
        const doc = new jsPDF();
        doc.setFontSize(16);
        doc.setTextColor(128, 128, 128);
        const text = 'Upcoming Deliveries';
        const centerOfPage = 105;
        let y = 10;
        doc.text(text, centerOfPage, y, { align: 'center' });

        const headCells = upcomingDeliveriesHeadCells.filter(cell => cell.title !== 'Actions');
        headCells.pop();

        const rowData = rows;
        rowData.forEach(row => {
            row.date = getFormattedDateFromUnixTimestamp(row.date);
            row.address = row.address?.description ? `${row?.unit ? row?.unit + ' - ' : ''}${row.address?.description}` : '--';
            row.mealPlan = row.tiffin.name ?? '--';
        });
        y += 10;
        doc.setFontSize(12);
        doc.text(`Customer: ${item?.customer?.firstName} ${item?.customer?.lastName}`, centerOfPage, y, { align: 'center' });
        autoTable(doc, {
            theme: 'grid',
            head: [headCells.map(cell => cell.title)],
            body: rowData.map(row => headCells.map(cell => row[cell.id])),
            startY: y + 10,
        });
        doc.save(`${item?.customer?.firstName}_${item?.customer?.lastName}_upcoming_deliveries.pdf`);
    };

    const resetConfirmationModal = <ConfirmationModal
        open={showResetConfirmationModal}
        handleClose={() => {
            setShowResetConfirmationModal(false);
        }}
        title={<Grid container spacing={2}>
            <Grid size={12}>
                <Typography variant='h6'>{`Are you sure want to reset this delivery?`}</Typography>
            </Grid>
        </Grid>}
        type="Update"
        actionHandlerIsLoading={loading}
        actionHandler={async () => {
            data = {
                id: item?.id,
                operation: APIS.CUSTOMER_OPERATIONS.RESET_DELIVERY,
                data: {
                    date: selectedItem?.id,
                },
            }
            setLoading(true);
            let result = await updateTiffinSubscriber(data).unwrap();

            if (!result.error) {
                successSnackBar({ message: 'Customer updated successfully' });
                fetchCustomer({ id: item?.id });
                setRows(upcomingDeliveries(result?.data));
                setSelectedItem(null);
            } else {
                errorSnackBar({ message: result?.errorMessage });
            }
            setLoading(false);
            setShowResetConfirmationModal(false);
        }}
    />;

    return (
        <>
            <Drawer
                anchor="right"
                PaperProps={{
                    sx: { width: '60vw' },
                }}
                open={open}
                onClose={handleClose}
            >
                <Grid container sx={{ p: 2 }} spacing={1}>
                    <Grid container size={12}>
                        <Grid size={3} container justifyContent="flex-start" alignItems="center">
                            <Grid size='auto'>
                                <IconButton onClick={handleClose} sx={{ p: 0, pr: 1, pt: 0.5 }}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid size='auto'>
                                <Typography variant="h6" fontWeight="bold">
                                    Manage Deliveries
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid size={9} container justifyContent="flex-end" alignItems="center">
                            <Tooltip title="Download PDF">
                                <IconButton
                                    sx={{
                                        color: '#008080',
                                    }}
                                    disabled={!data?.data?.items?.length}
                                    onClick={handleDownload}>
                                    <PictureAsPdfIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid size={12}>
                        {isLoading && <TuiSpinner />}
                        {!isLoading && (
                            <>
                                {!data?.data?.items?.length && <DataNotFound title="Upcoming Deliveries" />}
                                {data?.data?.items?.length > 0 && (
                                    <Table
                                        headers={upcomingDeliveriesHeadCells}
                                        data={rows?.map(row => ({
                                            date: getFormattedDateFromUnixTimestamp(row.date),
                                            mealPlan: <>
                                                <Typography>{row.tiffin?.name}</Typography>
                                                {row?.addons?.length > 0 && <Chip label="Add-Ons" size='small' />}
                                            </>,
                                            quantity: row.quantity,
                                            address: row.address?.description ? `${row?.unit ? row?.unit + ' - ' : ''}${row.address?.description}` : '--',
                                            updateSubscriptionEndDate: row.updateSubscriptionEndDate == true ? 'Yes' : row.updateSubscriptionEndDate == false ? 'No' : '--',
                                            actions: (
                                                <>
                                                    {isLoading && selectedItem?.id === row?.id ? <CircularProgress size='small' /> : <Tooltip title="Edit">
                                                        <IconButton
                                                            sx={{
                                                                color: '#008080',
                                                            }}
                                                            onClick={() => {
                                                                setSelectedItem(row);
                                                                setShowDeliveryEditForm(true);
                                                            }}>
                                                            <EditIconOutlined />
                                                        </IconButton>
                                                    </Tooltip>}
                                                    {isLoading && selectedItem?.id === row?.id ? <CircularProgress size='small' /> : <Tooltip title="Reset">
                                                        <IconButton
                                                            sx={{
                                                                color: '#008080',
                                                            }}
                                                            disabled={!row.updated}
                                                            onClick={() => {
                                                                setSelectedItem(row);
                                                                setShowResetConfirmationModal(true);
                                                            }}>
                                                            <RestartAltOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>}
                                                </>
                                            ),
                                            id: row.id
                                        }))}
                                        setSelectedRow={() => { }}
                                    />
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
            </Drawer>
            {showDeliveryEditForm && <CustomerDeliveryEditForm
                fetchCustomer={fetchCustomer}
                open={showDeliveryEditForm}
                customer={item}
                item={selectedItem}
                rows={rows}
                isLoading={loading}
                setRows={setRows}
                handleClose={handleDialogClose} />}
            {showResetConfirmationModal && resetConfirmationModal}
        </>
    );
}