import React from 'react';
import { Box, Typography, Modal } from '@mui/material';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import CoreButton from './button';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 420,
  color: 'black',
  backgroundColor: 'white',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  p: 2,
  borderRadius: '5px',
};

export default function CoreConfirmationModal({ open,
  handleClose,
  title,
  hideCancelOption = false,
  actionHandlerIsLoading = false,
  actionText,
  actionHandler,
  actionHandlerDisabled = false,
  type,
  ...rest }) {
  return (
    <Modal
      {...rest}
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {title}
          </Grid>
          <Grid item xs={12}>

            {actionText && <Typography variant="h6" sx={{ mt: 1, mb: 1, color: 'secondary.contrastText.main' }}>
              {actionText}
            </Typography>}
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end" spacing={1} sx={{ mt: 1 }}>
            {!hideCancelOption && <Grid item xs="auto">
              <CoreButton
                onClickHandler={handleClose}
                variant="outlined"
                sx={{
                  width: '100px',
                }}
              >Cancel
              </CoreButton>
            </Grid>}
            <Grid item xs="auto">
              <CoreButton
                onClickHandler={actionHandler}
                fullWidth={false}
                isLoading={actionHandlerIsLoading}
                variant='contained'
                sx={{
                  'width': '100px',
                  'backgroundColor': 'secondary.contrastText',
                  'color': 'white',
                  ':hover': {
                    backgroundColor: 'secondary.contrastText',
                  },
                }}
                disabled={actionHandlerDisabled}
              >
                {type}
              </CoreButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

CoreConfirmationModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  actionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  actionHandler: PropTypes.func,
  type: PropTypes.string,
};
