import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid2 as Grid } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';

import { TuiSpinner, Hooks, ComingSoon } from '../../../core';
import BillingsSettings from '../components/settings_billing';
import { useUpdateSettingsMutation } from '../../../js/slices/api_slices/settings_api_slice';
import Footer from '../components/footer';

export default function SettingsBillingView() {
  const [updateSettings, { isLoading, isSuccess, isError, error }] = useUpdateSettingsMutation();
  const settings = useSelector((store) => store?.settings.settings);
  const { errorSnackBar, successSnackBar } = Hooks.useSnackBarNotification();
  const methods = useForm({
    defaultValues: {
      ...settings,
    },
  });

  useEffect(() => {
    if (isSuccess) {
      successSnackBar({ message: 'Billing information Successfully Updated' });
    }
    if (isError) {
      errorSnackBar({ message: error?.data?.errorMessage || 'Billing information Failed to  Update' });
    }
  }, [isSuccess, isError]);

  if (isLoading) {
    return <TuiSpinner />;
  }

  return (
    <Grid container>
      <FormProvider {...methods}>
        <BillingsSettings/>
      </FormProvider>
      <Footer
        buttonClickHandler={() => {
          updateSettings(methods.getValues());
        }}
        showButtonIcon={false}
        buttonText='Update'
        buttonDisabled={isLoading || !methods?.formState?.isDirty}
      />
    </Grid>
  );
}
