import React, { useState, useEffect } from 'react';
import { Grid2 as Grid } from '@mui/material';
import { Tab } from '@mui/material';
import {
    TabContext,
    TabList,
    TabPanel
} from '@mui/lab';

import { TuiAppBar } from '../../../core';
import GeneralSettingsView from './settings_general_view';
import BillingSettingsView from './settings_billing_view';
import AlertsSettingsView from './settings_alerts_view';
import DriverAppSettingsView from './settings_driver_app_view';
import LeadPortalSettingsView from './settings_lead_portal';
import BusinessSettingsView from './settings_business_view';

export default function SettingsView() {
    const [value, setValue] = useState(window.location.hash.replace('#', '') || 'general');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        window.location.hash = value;
    }, [value]);

    useEffect(() => {
        const handleHashChange = () => {
            setValue(window.location.hash.replace('#', '') || 'general');
        };

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return (
        <Grid container>
            <Grid size={12}>
                <TuiAppBar
                    title='Settings'
                    subtitle='Manage your details and personal preferences here'
                />
            </Grid>
            <Grid size={12}>
                <TabContext value={value}>
                    <TabList onChange={handleChange} aria-label="settings menu" sx={{
                        '& .Mui-selected': {
                            color: '#008080',
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: '#008080',
                        },
                    }}>
                        <Tab label="General" value="general" />
                        <Tab label="Business" value="business" />
                        <Tab label="Billing" value="billing" />
                        <Tab label="Alerts" value="alerts" />
                        <Tab label="Driver App" value="driverApp" />
                        <Tab label="Lead Portal" value="leadPortal" />
                    </TabList>
                    <TabPanel value="general">
                        <GeneralSettingsView />
                    </TabPanel>
                    <TabPanel value="business">
                        <BusinessSettingsView />
                    </TabPanel>
                    <TabPanel value="billing">
                        <BillingSettingsView />
                    </TabPanel>
                    <TabPanel value="alerts">
                        <AlertsSettingsView />
                    </TabPanel>
                    <TabPanel value="driverApp">
                        <DriverAppSettingsView />
                    </TabPanel>
                    <TabPanel value="leadPortal">
                        <LeadPortalSettingsView />
                    </TabPanel>
                </TabContext>
            </Grid>
        </Grid>
    );
}
