import React from 'react';

import DirectionsIcon from '@mui/icons-material/DirectionsOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import EngineeringIcon from '@mui/icons-material/EngineeringOutlined';
import ExposureIcon from '@mui/icons-material/ExposureOutlined';
import LeaderboardIcon from '@mui/icons-material/LeaderboardOutlined';
import AnalyticsIcon from '@mui/icons-material/AnalyticsOutlined';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDiningOutlined';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturnedOutlined';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';

export const drawerNavLocations = [
  {
    group: 1,
    name: 'Meal Plans',
    path: '/meal-plans',
    icon: <TakeoutDiningIcon />,
    rolesAvailability: 'mealPlans',
  }, {
    group: 1,
    name: 'Customers',
    path: '/customers',
    icon: <PeopleIcon />,
    rolesAvailability: 'customers',
  }, {
    group: 1,
    name: 'Cook & Pack',
    path: '/cook-pack',
    icon: <AssignmentReturnedIcon />,
    rolesAvailability: 'cookingPlan',
  },{
    group: 1,
    name: 'Employees',
    path: '/employees',
    icon: <EngineeringIcon />,
    rolesAvailability: 'employees',
  }, {
    group: 1,
    name: 'Routes',
    path: '/routes',
    icon: <DirectionsIcon />,
    rolesAvailability: 'routes',
  }, {
    group: 1,
    path: '/expenses',
    icon: <ExposureIcon />,
    name: 'Expenses',
    rolesAvailability: 'expenses',
  }, {
    group: 1,
    path: '/leads',
    icon: <LeaderboardIcon />,
    name: 'Leads',
    rolesAvailability: 'leads',
  }, {
    group: 1,
    path: '/reports',
    icon: <AnalyticsIcon />,
    name: 'Reports',
    rolesAvailability: 'reports',
  },
  {
    group: 1,
    path: '/settings',
    icon: <SettingsIcon />,
    name: 'Settings',
    rolesAvailability: 'settings',
  },
  {
    group: 2,
    path: '/whats-new',
    icon: <AnnouncementOutlinedIcon />,
    name: 'What\'s New',
  },
  {
    group: 2,
    path: '/logout',
    icon: <LogoutIcon />,
    name: 'Logout',
  },
];
