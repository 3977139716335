import React, { useEffect, useRef, useState } from 'react';
import { Typography, Grid2 as Grid, Badge, IconButton, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import BaseTextField from './base_text_field';
import CoreButton from './button';
import { useSelector } from 'react-redux';
import moment from 'moment';

export default function AppBar({
  title,
  subtitle,
  searchHelperText,
  setSearchText,
  selectedItems = [],
  buttonClickHandler,
  buttonDisabled,
  selectedItemsMenu,
  searchText = '',
  menuItems,
  filter,
  exportData = null
}) {
  const theme = useTheme();
  const searchInputRef = useRef(null);
  const [showPaymentPendingAlert, setShowPaymentPendingAlert] = useState(false);
  const [showPaymentExpiredAlert, setShowPaymentExpiredAlert] = useState(false);
  const settings = useSelector((store) => store?.settings?.settings);

  useEffect(() => {
    if (settings?.plan?.name) {
      if (settings?.plan?.endDate) {
        const planEndDate = moment(settings.plan.endDate).endOf('day');
        const currentDate = moment().endOf('day');
        if (planEndDate < currentDate) {
          setShowPaymentExpiredAlert(true);
        } else if (moment(planEndDate).diff(currentDate, 'days') < 3) {
          setShowPaymentPendingAlert(true);
        }
      }
    }
  }, [settings]);

  if (showPaymentPendingAlert) {
    return (
      <Alert variant="filled" severity='warning' onClose={() => setShowPaymentPendingAlert(false)}>
        <Typography variant="body1">
          Your payment is pending. Please pay your bill to continue using our services.
        </Typography>
      </Alert>
    );
  }

  if (showPaymentExpiredAlert) {
    return (
      <Alert variant="filled" severity='error' onClose={() => setShowPaymentExpiredAlert(false)}>
        <Typography variant="body1">
          Your subscription is expired. Please pay your bill as soon as possible to continue using our services.
        </Typography>
      </Alert>
    );
  }

  return (
    <Grid container justifyContent='space-between'>
      <Grid size='auto' sx={{ mt: 1 }}>
        {typeof title === 'string' ? (
          <>
            <Typography variant="h6" fontWeight="bold" sx={{ pl: 1 }}>
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="subtitle1" sx={{ pl: 1 }}>
                {subtitle}
              </Typography>
            )}
          </>
        ) : React.isValidElement(title) ? (
          title
        ) : null}
      </Grid>
      <Grid container justifyContent='flex-end' size='auto' spacing={1}>
        {!selectedItems?.length && (
          <>
            {menuItems && (
              <Grid size='auto'>
                {menuItems}
              </Grid>
            )}
            {exportData && exportData.exportHandler && (
              <Grid size='auto'>
                <IconButton
                  sx={{
                    mt: 1.2,
                    p: 0.6,
                    border: '1px solid', // Add border
                    borderColor: 'grey.500', // Set border color
                    borderRadius: '10%', // Make the border rounded
                  }}
                  onClick={() => exportData.exportHandler()}
                >
                  <FileDownloadOutlinedIcon />
                </IconButton>
              </Grid>
            )}
            {filter && (
              <Grid size='auto'>
                <Badge
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  color="error"
                  badgeContent={filter.filterCount}
                  max={999}
                >
                  <IconButton
                    sx={{
                      mt: 1.2,
                      p: 0.6,
                      border: '1px solid', // Add border
                      borderColor: 'grey.500', // Set border color
                      borderRadius: '10%', // Make the border rounded
                    }}
                    onClick={() => filter.setShowFilterModal(!filter.showFilterModal)}
                  >
                    <TuneOutlinedIcon />
                  </IconButton>
                </Badge>
                {filter.filterModal}
              </Grid>
            )}
            {setSearchText && (
              <Grid size='auto' justifyContent='flex-end' sx={{ ml: 0.5 }}>
                <Badge
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  badgeContent={searchText ? 1 : 0}
                  color="error"
                  invisible={!searchText}
                >
                  <BaseTextField
                    sx={{
                      mt: 1,
                      width: '100%',
                      '& .MuiInputBase-input': {
                        padding: theme.spacing(1, 1, 1, 0),
                        // vertical padding + font size from searchIcon
                        transition: theme.transitions.create('width'),
                        [theme.breakpoints.up('sm')]: {
                          width: '0ch',
                          '&:focus': {
                            width: '20ch',
                          },
                        },
                      },
                    }}
                    value={searchText || ''}
                    startAdornment={<SearchIcon onClick={() => searchInputRef?.current?.focus()} />}
                    inputRef={searchInputRef}
                    onChange={(event) => {
                      setSearchText(event.target.value);
                    }}
                    placeholder={searchHelperText}
                  />
                </Badge>
              </Grid>
            )}
            <Grid sx={{ mt: 1 }} size='auto'>
              {buttonClickHandler && typeof buttonClickHandler === 'function' && (
                <CoreButton
                  variant="contained"
                  disabled={buttonDisabled}
                  onClickHandler={buttonClickHandler}
                  startIcon={<AddOutlinedIcon />}
                >
                  Add
                </CoreButton>
              )}
            </Grid>
          </>
        )}
        <Grid size='auto'>
          {selectedItems?.length > 0 && selectedItemsMenu}
        </Grid>
      </Grid>
    </Grid>
  );
};
